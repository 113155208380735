import React, { useState, useEffect } from "react";
import styles from "./CreateDroneBooking.module.css";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import { useNavigate, Link, useParams } from "react-router-dom";
import DynamicForm from "../../Component/DynamicForm/DyamicForm";
import InputFields from "../../Component/InputFields/InputFields";
import { open_api, token_api } from "../../Utils/network";
import { Button, useForkRef } from "@mui/material";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import editIcon from "../../Assets/edit_icon.svg";
import deleteIcon from "../../Assets/delete_icon.svg";
import CustomModal from "../../Component/CustomModal/CustomModal";
import { Validate, ValidateDroneOrder } from "./Utility";
import axios from "axios";
import successGif from "../../Assets/happy.svg";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const CreateDroneBooking = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  console.log(id, "bookingId");
  const [formData, setFormData] = useState({
    id: null,
    user: {},
    phone: "",
    gst: "",
    remarks: "",
    address: [],
    brand: [],
    products: [],
    category: [],
    payment_status: [],
    payment_mode: [],
    transaction_id: "",
    coupon: [],
  });
  const [formErrors, setFormErrors] = useState({});
  const [typedValue, setTypedValue] = useState("");
  const [dduserListing, setDDuserListing] = useState([]);
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link
      to="/bookings/drone-hub-bookings"
      className="orange-1 bread breadElement"
    >
      Drone Hub Bookings
    </Link>,
    <div className="orange-1 bread">
      {id ? "Edit" : "Create"} Drone Hub Booking
    </div>,
  ];

  const [categoriesList, setCategoriesList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [droneProductsList, setDroneProductsList] = useState([]);
  const [couponList, setCouponList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [open, setOpen] = useState(false);
  const [addressForm, setAddressForm] = useState({
    id: null,
    line_1: "",
    line_2: "",
    plot_no: "",
    city: "",
    tehsil: "",
    pincode: "",
    district: "",
    state: "",
    country: "",
  });
  const [addressFormErrors, setAddressFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [priceData, setPriceData] = useState({});
  const [cartData, setCartData] = useState({});
  const [userCartData, setUsersCartData] = useState([]);

  const handleNavigateBack = () => {
    navigate(`/bookings/drone-hub-bookings`);
  };

  const handleInputChange = (event, newValue) => {
    console.log(newValue, "hariNewVailue");

    setTypedValue(newValue);
    setFormData((prev) => ({
      ...prev,
      user: newValue,
      phone: newValue?.creator?.mobile,
    }));
  };

  console.log(formErrors, "hariFormErr");

  function getDdUserListing(value) {
    let apiEndpoint = "/users/dd_profiles";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    open_api
      .get(apiEndpoint)
      .then((response) => {
        setDDuserListing(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getProductsList();
    getCategoriesList();
    getBrandsList();
  }, []);

  useEffect(() => {
    getCouponsList();
  }, [
    id,
    formData?.user,
    formData?.category,
    formData?.brand,
    formData?.products,
  ]);

  const getCouponsList = async () => {
    try {
      let filterObj = { page_size: 100 };

      if (formData?.user && formData?.user?.creator?.id) {
        filterObj.user = formData?.user?.creator?.id;
      }
      if (formData?.brand && formData?.brand[0]) {
        filterObj.drone_product_brand = formData?.brand[0]?.id;
      }
      if (formData?.category && formData?.category[0]) {
        filterObj.drone_product_category = formData?.category[0]?.id;
      }
      if (formData?.products && formData?.products.length > 0) {
        filterObj.drone_product = formData?.products.map((item) => item?.id);
      }

      let responseData = await token_api
        .get(`course/coupons`, {
          params: { ...filterObj },
          paramsSerializer: { indexes: null },
        })
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            setCouponList(response?.data?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getDroneBookingDetails = (bookingId) => {
    token_api
      .get(`drone/shop_order/${bookingId}`)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          const { data } = response?.data;

          console.log(data, "hariData");
          const {
            id,
            customer_gst_number,
            order_product,
            user,
            payment_status_str,
            coupon,
            billing_address,
            remark,
            payment,
          } = data;
          const { mobile } = user;
          let orderedItems = order_product.map((item, index) => {
            return {
              id: item?.product?.id,
              index: item?.index,
              quantity: item?.quantity,
            };
          });

          console.log(
            coupon,

            "couponList"
          );
          setFormData({
            ...formData,
            id: id,
            user: {
              id: user?.id,
              name: `${user?.first_name} ${user?.last_name} ${user?.email} ${user?.mobile}`,
              creator: { id: user?.id },
            },
            phone: mobile,
            gst: customer_gst_number,
            remarks: remark,
            products: orderedItems,
            payment_status: paymentStatusList.filter(
              (item) => item?.title === payment_status_str
            ),
            coupon: couponList.filter((item) => item?.id === coupon),
            transaction_id:
              payment.length > 0 ? payment[0]?.transaction_id : "",
            payment_mode:
              payment.length > 0
                ? paymentModeList.filter(
                    (item) => item?.id === payment[0]?.payment_mode
                  )
                : [],
          });
          setSelectedAddress(billing_address);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (id) {
      getDroneBookingDetails(id);
    }
  }, [id]); //

  console.log(formData, "finalFormData");

  useEffect(() => {
    getDdUserListing(typedValue);
  }, [typedValue]);

  const getCategoriesList = () => {
    token_api
      .get(`drone/product/category?page_size=100`)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          let newListing = response?.data?.data.map((item) => {
            return {
              id: item?.id,
              title: item?.name,
            };
          });
          setCategoriesList(newListing);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBrandsList = () => {
    token_api
      .get(`drone/product/brand?page_size=100`)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          let newListing = response?.data?.data.map((item) => {
            return {
              id: item?.id,
              title: item?.name,
            };
          });
          setBrandsList(newListing);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProductsList = () => {
    let filterObj = {};
    if (formData?.brand && formData?.brand[0]) {
      filterObj.brand = formData?.brand[0]?.id;
    }
    if (formData?.category && formData?.category[0]) {
      filterObj.category = formData?.category[0]?.id;
    }
    token_api
      .get(`drone/product?page_size=1000`, {
        params: { ...filterObj },
        paramsSerializer: { indexes: null },
      })
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          setDroneProductsList(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserAddress = (userId) => {
    console.log(userId, "jausyaya");
    token_api
      .get(`users/address?creator=${userId}`)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          setAddressList(response?.data?.data);
          if (!id) {
            setSelectedAddress(response?.data?.data[0]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserCartsData = (userId) => {
    token_api.get(`drone/product_cart?user=${userId}`).then((response) => {
      if (response?.status === 200 || response?.status === 201) {
        let newData = response?.data?.data.map((item) => {
          return {
            id: item?.id,
            product: item?.product?.id,
          };
        });
        setUsersCartData(newData);
      }
    });
  };
  useEffect(() => {
    if (formData?.user && formData?.user?.creator?.id) {
      getUserAddress(formData?.user?.creator?.id);
      getUserCartsData(formData?.user?.creator?.id);
    } else {
      setAddressList([]);
    }
  }, [formData?.user]);

  console.log(formData, "hariFormDATA");

  // useEffect(() => {
  //   if (formData?.brand[0] || formData?.category[0]) {
  //     getCouponsList();
  //   } else {
  //     setCouponList([]);
  //   }
  // }, [formData?.brand, formData?.category]);

  useEffect(() => {
    getProductsList();
  }, [formData?.brand, formData?.category]);

  //Handle Change Data..

  const handleChangeForm = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setFormData({
        ...formData,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setFormData({
        ...formData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDeleteForm = (id, selectName) => {
    setFormData({
      ...formData,
      [selectName]: formData?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleSelectAddress = (address) => {
    setSelectedAddress(address);
  };

  const handleDeleteUserAddress = (e, addressId) => {
    e.stopPropagation();
    token_api
      .delete(`users/address/${addressId}/`)
      .then((response) => {
        if (
          response?.status === 204 ||
          response?.status === 201 ||
          response?.status === 200
        ) {
          alert("User address deleted successfully!");
          getUserAddress(formData?.user?.creator?.id);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setAddressFormErrors({});
  };

  const handleAddAddress = () => {
    setOpen(true);
    setAddressForm({
      id: null,
      line_1: "",
      line_2: "",
      plot_no: "",
      city: "",
      tehsil: "",
      pincode: "",
      district: "",
      state: "",
      country: "",
      pincode: "",
    });
  };

  const handleEditAddress = (e, address) => {
    e.stopPropagation();
    setOpen(true);
    setAddressFormErrors({});
    const {
      line_1,
      line_2,
      plot_no,
      id,
      pincode,
      city,
      tehsil,
      district,
      state,
      country,
    } = address;
    setAddressForm({
      line_1: line_1,
      line_2: line_2,
      plot_no: plot_no,
      id: id,
      city: city,
      district: district,
      pincode: pincode,
      tehsil: tehsil,
      state: state,
      country: country,
    });

    console.log(address, "editADdress");
  };

  useEffect(() => {
    const fetchData = async () => {
      if (addressForm?.pincode?.length === 6) {
        try {
          const response = await axios.get(
            `https://api.postalpincode.in/pincode/${addressForm?.pincode}`
          );

          if (
            response?.data?.length !== 0 &&
            response?.data[0]?.PostOffice?.length !== 0
          ) {
            const pinData = response?.data[0]?.PostOffice[0];
            const { District, Division, State, Name, Region, Country } =
              pinData;

            setAddressForm({
              ...addressForm,
              tehsil: Division,
              state: State,
              district: District,
              city: District,
              country: Country,
            });
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
        }
      }
    };

    fetchData();
  }, [addressForm?.pincode]);

  const handlePostAddress = (event) => {
    event.preventDefault();

    let data = {
      user_id: formData?.user?.creator?.id,
      ...(addressForm?.line_1 && { line_1: addressForm?.line_1 }),
      ...(addressForm?.line_2 && { line_2: addressForm?.line_2 }),
      ...(addressForm?.plot_no && { plot_no: addressForm?.plot_no }),
      pincode: addressForm?.pincode,
      city: addressForm?.city,
      tehsil: addressForm?.tehsil,
      district: addressForm?.district,
      state: addressForm?.state,
      country: addressForm?.country,
    };

    Validate(addressForm)
      .then(async (response) => {
        if (response == "success") {
          if (addressForm?.id) {
            token_api
              .patch(`/users/address/${addressForm?.id}/`, data)
              .then((response) => {
                if (response?.status == 200 || response?.status == 201) {
                  alert("User address details updated successfully!");
                  setOpen(false);
                  setAddressFormErrors({});
                  setAddressForm({});
                  getUserAddress(formData?.user?.creator?.id);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            token_api
              .post(`/users/admin/address/`, data)
              .then((response) => {
                if (response?.status == 200 || response?.status == 201) {
                  alert("User address details created successfully!");
                  setOpen(false);
                  setAddressFormErrors({});
                  setAddressForm({});
                  getUserAddress(formData?.user?.creator?.id);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      })
      .catch((error) => {
        setAddressFormErrors(error);
      });
  };

  const handleChangeAddress = (event) => {
    const { name, value } = event.target;
    setAddressForm({
      ...addressForm,
      [name]: value,
    });
  };

  //Handle Changing Products.....

  const handleIncreaseQuantity = (product, index, userId) => {
    setFormData((prevFormData) => {
      const existingProduct = prevFormData.products.find(
        (p) => p.id === product.id
      );

      const updatedProducts = existingProduct
        ? prevFormData.products.map((p) =>
            p.id === product.id ? { ...p, quantity: p.quantity + 1 } : p
          )
        : [...prevFormData.products, { ...product, quantity: 1 }];

      const updatedFormData = {
        ...prevFormData,
        products: updatedProducts,
      };

      const updatedProduct = updatedProducts.find((p) => p.id === product.id);

      // Call the API if quantity > 0
      if (updatedProduct?.quantity > 0) {
        createProductCart(updatedProduct, index, userId);
      }

      return updatedFormData;
    });
  };

  const handleDecreaseQuantity = (productItem, index, userId) => {
    setFormData((prevFormData) => {
      const updatedProducts = prevFormData.products
        .map((product) =>
          product.id === productItem.id && product.quantity >= 0
            ? { ...product, quantity: product.quantity - 1 }
            : product
        )
        .filter((product) => product.quantity >= 0); // Remove products with quantity 0

      const updatedFormData = {
        ...prevFormData,
        products: updatedProducts,
      };

      const updatedProduct = updatedProducts.find(
        (p) => p.id === productItem.id
      );

      console.log(productItem, updatedProduct, "hariUkkpdatedProduct");

      if (updatedProduct?.quantity > 0) {
        createProductCart(updatedProduct, index, userId);
      } else if (updatedProduct?.quantity === 0) {
        console.log(productItem, updatedProduct, "updatedProductbahah");
        deleteCartFromUserCart(updatedProduct?.id, userCartData);
      }

      return updatedFormData;
    });
  };

  const createProductCart = (product, index, userId) => {
    console.log(product, index, userId, "addCart");
    const data = {
      product_id: product?.id,
      index: index,
      quantity: product?.quantity,
      user_id: userId,
    };

    token_api
      .post(`drone/product_cart/`, data)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          setCartData(response?.data?.data);
          getUserCartsData(userId);
          getPlaceOrderCalculation();
        }
      })
      .catch((error) => {
        console.error("Error adding product to cart:", error);
      });
  };

  const deleteCartFromUserCart = (productId, userCartData) => {
    console.log(userCartData, "hariUserCartData");
    let deletedCart = userCartData.filter((item) => item?.product == productId);

    token_api
      .delete(`drone/product_cart/${deletedCart[0]?.id}/`)
      .then((response) => {
        if (response?.status === 204) {
          getUserCartsData(formData?.user?.creator?.id);
          getPlaceOrderCalculation();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProductQuantity = (productItem) => {
    const product = formData.products.find((p) => p.id === productItem.id);
    return product ? product.quantity : 0;
  };

  console.log(formData, "hariFormData");

  //Create drone product order .....

  const handleOrderDroneProduct = (event) => {
    event.preventDefault();
    let data = {
      user_id: formData?.user?.creator?.id,
      shipping_address_id: selectedAddress?.id,
      billing_address_id: selectedAddress?.id,
      coupon_id: formData?.coupon[0]?.id,
      payment_status: formData?.payment_status[0]?.id,

      remark: formData?.remarks,
      customer_gst_number: formData?.gst,
      coupon: formData?.coupon[0]?.id,
    };
    if (formData?.payment_status[0]?.id === 3) {
      data.payment_mode = formData?.payment_mode[0]?.id;
      data.transaction_id = formData?.transaction_id;
    }
    ValidateDroneOrder(formData)
      .then(async (response) => {
        if (response == "success") {
          setLoading(true);
          if (formData?.id) {
            //Use Patch APi....
            token_api
              .patch(`drone/admin_place_order/${formData?.id}/`, data)
              .then((response) => {
                if (response?.status === 200 || response?.status === 201) {
                  alert("Dronehub product order details updated successfully!");
                  navigate(`/bookings/drone-hub-bookings/`);
                  setLoading(false);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            let patchData = {
              ...data,
              product_cart_ids: userCartData.map((item) => item?.id),
            };
            token_api
              .post(`drone/admin_place_order/`, patchData)
              .then((response) => {
                if (response?.status === 200 || response?.status === 201) {
                  alert("Dronehub product ordered successfully!");
                  navigate(`/bookings/drone-hub-bookings/`);
                  setLoading(false);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        setFormErrors(error);
      });
  };

  //Cart and price logics here.....

  //formData?.products length > 0 , then call drone/product_cart/ post api and then call drone_product_cart_calculation?user={userid} GET api

  const getPlaceOrderCalculation = () => {
    let filterObj = {};
    if (formData?.user && formData?.user?.creator?.id) {
      filterObj.user = formData?.user?.creator?.id;
    }
    if (formData?.coupon && formData?.coupon[0]) {
      filterObj.coupon = formData?.coupon[0]?.id;
    }
    token_api
      .get(`drone/product_cart_calculation`, {
        params: { ...filterObj },
        paramsSerializer: { indexes: null },
      })
      .then((response) => {
        if (response?.status === 200 || response?.status === 210) {
          setPriceData(response?.data?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  console.log(cartData, "hariCartData");
  // useEffect(() => {
  //   if (
  //     formData?.products.length > 0 &&
  //     formData?.user &&
  //     formData?.user?.creator?.id
  //   ) {
  //     createProductCart(formData?.products, formData?.user?.creator?.id);
  //   }
  // }, [formData?.products, formData?.user]);

  useEffect(() => {
    if (formData?.products?.length > 0) {
      getPlaceOrderCalculation();
    }
  }, [formData?.user, formData?.coupon]);

  console.log(formErrors, "hariPriceData");

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>
      </div>

      <div
        style={{
          margin: "40px 0px",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "65%" }}>
          <div>
            <h1 className={styles.orangeHead}>Add Details</h1>
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                width: "100%",
              }}
            >
              <div style={{ width: "100%" }}>
                <DynamicForm
                  value={formData?.user}
                  onChange={handleInputChange}
                  listing={dduserListing}
                  error={formErrors?.user}
                  typedValue={typedValue}
                  setTypedValue={setTypedValue}
                />
              </div>
              <div style={{ marginTop: "15px", width: "100%" }}>
                <InputFields
                  label="Phone No."
                  name="phone"
                  style={{ width: "100%" }}
                  value={formData?.phone}
                  onChange={handleChangeForm}
                  error={formErrors?.phone}
                  helperText={formErrors?.phone}
                  maxLength={10}
                />
              </div>
              <div style={{ marginTop: "15px", width: "100%" }}>
                <InputFields
                  label="GST No."
                  name="gst"
                  style={{ width: "100%" }}
                  value={formData?.gst}
                  onChange={handleChangeForm}
                  error={formErrors?.gst}
                  helperText={formErrors?.gst}
                />
              </div>
              {formData?.user && formData?.user?.creator?.id && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "20px 0px",
                  }}
                >
                  <h1 className={styles.orangeHead}>User Address</h1>
                  <Button variant="contained" onClick={handleAddAddress}>
                    + ADD ADDRESS
                  </Button>
                </div>
              )}
              {addressList?.length > 0 && (
                <div>
                  {addressList.map((address, index) => {
                    return (
                      <div
                        key={address?.id}
                        className={styles.addressCon}
                        style={{
                          background:
                            address?.id === selectedAddress?.id
                              ? "rgba(255, 234, 207, 1)"
                              : "none",
                        }}
                        onClick={() => {
                          handleSelectAddress(address);
                        }}
                      >
                        <p>
                          {address?.plot_no && `${address.plot_no}, `}
                          {address?.line_1 && `${address.line_1}, `}
                          {address?.line_2 && `${address.line_2}, `}
                          {address?.city && `${address.city}, `}
                          {address?.tehsil && `${address.tehsil}, `}
                          {address?.district && `${address.district}, `}
                          {address?.state && `${address.state}, `}
                          {address?.country && `${address.country}`}
                          {address?.pincode && `, ${address.pincode}`}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              handleEditAddress(e, address);
                            }}
                          >
                            <img src={editIcon} alt="edit_icon" />
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              handleDeleteUserAddress(e, address?.id);
                            }}
                          >
                            <img src={deleteIcon} alt="deleteIcon_icon" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              <div style={{ marginTop: "15px", width: "100%" }}>
                <CustomSelectChip
                  multiple={false}
                  label="Select Category"
                  name="category"
                  onChange={handleChangeForm}
                  onDelete={handleDeleteForm}
                  value={formData?.category}
                  listArray={categoriesList}
                  error={formErrors?.category}
                />{" "}
              </div>
              <div style={{ marginTop: "15px", width: "100%" }}>
                <CustomSelectChip
                  multiple={false}
                  label="Brand"
                  name="brand"
                  onChange={handleChangeForm}
                  onDelete={handleDeleteForm}
                  value={formData?.brand}
                  listArray={brandsList}
                  error={formErrors?.brand}
                />{" "}
              </div>
              <div style={{ marginTop: "15px", width: "100%" }}>
                <InputFields
                  label="Remarks"
                  name="remarks"
                  style={{ width: "100%" }}
                  value={formData?.remarks}
                  onChange={handleChangeForm}
                  error={formErrors?.remarks}
                  helperText={formErrors?.remarks}
                />
              </div>
              <div style={{ marginTop: "15px", width: "100%" }}>
                <h1 className={styles.orangeHead}>
                  Products{" "}
                  {formErrors?.products && (
                    <span>(* Please select products)</span>
                  )}
                </h1>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    gap: "3%",
                    overflow: "auto",
                    paddingBottom: "20px",
                  }}
                >
                  {droneProductsList.map((drone, index) => {
                    return (
                      <div key={drone?.id} className={styles.droneProductCard}>
                        <img
                          src={drone?.thumbnail}
                          alt={`${drone?.name} _avatar`}
                        />
                        <h3 title={drone?.name}>{drone?.name}</h3>
                        <div className={styles.priceCon}>
                          <span className={styles.srpText}>
                            Rs.{drone?.selling_price}
                          </span>
                          {drone?.selling_price != drone?.mrp && (
                            <span className={styles.mrpText}>
                              Rs.{drone?.mrp}
                            </span>
                          )}
                        </div>
                        <div className={styles.countCon}>
                          <span
                            className={styles.orangeCon}
                            onClick={() => {
                              handleDecreaseQuantity(
                                drone,
                                index,
                                formData?.user?.creator?.id
                              );
                            }}
                          >
                            -
                          </span>
                          <span className={styles.countVal}>
                            {getProductQuantity(drone)}
                          </span>
                          <span
                            className={styles.orangeCon}
                            onClick={() => {
                              handleIncreaseQuantity(
                                drone,
                                index,
                                formData?.user?.creator?.id
                              );
                            }}
                          >
                            +
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div style={{ marginTop: "15px", width: "100%" }}>
                <CustomSelectChip
                  multiple={false}
                  label="Coupon Code"
                  name="coupon"
                  onChange={handleChangeForm}
                  onDelete={handleDeleteForm}
                  value={formData?.coupon}
                  listArray={couponList}
                  error={formErrors?.coupon}
                />{" "}
              </div>

              <div style={{ marginTop: "15px", width: "100%" }}>
                <CustomSelectChip
                  multiple={false}
                  label="Payment Status"
                  name="payment_status"
                  onChange={handleChangeForm}
                  onDelete={handleDeleteForm}
                  value={formData?.payment_status}
                  listArray={paymentStatusList}
                  error={formErrors?.payment_status}
                />{" "}
              </div>
              {formData?.payment_status &&
                formData?.payment_status[0]?.id === 4 && (
                  <div style={{ marginTop: "15px", width: "100%" }}>
                    <CustomSelectChip
                      multiple={false}
                      label="Payment Mode"
                      name="payment_mode"
                      onChange={handleChangeForm}
                      onDelete={handleDeleteForm}
                      value={formData?.payment_mode}
                      listArray={paymentModeList}
                      error={formErrors?.payment_mode}
                    />{" "}
                  </div>
                )}

              {formData?.payment_status &&
                formData?.payment_status[0]?.id === 4 && (
                  <div style={{ marginTop: "15px", width: "100%" }}>
                    <InputFields
                      label="Transaction ID"
                      name="transaction_id"
                      style={{ width: "100%" }}
                      value={formData?.transaction_id}
                      onChange={handleChangeForm}
                      error={formErrors?.transaction_id}
                      helperText={formErrors?.transaction_id}
                    />{" "}
                  </div>
                )}
              <div style={{ marginTop: "15px", width: "max-content" }}>
                <Button
                  variant="contained"
                  disabled={loading}
                  style={{ background: loading ? "grey" : "" }}
                  onClick={(event) => {
                    handleOrderDroneProduct(event);
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
        {formData?.user &&
          formData?.user?.creator?.id &&
          formData?.products.length > 0 &&
          priceData?.total_amt && (
            <div style={{ width: "30%", marginTop: "60px" }}>
              <div className={styles.priceDetailsCon}>
                <h1 className={styles.priceBreakup}>Price Breakup</h1>
                <div>
                  <div className={styles.priceDetails}>
                    <h3>Total Amount</h3>
                    <p>&#8377; {priceData?.total_amt}</p>
                  </div>
                  <div className={styles.priceDetails}>
                    <h3>Sub Total Amount</h3>
                    <p>&#8377; {priceData?.sub_total}</p>
                  </div>
                  <div className={styles.priceDetails}>
                    <h3>Coupon Code</h3>
                    <p>{priceData?.coupon_code || "N.A"}</p>
                  </div>
                  <div className={styles.priceDetails}>
                    <h3>Discount</h3>
                    <p>&#8377; {priceData?.discount_amt}</p>
                  </div>
                  <div className={styles.priceDetails}>
                    <h3>GST </h3>
                    <p>&#8377; {priceData?.gst_amt}</p>
                  </div>

                  {priceData?.discount_amt > 0 && (
                    <div className={styles.savingCon}>
                      <img src={successGif} alt="success_gif" />
                      <p>
                        Congratulations! you have saved &#8377;{" "}
                        {priceData?.discount_amt} on this order.
                        <br />
                        <span>
                          Including GST saving of &#8377;{" "}
                          {priceData?.discount_amt}{" "}
                        </span>
                      </p>
                    </div>
                  )}
                  <div className={styles.finalAmountCon}>
                    <h3>Final Amount</h3>
                    <p>&#8377; {priceData?.after_discount_total_amt}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
      </div>
      <CustomModal open={open} onClose={handleClose} height="600px">
        <div>
          <h1 style={{ color: "#e95e33" }}>{`${
            addressForm?.id ? "Update" : "Add"
          } User Address`}</h1>
          <div style={{ marginTop: "15px", width: "100%" }}>
            <InputFields
              label="Pincode"
              required
              name="pincode"
              style={{ width: "100%" }}
              value={addressForm?.pincode}
              onChange={handleChangeAddress}
              error={addressFormErrors?.pincode}
              helperText={addressFormErrors?.pincode}
            />
          </div>
          <div style={{ marginTop: "15px", width: "100%" }}>
            <InputFields
              label="Address Line 1"
              name="line_1"
              style={{ width: "100%" }}
              value={addressForm?.line_1}
              onChange={handleChangeAddress}
            />
          </div>{" "}
          <div style={{ marginTop: "15px", width: "100%" }}>
            <InputFields
              label="Address Line 2"
              name="line_2"
              style={{ width: "100%" }}
              value={addressForm?.line_2}
              onChange={handleChangeAddress}
            />
          </div>{" "}
          <div style={{ marginTop: "15px", width: "100%" }}>
            <InputFields
              label="Plot No."
              name="plot_no"
              style={{ width: "100%" }}
              value={addressForm?.plot_no}
              onChange={handleChangeAddress}
            />
          </div>{" "}
          <div style={{ marginTop: "15px", width: "100%" }}>
            <InputFields
              label="City"
              required
              name="city"
              style={{ width: "100%" }}
              value={addressForm?.city}
              onChange={handleChangeAddress}
              error={addressFormErrors?.city}
              helperText={addressFormErrors?.city}
            />
          </div>{" "}
          <div style={{ marginTop: "15px", width: "100%" }}>
            <InputFields
              label="Tehsil"
              name="tehsil"
              style={{ width: "100%" }}
              value={addressForm?.tehsil}
              onChange={handleChangeAddress}
            />
          </div>{" "}
          <div style={{ marginTop: "15px", width: "100%" }}>
            <InputFields
              label="District"
              required
              name="district"
              style={{ width: "100%" }}
              value={addressForm?.district}
              onChange={handleChangeAddress}
              error={addressFormErrors?.district}
              helperText={addressFormErrors?.district}
            />
          </div>{" "}
          <div style={{ marginTop: "15px", width: "100%" }}>
            <InputFields
              label="State"
              required
              name="state"
              style={{ width: "100%" }}
              value={addressForm?.state}
              onChange={handleChangeAddress}
              error={addressFormErrors?.state}
              helperText={addressFormErrors?.state}
            />
          </div>
          <div style={{ marginTop: "15px", width: "100%" }}>
            <InputFields
              label="Country"
              required
              name="country"
              style={{ width: "100%" }}
              value={addressForm?.country}
              onChange={handleChangeAddress}
              error={addressFormErrors?.country}
              helperText={addressFormErrors?.country}
            />
          </div>
          <div style={{ margin: "20px 0px" }}>
            <Button
              variant="contained"
              onClick={(event) => {
                handlePostAddress(event);
              }}
            >
              Sumit
            </Button>
          </div>
        </div>
      </CustomModal>
    </Container>
  );
};

export default CreateDroneBooking;

const paymentStatusList = [
  {
    id: 1,
    title: "Pending",
  },
  // {
  //   id: 2,
  //   title: "Cancelled",
  // },
  // {
  //   id: 3,
  //   title: "Partially Paid",
  // },
  {
    id: 4,
    title: "Paid",
  },
  // {
  //   id: 5,
  //   title: "Failed",
  // },
  // {
  //   id: 6,
  //   title: "Refund",
  // },
];

const paymentModeList = [
  {
    id: 1,
    title: "Card",
  },
  {
    id: 2,
    title: "UPI",
  },
  {
    id: 3,
    title: "Net Banking",
  },
  // {
  //   id: 4,
  //   title: "Wallet",
  // },
  // {
  //   id: 5,
  //   title: "Cardless EMI",
  // },
];
