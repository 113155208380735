import * as React from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTabs3 = ({ tabHead, style, setTabVal, currentTab }) => {
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setTabVal(newValue);
  };

  return (
    <Box sx={style}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabHead.map((tabObj) => {
            return (
              <Tab
                style={{ fontSize: "14px" }}
                value={tabObj?.id}
                label={tabObj?.title}
                key={tabObj?.id}
                {...a11yProps(tabObj?.id)}
              />
            );
          })}
        </Tabs>
      </Box>
    </Box>
  );
};

export default CustomTabs3;
