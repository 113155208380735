import React, { useState, useEffect } from "react";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import DynamicForm from "../../Component/DynamicForm/DyamicForm";
import { open_api, token_api } from "../../Utils/network";
import CustomModal from "../../Component/CustomModal/CustomModal";
import AddAddress from "../../Component/AddAddress/AddAddress";
import styles from "./CreateServiceBooking.module.css";
import Rectangle from "../../Assets/Rectangle.png";
import edit from "../../Assets/edit.png";
import deleteicon from "../../Assets/deleteicon.png";
import { batch } from "react-redux";
import InputFields from "../../Component/InputFields/InputFields";
import CustomAutoforQuote from "../../Component/CustomAutoforQuote/CustomAutoforQuote";
import CreateOtherDeatils from "../../Component/CreateOtherDetails/CreateOtherDeatils";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";

const CreateServiceBooking = () => {
  let navigate = useNavigate();
  const [batchForm, setbatchForm] = useState({
    dduser: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [typedValue, setTypedValue] = useState("");
  const [dduserlisting, setDDuserListing] = useState([]);
  const [open, setOpen] = useState(false);
  const [buttonShow, setButtonShow] = useState(false);
  const [addressDetails, setaddressDetails] = useState([]);
  const [activeaddressid, setActiveAddressId] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [internalOption, setInternalOption] = useState([]);
  const [singleServiceSelect, setSingleServcieSelect] = useState({});
  const [questions, setQuestions] = useState([]);
  const [availableSlots, setAvaialableSlots] = useState([]);
  const [activeStage, setActiveStage] = useState(null);
  const [activeAddress, setActiveAddressDetails] = useState({});
  const [productsList, setProductsList] = useState([]);

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link
      to="/bookings/service-bookings"
      className="orange-1 bread breadElement"
    >
      Service Bookings{" "}
    </Link>,
    <div className="orange-1 bread">Create Service Booking</div>,
  ];

  const handleInputChange = (event, newValue) => {
    setTypedValue(newValue);
    setbatchForm((prev) => ({
      ...prev,
      dduser: newValue,
    }));
  };

  function ddUser(value) {
    let apiEndpoint = "/users/dd_profiles";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }
    open_api
      .get(apiEndpoint)
      .then((response) => {
        setDDuserListing(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    ddUser(typedValue);
  }, [typedValue]);

  function getAddress(id) {
    token_api
      .get(`/users/address?creator=${id}`)
      .then((response) => {
        setaddressDetails(response?.data?.data);
        setActiveAddressDetails(response?.data?.data[0]);
        setActiveAddressId(response?.data?.data[0]?.id);
        setButtonShow(false);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    if (batchForm?.dduser?.addresses.length == 0) {
      setOpen(true);
      setButtonShow(true);

      setaddressDetails([]);
      setActiveAddressId(null);
    } else if (batchForm?.dduser?.addresses.length != 0 && batchForm?.dduser) {
      getAddress(batchForm?.dduser?.creator?.id);
    }
  }, [batchForm?.dduser?.addresses]);

  useEffect(() => {
    if (batchForm?.dduser?.id) {
      setUserDetails((prev) => ({
        ...prev,
        phone: batchForm?.dduser?.creator?.mobile,
      }));
    }
  }, [batchForm?.dduser?.id]);

  function getslugDetails(urlslug) {
    token_api
      .get(`/service/${urlslug}`)
      .then((response) => {
        setQuestions(response?.data?.data?.other_details);
      })
      .catch((err) => {});
  }

  function getSlots(urlslug) {
    token_api
      .get(`/service/slot?service=${urlslug}`)
      .then((response) => {
        setAvaialableSlots(response?.data?.data);
        setActiveStage(response?.data?.data[0]?.id);
      })
      .catch((err) => {});
  }

  const getProductsList = () => {
    token_api
      .get(`service/booking/products`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setProductsList(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (singleServiceSelect?.id) {
      getslugDetails(singleServiceSelect?.slug);
      getSlots(singleServiceSelect?.slug);
      getProductsList();
    }
  }, [singleServiceSelect?.id]);

  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const handleUserSet = (incomingdata) => {
    setbatchForm((prev) => ({
      ...prev,
      dduser: {
        ...prev.dduser,
        addresses: [incomingdata],
      },
    }));

    setOpen(false);
    setButtonShow(false);
  };

  const handleClick = (event, id, info) => {
    event.stopPropagation();
    setActiveAddressId(id);
  };

  const handleDeleteAdd = (event, info) => {
    event.stopPropagation();
    const { id } = info;
    token_api
      .delete(`/users/address/${id}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Address Deleted Successfully!");
          getAddress(batchForm?.dduser?.creator?.id);
        }
      })
      .catch((err) => {});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function getOptionsforserice() {
    token_api
      .get(`/service`)
      .then((response) => {
        let selectOptions = response?.data?.data?.map((item) => {
          return {
            id: item?.id || null,
            title: item?.title || "",
            price: item?.price || "N.A",
            hsn_code: item?.hsn_code,
            slug: item?.slug || "NA",
          };
        });

        setInternalOption(selectOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getOptionsforserice();
  }, []);

  const handleChangeAutoComplete = (e) => {
    setSingleServcieSelect({
      ...singleServiceSelect,
      ...e,
      qty: "1",
      total: e?.price,
      slug: e?.slug,
    });
  };

  const handleditCick = (event, info) => {
    event.stopPropagation();
    const { id } = info;
    setActiveAddressDetails(info);

    setOpen(true);
  };

  const handleOpenAddAddress = () => {
    setOpen(true);
    setActiveAddressDetails({});
    setActiveAddressId(null);
  };

  const handleNavigateBack = () => {
    navigate(`/bookings/service-bookings`);
  };

  return (
    <Container>
      <div className="flexBetween">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>
      </div>

      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "50%" }}>
            <div style={{ width: "100%" }}>
              <DynamicForm
                value={batchForm?.dduser}
                onChange={handleInputChange}
                listing={dduserlisting}
                error={formErrors?.dduser}
                typedValue={typedValue}
                setTypedValue={setTypedValue}
              />
            </div>

            <div style={{ marginTop: "15px", width: "100%" }}>
              <InputFields
                label="Phone no*"
                name="phone"
                style={{ width: "100%" }}
                value={userDetails?.phone}
                onChange={handleChange}
                error={formErrors?.phone}
                helperText={formErrors?.phone}
                maxLength={10}
              />
            </div>

            <div style={{ marginTop: "15px", width: "100%" }}>
              <InputFields
                label="Gst no"
                name="gst"
                style={{ width: "100%" }}
                value={userDetails?.gst}
                onChange={handleChange}
                error={formErrors?.gst}
                helperText={formErrors?.gst}
              />
            </div>

            <div>
              <div className={styles.addressWrapper}>
                {batchForm?.dduser?.creator?.id && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <h3>User Addresses</h3>
                    <Button
                      variant="contained"
                      className="btnLinearGradient"
                      onClick={handleOpenAddAddress}
                    >
                      +ADD ADDRESS
                    </Button>
                  </div>
                )}
                {addressDetails?.map((info, index) => {
                  return (
                    <>
                      <div
                        key={info?.id}
                        // onClick={() => handleClick(info?.id, info)}
                        className={`${styles.mainWrapperConaddress} ${
                          styles.cursorp
                        }  ${
                          activeaddressid == info?.id && styles.activeaddress
                        }  ${
                          activeaddressid == info?.id && styles.selectedactive
                        }  `}
                      >
                        <div className={styles.mainrectImg}>
                          {activeaddressid == info?.id && (
                            <div className={styles.rectContainer}>
                              {" "}
                              <img
                                className={styles.rectImg}
                                src={Rectangle}
                              />{" "}
                            </div>
                          )}
                        </div>
                        <div>
                          {activeaddressid == info?.id && (
                            <p
                              className={`${styles.marginZero} ${styles.selectaddresstext} `}
                              style={{ margin: "0px" }}
                            >
                              Selected Address
                            </p>
                          )}
                        </div>

                        {/* {activeaddressid != info?.id && ( */}
                        <div className={styles.editanddelete}>
                          <div
                            className={styles.icons}
                            onClick={(event) => handleditCick(event, info)}
                          >
                            <img src={edit} />
                          </div>
                          <div
                            className={styles.icons}
                            onClick={(event) => handleDeleteAdd(event, info)}
                          >
                            <img src={deleteicon} />
                          </div>
                        </div>
                        {/* )} */}

                        <div
                          className={styles.leftWrapper}
                          onClick={(event) =>
                            handleClick(event, info?.id, info)
                          }
                        >
                          <div className={`${styles.activestateCon}`}>
                            <p
                              className={`${styles.landtypeCon} ${
                                activeaddressid == info?.id &&
                                styles.activeaddressTop
                              }   `}
                            >
                              {" "}
                              <span className={styles.dotbutton}>
                                &#x2022;
                              </span>{" "}
                              {`${info?.line_2 ? info?.line_2 : ""}`}{" "}
                            </p>
                          </div>

                          <p
                            style={{ marginBottom: "0px" }}
                            className={`${styles.marginZero}`}
                          >
                            {`${info?.line_2 ? info?.line_2 + "," : ""}`}{" "}
                            {info?.landmark ? info?.landmark : ""}{" "}
                          </p>
                          <p
                            style={{ margin: "0px" }}
                            className={`${styles.marginZero}`}
                          >
                            {`${info?.city}`} {` ${info?.state}`}{" "}
                            {`${info?.pincode} ${info?.country}  `}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>

            <div style={{ width: "100%" }}>
              <InputFields
                label="Remark"
                name="remark"
                style={{ width: "100%" }}
                value={userDetails?.remark}
                onChange={handleChange}
                error={formErrors?.remark}
                helperText={formErrors?.remark}
              />
            </div>
            <div style={{ marginTop: "15px", width: "100%" }}>
              <CustomAutoforQuote
                value={userDetails?.title}
                name="title"
                handleChange={handleChangeAutoComplete}
                options={internalOption}
                error={formErrors?.title}
                label="Service type*"
              />
            </div>

            <div style={{ marginTop: "15px", width: "100%" }}>
              <InputFields
                label="Dealer Code"
                name="dealer_code"
                style={{ width: "100%" }}
                value={userDetails?.dealer_code}
                onChange={handleChange}
              />
            </div>
          </div>
          <div style={{ width: "45%" }}>
            <CreateOtherDeatils
              questionsdata={questions}
              availableSlots={availableSlots}
              activeStage={activeStage}
              activeaddressid={activeaddressid}
              setActiveStage={setActiveStage}
              userDetails={userDetails}
              dduser={batchForm?.dduser}
              setFormErrors={setFormErrors}
              formErrors={formErrors}
              singleServiceSelect={singleServiceSelect}
              handleOpen={handleOpen}
              productsList={productsList}
              activeAddress={activeAddress}
            />
          </div>
        </div>
      </Container>

      <CustomModal open={open} onClose={handleClose}>
        <p>
          {" "}
          {!activeAddress?.id && (
            <p>
              Since the user currently lacks address details, could you please
              provide the PIN code details? This information is necessary for us
              to generate GST details.
            </p>
          )}
        </p>
        <AddAddress
          handleDrawerClose={handleClose}
          userListGet={ddUser}
          selectedUser={batchForm?.dduser}
          handleUserSet={handleUserSet}
          activeAddress={activeAddress}
          activeaddressid={activeaddressid}
        />
      </CustomModal>
    </Container>
  );
};

export default CreateServiceBooking;
