import React, { useEffect, useState, useRef } from "react";
import Container from "../../Component/Container/Container";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import styles from "./drone.module.css";
import { token_api, base_url } from "../../Utils/network";
import { formatDroneMasterList, formatBatteryMasterList } from "./droneUtility";
import { useNavigate, Link } from "react-router-dom";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import droneAvailable from "../../Assets/droneAvailable.svg";
import droneNotAvailable from "../../Assets/droneNotAvailable.svg";
import { Button } from "@mui/material";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import InputFields from "../../Component/InputFields/InputFields";
import editIcon from "../../Assets/edit_icon.svg";
import deleteIcon from "../../Assets/delete_icon.svg";
import {
  ValidateVehicle,
  ValidateCharger,
  ValidateBattery,
  ValidateChargingHub,
  ValidateLatopMaster,
  ValidateDrone,
} from "./droneUtility";
import laptopImage from "../../Assets/lapTopAsset.png";
import chargingHubAsset from "../../Assets/chargingHubAsset.png";
import droneImg from "../../Assets/drone18x53.png";
import assetVehicle from "../../Assets/assetVehicle.svg";
import assetBattery from "../../Assets/assetBattery.svg";
import assetCharger from "../../Assets/assetCharger.svg";
import TextEditor from "../../Component/TextEditor/TextEditor";
import axios from "axios";
import { useSelector } from "react-redux";
import CustomModal from "../../Component/CustomModal/CustomModal";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DroneMaster = () => {
  let navigate = useNavigate();
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Drone & Battery Master</div>,
  ];
  const { access_token } = useSelector((state) => state.auth);

  const [filters, setfilters] = useState({});
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [value, setValue] = React.useState(0);
  const [droneList, setDroneList] = useState([]);

  const [open, setOpen] = useState(false);
  const [drawerState, setDrawerState] = useState("");
  const [openDailog, setOPenDailog] = useState(false);
  const [modalState, setModalState] = useState(1);
  const [deletedDrone, setDeletedDrone] = useState(null); //1
  const [deletedBattery, setDeletedBattery] = useState(null); //2
  const [deletedVehicle, setDeletedVehicle] = useState(null); //3
  const [deletedCharger, setDeletedCharger] = useState(null); //4
  const [deletedChargingHub, setDeletedChargingHub] = useState(null); //5
  const [deletedLaptop, setDeletedLaptop] = useState(null); //6

  //Drone....
  const [droneMasterList, setDroneMasterList] = useState([]);
  const [droneForm, setDroneForm] = useState({
    id: null,
    category_id: [],
    title: "",
    tagline: "",
    certification: "",
    model_no: "",
    slug: "",
    price: "",
    about: "",
    available_for_sale: [],
    is_available: [],
  });

  const [droneFormErrors, setDroneFormErrors] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const descriptionRef = useRef(null);

  const [droneIcon, setDroneIcon] = useState("");
  const [droneIconPreview, setDroneIconPreview] = useState("");
  const droneIconInput = useRef(null);
  const droneBannerInput = useRef(null);
  const [droneBanner, setDroneBanner] = useState("");
  const [droneBannerPreview, setDroneBannerPreview] = useState("");

  //Vehicle....
  const [vehicleMasterList, setVehicleMasterList] = useState([]);
  const [vehicleForm, setVehicleForm] = useState({
    name: "",
    company: "",
    status: [],
  });
  const [vehicleId, setVehicleId] = useState(null);
  const [vehicleFormErrors, setVehicleFormErrors] = useState({});
  const [vehicleIcon, setVehicleIcon] = useState("");
  const [vehicleIconPreview, setVehicleIconPreview] = useState("");
  const vehicleIconInput = useRef(null);

  //Charger...
  const [chargerMasterList, setChargerMasterList] = useState([]);
  const [chargerForm, setChargerForm] = useState({
    name: "",
    model: "",
    status: [],
    available_for_sale: [],
  });
  const [chargerId, setChargerId] = useState(null);
  const [chargerFormErrors, setChargerFormErrors] = useState({});
  const [chargerIcon, setChargerIcon] = useState("");
  const [chargerIconPreview, setChargerIconPreview] = useState("");
  const chargerIconInput = useRef(null);

  //Battery...
  const [batteryMasterList, setBatteryMasterList] = useState([]);
  const [batteryForm, setBatteryForm] = useState({
    id: null,
    name: "",
    power: "",
    drone_id: [],
  });
  const [batteryFormErrors, setBatteryFormErrors] = useState({});

  const [batteryIcon, setBatteryIcon] = useState("");
  const [batteryIconPreview, setBatteryIconPreview] = useState("");
  const batteryIconInput = useRef(null);

  //Charging Hub....

  const [chargingHubMatserList, setChargingHubMasterList] = useState([]);
  const [chargingHubForm, setChargingHubForm] = useState({
    id: null,
    model: "",
    manufacturer: "",
  });
  const [chargingHubFormErrors, setChargingHubFormErrors] = useState({});
  const [chargingHubIcon, setChargingHubIcon] = useState("");
  const [chargingHubIconPreview, setChargingHubIconPreview] = useState("");
  const chargingHubIconInput = useRef(null);

  //Laptop Master.....
  const [laptopMasterList, setLaptopMasterList] = useState([]);
  const [laptopForm, setLaptopForm] = useState({
    id: null,
    model: "",
    manufacturer: "",
  });
  const [laptopErrors, setLaptopErrors] = useState({});
  const [laptopIcon, setLaptopIcon] = useState("");
  const [laptopIconPreview, setLaptopIconPreview] = useState("");
  const laptopIconInput = useRef(null);

  //Icon uploading....
  function handleDrop(e, type) {
    e.preventDefault();

    if (type === "laptop") {
      if (e?.target?.files) {
        setLaptopIcon(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setLaptopIconPreview(imageUrl);
      } else if (e?.dataTransfer?.files) {
        setLaptopIcon(e?.dataTransfer?.files[0]);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setLaptopIconPreview(imageUrl);
      }
    } else if (type === "charging_hub") {
      if (e?.target?.files) {
        setChargingHubIcon(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setChargingHubIconPreview(imageUrl);
      } else if (e?.dataTransfer?.files) {
        setChargingHubIcon(e?.dataTransfer?.files[0]);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setChargingHubIconPreview(imageUrl);
      }
    } else if (type === "charger") {
      if (e?.target?.files) {
        setChargerIcon(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setChargerIconPreview(imageUrl);
      } else if (e?.dataTransfer?.files) {
        setChargerIcon(e?.dataTransfer?.files[0]);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setChargerIconPreview(imageUrl);
      }
    } else if (type === "vehicle") {
      if (e?.target?.files) {
        setVehicleIcon(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setVehicleIconPreview(imageUrl);
      } else if (e?.dataTransfer?.files) {
        setVehicleIcon(e?.dataTransfer?.files[0]);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setVehicleIconPreview(imageUrl);
      }
    } else if (type === "battery") {
      if (e?.target?.files) {
        setBatteryIcon(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setBatteryIconPreview(imageUrl);
      } else if (e?.dataTransfer?.files) {
        setBatteryIcon(e?.dataTransfer?.files[0]);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setBatteryIconPreview(imageUrl);
      }
    } else if (type === "drone") {
      if (e?.target?.files) {
        setDroneIcon(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setDroneIconPreview(imageUrl);
      } else if (e?.dataTransfer?.files) {
        setDroneIcon(e?.dataTransfer?.files[0]);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setDroneIconPreview(imageUrl);
      }
    } else if (type === "drone_banner") {
      if (e?.target?.files) {
        setDroneBanner(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setDroneBannerPreview(imageUrl);
      } else if (e?.dataTransfer?.files) {
        setDroneBanner(e?.dataTransfer?.files[0]);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setDroneBannerPreview(imageUrl);
      }
    }
  }

  const handleClick = (type) => {
    if (type === "laptop") {
      laptopIconInput.current.click();
    } else if (type === "charging_hub") {
      chargingHubIconInput.current.click();
    } else if (type === "charger") {
      chargerIconInput.current.click();
    } else if (type === "vehicle") {
      vehicleIconInput.current.click();
    } else if (type === "battery") {
      batteryIconInput.current.click();
    } else if (type === "drone") {
      droneIconInput.current.click();
    } else if (type === "drone_banner") {
      droneBannerInput.current.click();
    }
  };
  function handleDragOver(e) {
    e.preventDefault();
  }

  //Tab changing....
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    setfilters({});
  };

  //Drone CRUD operations....
  const getDroneMasterListing = async (filterobject) => {
    try {
      const fetchDroneMaster = await token_api.get(`drone`, {
        params: { ...filterobject },
      });
      if (fetchDroneMaster.status !== 200) {
        throw new Error("Error in fetching");
      }
      setPaginationData(fetchDroneMaster?.data);
      const modifiedData = formatDroneMasterList(
        fetchDroneMaster?.data?.data,
        handleEditDrone,
        handleDeleteDroneModal
      );
      setDroneMasterList(modifiedData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditDrone = (editObject) => {
    console.log(editObject, categoryList, "hariEditObject");
    // let categoryItem = categoryList?.filter(
    //   (info) => info?.id == editObject?.category?.id
    // );
    let availableForSale = availableOptions?.filter(
      (info) => info?.id == editObject?.available_for_sale
    );
    let isAvailable = statusOptions?.filter(
      (info) => info?.id == editObject?.is_available
    );
    setOpen(true);
    setDrawerState("drone");

    setDroneForm({
      ...droneForm,
      id: editObject?.id,
      title: editObject?.title,
      certification: editObject?.certification,
      tagline: editObject?.tagline,
      price: editObject?.price,
      model_no: editObject?.model_no,
      slug: editObject?.slug,
      available_for_sale: availableForSale,
      is_available: isAvailable,
      category_id: editObject?.category
        ? [{ id: editObject?.category?.id, title: editObject?.category?.title }]
        : [],
      about: editObject?.about,
    });

    setDroneFormErrors({});
    setDroneIconPreview(editObject?.drone_image);
    setDroneBannerPreview(editObject?.banner);
  };

  const handleDeleteDrone = () => {
    token_api.delete(`drone/${deletedDrone}/`).then((response) => {
      if (
        response?.status === 200 ||
        response?.status === 201 ||
        response?.status === 204
      ) {
        alert(`Drone Master Deleted Successfully!`);
        setModalState(1);
        setOPenDailog(false);
        setDeletedDrone(null);
        getDroneMasterListing();
      }
    });
  };

  const handleDeleteDroneModal = (droneSlug) => {
    setModalState(1);
    setOPenDailog(true);
    setDeletedDrone(droneSlug);
  };

  const handleAddDrone = (e) => {
    let isDescriptionsEmpty;

    if (descriptionRef.current) {
      const content = descriptionRef.current.getContent();
      droneForm.about = content;

      // Set a boolean value based on whether the content is empty or not
      isDescriptionsEmpty = !content.trim();
    }
    e.preventDefault();
    let data = {
      category_id: droneForm?.category_id[0]?.id,
      title: droneForm?.title,
      ...(droneForm?.tagline && { tagline: droneForm?.tagline }),
      ...(droneForm?.certification && {
        certification: droneForm?.certification,
      }),
      ...(droneForm?.price && { price: droneForm?.price }),
      model_no: droneForm?.model_no,
      slug: droneForm?.slug,
      available_for_sale: droneForm?.available_for_sale[0]?.id,
      is_available: droneForm?.is_available[0]?.id,
      about: droneForm?.about,
    };

    ValidateDrone(droneForm)
      .then(async (response) => {
        if (response == "success") {
          if (!droneForm?.id) {
            const droneData = await token_api.post(`drone/`, data);

            if (droneIcon || droneBanner) {
              let droneIconItem = new FormData();
              if (droneIcon) droneIconItem.append("drone_image", droneIcon);
              if (droneBanner) droneIconItem.append("banner", droneBanner);

              await axios({
                method: "patch",
                url: `${base_url}drone/${droneData?.data?.data?.slug}/`,
                data: droneIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }

            if (droneData?.status === 200 || droneData?.status === 201) {
              alert(`Drone master created successfully!`);
              setOpen(false);
              setDroneForm({
                ...droneForm,
                id: null,
                category_id: [],
                title: "",
                tagline: "",
                certification: "",
                model_no: "",
                slug: "",
                price: "",
                about: "",
                available_for_sale: [],
                is_available: [],
              });
              getDroneMasterListing();
              setDroneIcon("");
              setDroneIconPreview("");
              setDroneBanner("");
              setDroneBannerPreview("");
              setDroneFormErrors({});
            }
          } else {
            const droneData = await token_api.patch(
              `drone/${droneForm?.slug}/`,
              data
            );

            if (droneIcon || droneBanner) {
              let droneIconItem = new FormData();
              if (droneIcon) droneIconItem.append("drone_image", droneIcon);
              if (droneBanner) droneIconItem.append("banner", droneBanner);

              await axios({
                method: "patch",
                url: `${base_url}drone/${droneForm?.slug}/`,
                data: droneIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            if (droneData?.status === 200 || droneData?.status === 201) {
              alert(`Drone master updated successfully!`);
              setOpen(false);
              setDroneForm({
                ...droneForm,
                id: null,
                category_id: [],
                title: "",
                tagline: "",
                certification: "",
                model_no: "",
                slug: "",
                price: "",
                about: "",
                available_for_sale: [],
                is_available: [],
              });
              getDroneMasterListing();
              setDroneIcon("");
              setDroneIconPreview("");
              setDroneBanner("");
              setDroneBannerPreview("");
              setDroneFormErrors({});
            }
          }
        }
      })
      .catch((error) => {
        setDroneFormErrors(error);
      });
  };

  const handleChangeDrone = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setDroneForm({
        ...droneForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setDroneForm({
        ...droneForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setDroneForm({
        ...droneForm,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };

  const handleDeleteChipDrone = (id, selectName) => {
    setDroneForm({
      ...droneForm,
      [selectName]: droneForm?.[selectName]?.filter((chip) => chip?.id !== id),
    });
    let namefiled = vehicleForm[selectName];
  };
  //Battery CRUD operations...
  const getBatteryMasterList = async (filterobject) => {
    try {
      const fetchBatteryMaster = await token_api.get(`drone/battery`, {
        params: { ...filterobject },
      });
      if (fetchBatteryMaster.status !== 200) {
        throw new Error("Error in fetching");
      }
      setPaginationData(fetchBatteryMaster?.data);
      const modifiedData = formatBatteryMasterList(
        fetchBatteryMaster?.data?.data,
        handleEditBattery,
        handleDeleteBatteryModal
      );
      setBatteryMasterList(modifiedData);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditBattery = (editBattery) => {
    let droneItem = droneList?.filter(
      (info) => info?.id == editBattery?.drone?.id
    );
    setOpen(true);
    setDrawerState("battery");
    setBatteryForm({
      ...batteryForm,
      id: editBattery?.id,
      name: editBattery?.name,
      power: editBattery?.power,
      drone_id: droneItem,
    });
    setBatteryFormErrors({});
    setBatteryIconPreview(editBattery?.image);
  };
  const handleDeleteBattery = () => {
    token_api.delete(`drone/battery/${deletedBattery}/`).then((response) => {
      if (
        response?.status === 200 ||
        response?.status === 201 ||
        response?.status === 204
      ) {
        alert(`Battery Master Deleted Successfully!`);
        setModalState(2);
        setOPenDailog(false);
        setDeletedBattery(null);
        getBatteryMasterList();
      }
    });
  };

  const handleDeleteBatteryModal = (batteryId) => {
    setModalState(2);
    setOPenDailog(true);
    setDeletedBattery(batteryId);
  };

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  const handleChangeacc = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setfilters({
        ...filters,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };

  const handleDeleteChipacc = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
    let namefiled = filters[selectName];
  };
  const getDroneList = () => {
    token_api
      .get(`drone?page_size=100`)
      .then((res) => {
        if (res.data.data) {
          setDroneList(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCategoryList = () => {
    token_api
      .get(`drone/category?page_size=100`)
      .then((res) => {
        if (res.data.data) {
          setCategoryList(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getDroneList();
    getCategoryList();
  }, []);

  const handleAdd = (addType) => {
    if (addType === "drone") {
      setOpen(true);
      setDrawerState(addType);
      setDroneForm({
        ...droneForm,
        id: null,
        category_id: [],
        title: "",
        tagline: "",
        certification: "",
        model_no: "",
        slug: "",
        price: "",
        about: "",
        available_for_sale: [],
        is_available: [],
      });
      setDroneFormErrors({});
      setDroneIcon("");
      setDroneIconPreview("");
      setDroneBanner("");
      setDroneBannerPreview("");
    } else if (addType === "vehicle") {
      setOpen(true);
      setDrawerState(addType);
      setVehicleForm({
        ...vehicleForm,
        name: "",
        company: "",
        status: [],
      });
      setVehicleId(null);
      setVehicleFormErrors({});
      setVehicleIcon("");
      setVehicleIconPreview("");
    } else if (addType === "charger") {
      setOpen(true);
      setDrawerState(addType);
      setChargerForm({
        ...chargerForm,
        name: "",
        model: "",
        status: [],
        available_for_sale: [],
      });
      setChargerId(null);
      setChargerFormErrors({});
      setChargerIcon("");
      setChargerIconPreview("");
    } else if (addType === "battery") {
      setOpen(true);
      setBatteryForm({
        ...batteryForm,
        id: null,
        name: "",
        power: "",
        drone_id: [],
      });
      setDrawerState(addType);
      setBatteryFormErrors({});
      setBatteryIcon("");
      setBatteryIconPreview("");
    } else if (addType === "charging_hub") {
      setOpen(true);
      setChargingHubForm({
        ...chargingHubForm,
        id: null,
        model: "",
        manufacturer: "",
      });
      setDrawerState(addType);
      setChargingHubFormErrors({});
      setChargingHubIcon("");
      setChargingHubIconPreview("");
    } else if (addType === "laptop") {
      setOpen(true);
      setLaptopForm({
        ...laptopForm,
        id: null,
        model: "",
        manufacturer: "",
      });
      setDrawerState(addType);
      setLaptopErrors({});
      setLaptopIcon("");
      setLaptopIconPreview("");
    }
  };

  const handleText = (e, type, data, auto) => {
    if (type === "drone") {
      const { name, value } = e.target;
      setDroneForm({ ...droneForm, [name]: value });
    } else if (type === "vehicle") {
      const { name, value } = e.target;
      setVehicleForm({ ...vehicleForm, [name]: value });
    } else if (type === "charger") {
      const { name, value } = e.target;
      setChargerForm({ ...chargerForm, [name]: value });
    } else if (type === "battery") {
      const { name, value } = e.target;
      setBatteryForm({ ...batteryForm, [name]: value });
    } else if (type === "charging_hub") {
      const { name, value } = e.target;
      setChargingHubForm({ ...chargingHubForm, [name]: value });
    } else if (type === "laptop") {
      const { name, value } = e.target;
      setLaptopForm({ ...laptopForm, [name]: value });
    }
  };

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setVehicleForm({
        ...vehicleForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setVehicleForm({
        ...vehicleForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setVehicleForm({
        ...vehicleForm,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };

  const handleDeleteChip = (id, selectName) => {
    setVehicleForm({
      ...vehicleForm,
      [selectName]: vehicleForm?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
    let namefiled = vehicleForm[selectName];
  };

  const handleChangeCharger = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setChargerForm({
        ...chargerForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setChargerForm({
        ...chargerForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setChargerForm({
        ...chargerForm,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };

  const handleDeleteChipCharger = (id, selectName) => {
    setChargerForm({
      ...chargerForm,
      [selectName]: chargerForm?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
    let namefiled = chargerForm[selectName];
  };

  //Vehicle Related CRUD Operations...

  const getVehicleMasterList = async (filters) => {
    try {
      const fetchVehilceList = await token_api.get(`drone/vehicles`, {
        params: { ...filters },
        paramsSerializer: {
          indexes: null,
        },
      });
      if (fetchVehilceList.status !== 200) {
        throw new Error("Error while fetching vehicles data");
      }
      setPaginationData(fetchVehilceList?.data);

      let modifiedArray = fetchVehilceList?.data?.data?.map((item) => {
        return {
          id: <p style={{ fontSize: "12px" }}>{item?.id}</p>,

          name: <p style={{ fontSize: "12px" }}>{item?.name}</p>,
          company: <p style={{ fontSize: "12px" }}>{item?.company}</p>,

          status: (
            <p
              style={{
                color: `${item?.status ? "rgb(3, 98, 2)" : "rgb(174, 47, 7)"}`,
                background: `${
                  item?.status
                    ? "rgb(212, 236, 195)"
                    : "rgba(255, 145, 111, 0.5)"
                }`,
                fontSize: "12px",
              }}
              className={styles.statusText}
            >
              {item?.status ? "Active" : "Inactive"}
            </p>
          ),
          image: (
            <img
              src={item?.image ? item?.image : assetVehicle}
              style={{ width: "100px", height: "100px", objectFit: "contain" }}
              alt="vehicle_image"
            />
          ),
          action: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={(e) => handleEditVehicle("vehicle", item)}
              >
                <img src={editIcon} />
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleDeleteVehicleModal(item?.id)}
              >
                <img src={deleteIcon} />
              </div>
            </div>
          ),
        };
      });
      setVehicleMasterList([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddVehicle = (e) => {
    e.preventDefault();
    let data = {
      name: vehicleForm?.name,
      company: vehicleForm?.company,
      status: vehicleForm?.status[0]?.id,
    };

    ValidateVehicle(vehicleForm)
      .then(async (response) => {
        if (response == "success") {
          if (!vehicleId) {
            const vehicleData = await token_api.post(`drone/vehicle/`, data);

            if (vehicleIcon) {
              let vehicleIconItem = new FormData();
              vehicleIconItem.append("image", vehicleIcon);

              await axios({
                method: "patch",
                url: `${base_url}drone/vehicle/${vehicleData?.data?.data?.id}/`,
                data: vehicleIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            if (vehicleData?.status === 200 || vehicleData?.status === 201) {
              alert(`Vehicle master created successfully!`);
              setOpen(false);
              setVehicleForm({
                ...vehicleForm,
                name: "",
                company: "",
                status: [],
              });
              setVehicleId(null);
              getVehicleMasterList();
              setVehicleIcon("");
              setVehicleIconPreview("");
              setVehicleFormErrors({});
            }
          } else {
            const vehicleData = await token_api.patch(
              `drone/vehicle/${vehicleId}/`,
              data
            );

            if (vehicleIcon) {
              let vehicleIconItem = new FormData();
              vehicleIconItem.append("image", vehicleIcon);

              await axios({
                method: "patch",
                url: `${base_url}drone/vehicle/${vehicleId}/`,
                data: vehicleIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            if (vehicleData?.status === 200 || vehicleData?.status === 201) {
              alert(`Vehicle master updated successfully!`);
              setOpen(false);
              setVehicleForm({
                ...vehicleForm,
                name: "",
                company: "",
                status: [],
              });
              setVehicleId(null);
              getVehicleMasterList();
              setVehicleIcon("");
              setVehicleIconPreview("");
              setVehicleFormErrors({});
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setVehicleFormErrors(error);
      });
  };

  const handleDeleteVehicle = () => {
    token_api
      .delete(`drone/vehicle/${deletedVehicle}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Vehicle Master Deleted Successfully!");
          setOPenDailog(false);
          setModalState(3);
          setDeletedVehicle(null);
          getVehicleMasterList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteVehicleModal = (vehicleId) => {
    setOPenDailog(true);
    setModalState(3);
    setDeletedVehicle(vehicleId);
  };

  const handleEditVehicle = (addType, item) => {
    let isactive = statusOptions?.filter((info) => info?.id == item?.status);
    setOpen(true);
    setDrawerState(addType);
    setVehicleForm({
      ...vehicleForm,
      name: item?.name,
      company: item?.company,
      status: isactive,
    });
    setVehicleId(item?.id);
    setVehicleFormErrors({});
    setVehicleIconPreview(item?.image);
  };

  //Charger Related CRUD Operations...

  const getChargerMasterList = async (filters) => {
    try {
      const fetchChargerList = await token_api.get(`drone/chargers`, {
        params: { ...filters },
        paramsSerializer: {
          indexes: null,
        },
      });

      if (fetchChargerList.status !== 200) {
        throw new Error("Error while fetching chargers data");
      }
      setPaginationData(fetchChargerList?.data);

      let modifiedArray = fetchChargerList?.data?.data?.map((item) => {
        return {
          id: <p style={{ fontSize: "12px" }}>{item?.id}</p>,

          name: <p style={{ fontSize: "12px" }}>{item?.name}</p>,
          model: <p style={{ fontSize: "12px" }}>{item?.model}</p>,
          is_available: (
            <img
              src={
                item?.available_for_sale ? droneAvailable : droneNotAvailable
              }
            />
          ),
          image: (
            <img
              src={item?.image ? item?.image : assetCharger}
              style={{ width: "100px", height: "100px", objectFit: "contain" }}
              alt="charger_image"
            />
          ),

          status: (
            <p
              style={{
                color: `${item?.status ? "rgb(3, 98, 2)" : "rgb(174, 47, 7)"}`,
                background: `${
                  item?.status
                    ? "rgb(212, 236, 195)"
                    : "rgba(255, 145, 111, 0.5)"
                }`,
                fontSize: "12px",
              }}
              className={styles.statusText}
            >
              {item?.status ? "Active" : "Inactive"}
            </p>
          ),
          action: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onClick={(e) => handleEditCharger("charger", item)}
              >
                <img src={editIcon} />
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleDeleteChargerModal(item?.id)}
              >
                <img src={deleteIcon} />
              </div>
            </div>
          ),
        };
      });
      setChargerMasterList([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditCharger = (addType, item) => {
    let isactive = statusOptions?.filter((info) => info?.id == item?.status);
    let isavailable = availableOptions?.filter(
      (info) => info?.id == item?.available_for_sale
    );

    setOpen(true);
    setDrawerState(addType);
    setChargerForm({
      ...chargerForm,
      name: item?.name,
      model: item?.model,
      status: isactive,
      available_for_sale: isavailable,
    });
    setChargerId(item?.id);
    setChargerFormErrors({});
    setChargerIconPreview(item?.image);
  };
  const handleDeleteCharger = () => {
    token_api
      .delete(`drone/charger/${deletedCharger}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Charger Master Deleted Successfully!");
          setOPenDailog(false);
          setModalState(4);
          setDeletedCharger(null);
          getChargerMasterList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteChargerModal = (chargerId) => {
    setDeletedCharger(chargerId);
    setOPenDailog(true);
    setModalState(4);
  };
  const handleAddCharger = (e) => {
    e.preventDefault();
    let data = {
      name: chargerForm?.name,
      model: chargerForm?.model,
      status: chargerForm?.status[0]?.id,
      available_for_sale: chargerForm?.available_for_sale[0]?.id,
    };

    ValidateCharger(chargerForm)
      .then(async (response) => {
        if (response == "success") {
          if (!chargerId) {
            const chargerData = await token_api.post(`drone/charger/`, data);

            if (chargerIcon) {
              let chargerIconItem = new FormData();
              chargerIconItem.append("image", chargerIcon);

              await axios({
                method: "patch",
                url: `${base_url}drone/charger/${chargerData?.data?.data?.id}/`,
                data: chargerIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            if (chargerData?.status === 200 || chargerData?.status === 201) {
              alert(`Charger master created successfully!`);
              setOpen(false);
              setChargerForm({
                ...chargerForm,
                name: "",
                model: "",
                status: [],
                available_for_sale: [],
              });
              setChargerId(null);
              setChargerFormErrors({});
              getChargerMasterList();
              setChargerIcon("");
              setChargerIconPreview("");
              setChargerFormErrors({});
            }
          } else {
            const chargerData = await token_api.patch(
              `drone/charger/${chargerId}/`,
              data
            );

            if (chargerIcon) {
              let chargerIconItem = new FormData();
              chargerIconItem.append("image", chargerIcon);

              await axios({
                method: "patch",
                url: `${base_url}drone/charger/${chargerId}/`,
                data: chargerIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }

            if (chargerData?.status === 200 || chargerData?.status === 201) {
              alert(`Charger master updated successfully!`);
              setOpen(false);
              setChargerForm({
                ...chargerForm,
                name: "",
                model: "",
                status: [],
                available_for_sale: [],
              });
              setChargerId(null);
              setChargerFormErrors({});
              getChargerMasterList();
              setChargerIcon("");
              setChargerIconPreview("");
              setChargerFormErrors({});
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setChargerFormErrors(error);
      });
  };

  //Battery Related CRUD Operations...

  const handleAddBattery = (e) => {
    e.preventDefault();
    let data = {
      name: batteryForm?.name,
      power: batteryForm?.power,
      drone_id: batteryForm?.drone_id[0]?.id,
    };

    ValidateBattery(batteryForm)
      .then(async (response) => {
        if (response == "success") {
          if (!batteryForm?.id) {
            const batteryData = await token_api.post(`drone/battery/`, data);

            if (batteryIcon) {
              let batteryIconItem = new FormData();
              batteryIconItem.append("image", batteryIcon);

              await axios({
                method: "patch",
                url: `${base_url}drone/battery/${batteryData?.data?.data?.id}/`,
                data: batteryIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }

            if (batteryData?.status === 200 || batteryData?.status === 201) {
              alert(`Battery master created successfully!`);
              setOpen(false);
              setBatteryForm({
                ...batteryForm,
                id: null,
                name: "",
                power: "",
                drone_id: [],
              });
              setBatteryFormErrors({});
              getBatteryMasterList();
              setBatteryIcon("");
              setBatteryIconPreview("");
              setBatteryFormErrors({});
            }
          } else {
            const batteryData = await token_api.patch(
              `drone/battery/${batteryForm?.id}/`,
              data
            );

            if (batteryIcon) {
              let batteryIconItem = new FormData();
              batteryIconItem.append("image", batteryIcon);

              await axios({
                method: "patch",
                url: `${base_url}drone/battery/${batteryForm?.id}/`,
                data: batteryIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }

            if (batteryData?.status === 200 || batteryData?.status === 201) {
              alert(`Battery master updated successfully!`);
              setOpen(false);
              setBatteryForm({
                ...batteryForm,
                id: null,
                name: "",
                power: "",
                drone_id: [],
              });
              setBatteryFormErrors({});
              getBatteryMasterList();
              setBatteryIcon("");
              setBatteryIconPreview("");
              setBatteryFormErrors({});
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setBatteryFormErrors(error);
      });
  };

  const handleSelectDrone = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setBatteryForm({
        ...batteryForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setBatteryForm({
        ...batteryForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setBatteryForm({
        ...batteryForm,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };

  const handleDeleteSelectDrone = (id, selectName) => {
    setBatteryForm({
      ...batteryForm,
      [selectName]: batteryForm?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
    let namefiled = batteryForm[selectName];
  };

  useEffect(() => {
    if (value === 0) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};

        if (pageState) {
          filtersObject.page = pageState;
        }

        if (filters?.q) {
          filtersObject.q = filters?.q;
        }
        if (filters?.title) {
          filtersObject.title = filters?.title;
        }
        if (filters?.category) {
          const ids = filters?.category?.map((info) => info?.id);
          filtersObject.category = ids[0];
        }
        if (filters?.is_available != null) {
          let ids = filters?.is_available[0]?.id;
          filtersObject.is_available = ids;
        }
        getDroneMasterListing(filtersObject);
      }, 500);

      return () => clearTimeout(timeOut);
    } else if (value === 1) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};
        if (pageState) {
          filtersObject.page = pageState;
        }
        if (filters?.q) {
          filtersObject.q = filters?.q;
        }
        if (filters?.power) {
          filtersObject.power = filters?.power;
        }

        if (filters?.drone) {
          const ids = filters?.drone?.map((info) => info?.slug);
          filtersObject.drone = ids[0];
        }
        getBatteryMasterList(filtersObject);
      }, 500);

      return () => clearTimeout(timeOut);
    } else if (value === 2) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};
        if (pageState) {
          filtersObject.page = pageState;
        }
        if (filters?.q) {
          filtersObject.q = filters?.q;
        }
        if (filters?.status) {
          const ids = filters?.status?.map((info) => info?.id);
          filtersObject.status = ids[0];
        }

        getVehicleMasterList(filtersObject);
      }, 500);

      return () => clearTimeout(timeOut);
    } else if (value === 3) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};
        if (pageState) {
          filtersObject.page = pageState;
        }
        if (filters?.q) {
          filtersObject.q = filters?.q;
        }
        if (filters?.status) {
          const ids = filters?.status?.map((info) => info?.id);
          filtersObject.status = ids[0];
        }

        getChargerMasterList(filtersObject);
      }, 500);

      return () => clearTimeout(timeOut);
    } else if (value === 4) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};
        if (pageState) {
          filtersObject.page = pageState;
        }
        if (filters?.q) {
          filtersObject.q = filters?.q;
        }

        getChargingHubMasterList(filtersObject);
      }, 500);
    } else if (value === 5) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};
        if (pageState) {
          filtersObject.page = pageState;
        }
        if (filters?.q) {
          filtersObject.q = filters?.q;
        }

        getLaptopMasterList(filtersObject);
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [
    pageState,
    filters?.q,
    filters?.drone,
    value,
    filters?.title,
    filters?.category,
    filters?.is_available,
    filters?.power,
    filters?.status,
  ]);

  //Charging Hub Master CRUD operations....

  const getChargingHubMasterList = async (filters) => {
    try {
      const fetchChargingHubMasterList = await token_api.get(
        `drone/charging_hubs`,
        {
          params: { ...filters },
          paramsSerializer: {
            indexes: null,
          },
        }
      );

      if (fetchChargingHubMasterList.status !== 200) {
        throw new Error("Error while fetching charging hub data");
      }
      setPaginationData(fetchChargingHubMasterList?.data);

      let modifiedArray = fetchChargingHubMasterList?.data?.data?.map(
        (item) => {
          return {
            id: <p style={{ fontSize: "12px" }}>{item?.id}</p>,

            model: <p style={{ fontSize: "12px" }}>{item?.model}</p>,
            manufacturer: (
              <p style={{ fontSize: "12px" }}>{item?.manufacturer}</p>
            ),
            image: (
              <div>
                {" "}
                <img
                  src={item?.image ? item?.image : chargingHubAsset}
                  style={{ width: "80px", height: "80px" }}
                  alt="charging_hub_icon"
                />{" "}
              </div>
            ),
            action: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  justifyContent: "center",
                }}
              >
                <img
                  src={editIcon}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEditChargingHub("charging_hub", item)}
                />

                <img
                  src={deleteIcon}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteChargingHubModal(item?.id)}
                />
              </div>
            ),
          };
        }
      );
      setChargingHubMasterList([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteChargingHub = () => {
    token_api
      .delete(`drone/charging_hub/${deletedChargingHub}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Charging Hub Deleted Successfully!");
          setOPenDailog(false);
          setModalState(5);
          setDeletedChargingHub(null);
          getChargingHubMasterList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteChargingHubModal = (chargingHubId) => {
    setOPenDailog(true);
    setModalState(5);
    setDeletedChargingHub(chargingHubId);
  };

  const handleEditChargingHub = (addType, item) => {
    setOpen(true);
    setDrawerState(addType);
    setChargingHubForm({
      ...chargingHubForm,
      id: item?.id,
      model: item?.model,
      manufacturer: item?.manufacturer,
    });
    setChargingHubFormErrors({});
    setChargingHubIconPreview(item?.image);
  };

  const handleAddChargingHub = (e) => {
    e.preventDefault();
    let data = {
      model: chargingHubForm?.model,
      manufacturer: chargingHubForm?.manufacturer,
    };

    ValidateChargingHub(chargingHubForm)
      .then(async (response) => {
        if (response == "success") {
          if (!chargingHubForm?.id) {
            const chargingHubData = await token_api.post(
              `drone/charging_hub/`,
              data
            );

            if (chargingHubIcon) {
              let chargingHubIconItem = new FormData();
              chargingHubIconItem.append("image", chargingHubIcon);

              await axios({
                method: "patch",
                url: `${base_url}drone/charging_hub/${chargingHubData?.data?.data?.id}/`,
                data: chargingHubIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }

            if (
              chargingHubData?.status === 200 ||
              chargingHubData?.status === 201
            ) {
              alert(`Charginghub master created successfully!`);
              setOpen(false);
              setChargingHubForm({
                ...chargingHubForm,
                id: null,
                model: "",
                manufacturer: "",
              });
              setChargingHubFormErrors({});
              setChargingHubIcon("");
              setChargingHubIconPreview("");
              getChargingHubMasterList();
            }
          } else {
            const chargingHubData = await token_api.patch(
              `drone/charging_hub/${chargingHubForm?.id}/`,
              data
            );

            if (chargingHubIcon) {
              let chargingHubIconItem = new FormData();
              chargingHubIconItem.append("image", chargingHubIcon);

              await axios({
                method: "patch",
                url: `${base_url}drone/charging_hub/${chargingHubForm?.id}/`,
                data: chargingHubIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }

            if (
              chargingHubData?.status === 200 ||
              chargingHubData?.status === 201
            ) {
              alert(`Charginghub master created successfully!`);
              setOpen(false);
              setChargingHubForm({
                ...chargingHubForm,
                id: null,
                model: "",
                manufacturer: "",
              });
              setChargingHubFormErrors({});
              setChargingHubIcon("");
              setChargingHubIconPreview("");
              getChargingHubMasterList();
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setChargingHubFormErrors(error);
      });
  };

  //Laptop Master CRUD operations...

  const getLaptopMasterList = async (filters) => {
    try {
      const fetchLaptopMasterList = await token_api.get(`drone/laptops`, {
        params: { ...filters },
        paramsSerializer: {
          indexes: null,
        },
      });

      if (fetchLaptopMasterList.status !== 200) {
        throw new Error("Error while fetching laptop data");
      }
      setPaginationData(fetchLaptopMasterList?.data);

      let modifiedArray = fetchLaptopMasterList?.data?.data?.map((item) => {
        return {
          id: <p style={{ fontSize: "12px" }}>{item?.id}</p>,

          model: <p style={{ fontSize: "12px" }}>{item?.model}</p>,
          manufacturer: (
            <p style={{ fontSize: "12px" }}>{item?.manufacturer}</p>
          ),
          image: (
            <div>
              {" "}
              <img
                src={item?.image ? item?.image : laptopImage}
                style={{ width: "80px", height: "80px" }}
                alt="laptop_master"
              />
            </div>
          ),

          action: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "10px",
                justifyContent: "center",
              }}
            >
              <img
                src={editIcon}
                style={{ cursor: "pointer" }}
                onClick={() => handleEditLaptop("laptop", item)}
              />

              <img
                src={deleteIcon}
                style={{ cursor: "pointer" }}
                onClick={() => handleDeleteLaptopMasterModal(item?.id)}
              />
            </div>
          ),
        };
      });
      setLaptopMasterList([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteLaptopMaster = () => {
    token_api
      .delete(`drone/laptop/${deletedLaptop}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Laptop Master Deleted Successfully!");
          setOPenDailog(false);
          setModalState(6);
          setDeletedLaptop(null);
          getLaptopMasterList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleDeleteLaptopMasterModal = (laptopId) => {
    setOPenDailog(true);
    setModalState(6);
    setDeletedLaptop(laptopId);
  };

  const handleEditLaptop = (addType, item) => {
    setOpen(true);
    setDrawerState(addType);
    setLaptopForm({
      ...laptopForm,
      id: item?.id,
      model: item?.model,
      manufacturer: item?.manufacturer,
    });
    setLaptopErrors({});
    setLaptopIconPreview(item?.image);
  };

  const handleAddingLaptopMaster = (e) => {
    e.preventDefault();
    let data = {
      model: laptopForm?.model,
      manufacturer: laptopForm?.manufacturer,
    };

    ValidateLatopMaster(laptopForm)
      .then(async (response) => {
        if (response == "success") {
          if (!laptopForm?.id) {
            const laptopData = await token_api.post(`drone/laptop/`, data);

            if (laptopIcon) {
              let laptopIconItem = new FormData();
              laptopIconItem.append("image", laptopIcon);

              await axios({
                method: "patch",
                url: `${base_url}drone/laptop/${laptopData?.data?.data?.id}/`,
                data: laptopIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }

            if (laptopData?.status === 200 || laptopData?.status === 201) {
              alert("Laptop master created successfully!");
              setOpen(false);
              setLaptopForm({
                ...laptopForm,
                id: null,
                model: "",
                manufacturer: "",
              });
              setLaptopErrors({});
              setLaptopIcon("");
              setLaptopIconPreview("");
              getLaptopMasterList();
            }
          } else {
            const laptopData = await token_api.patch(
              `drone/laptop/${laptopForm?.id}/`,
              data
            );

            if (laptopIcon) {
              let laptopIconItem = new FormData();
              laptopIconItem.append("image", laptopIcon);

              await axios({
                method: "patch",
                url: `${base_url}drone/laptop/${laptopForm?.id}/`,
                data: laptopIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }

            if (laptopData?.status === 200 || laptopData?.status === 201) {
              alert("Laptop master updated successfully!");
              setOpen(false);
              setLaptopForm({
                ...laptopForm,
                id: null,
                model: "",
                manufacturer: "",
              });
              setLaptopErrors({});
              setLaptopIcon("");
              setLaptopIconPreview("");
              getLaptopMasterList();
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLaptopErrors(error);
      });
  };

  const handleCloseModal = () => {
    setOPenDailog(false);
    setModalState(1);
    setDeletedBattery(null);
    setDeletedLaptop(null);
    setDeletedDrone(null);
    setDeletedCharger(null);
    setDeletedChargingHub(null);
    setDeletedVehicle(null);
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>

      <div>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Drone Master" {...a11yProps(0)} />
            <Tab label="Battery Master" {...a11yProps(1)} />
            <Tab label="Vehicle Master" {...a11yProps(2)} />
            <Tab label="Charger Master" {...a11yProps(3)} />
            <Tab label="Charger Hub Master" {...a11yProps(4)} />
            <Tab label="Laptop Master" {...a11yProps(5)} />
          </Tabs>
        </Box>
      </div>

      {value === 0 && (
        <div>
          <FilterContainer
            style={{ maxHeight: "40px", marginTop: "20px" }}
            // className={`${styles.marginTopBox}`}
          >
            <CustomSearchFilter
              placeholder="Search by drone title, category title..."
              onChange={(e) => {
                setfilters({ ...filters, q: e });
              }}
            />

            {/* <InputFields
              label="Filter By Title"
              name="title"
              value={filters?.title || ""}
              onChange={(e) => {
                setfilters({
                  ...filters,
                  ["title"]: e.target.value,
                });
              }}
            /> */}

            <CustomSelectChip
              multiple={false}
              label="Filter By Category"
              name="category"
              listArray={categoryList}
              onChange={handleChangeacc}
              onDelete={handleDeleteChipacc}
              value={filters?.category}
            />
            <CustomSelectChip
              multiple={false}
              label="Filter By Status"
              name="is_available"
              listArray={statusOptions}
              onChange={handleChangeacc}
              onDelete={handleDeleteChipacc}
              value={filters?.is_available}
            />

            <Button
              variant="contained"
              onClick={() => handleAdd("drone")}
              sx={{ height: "53px", fontSize: "14px" }}
              style={{ fontSize: "14px" }}
            >
              Add
            </Button>
          </FilterContainer>

          <div className="totalCountText">
            Total Count : <span>{total_count}</span>
          </div>

          <div className={styles.tableContainer}>
            <CustomTable
              columns={tableColumns}
              data={droneMasterList}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      )}

      {value === 1 && (
        <div>
          <FilterContainer
            style={{ maxHeight: "40px", marginTop: "20px" }}
            // className={`${styles.marginTopBox}`}
          >
            <CustomSearchFilter
              placeholder="Search by battery name, drone name..."
              onChange={(e) => {
                setfilters({ ...filters, q: e });
              }}
            />
            <InputFields
              label="Filter By Power"
              name="power"
              type="number"
              value={filters?.power || ""}
              onChange={(e) => {
                setfilters({
                  ...filters,
                  ["power"]: e.target.value,
                });
              }}
            />

            <CustomSelectChip
              multiple={false}
              label="Filter By Drone"
              name="drone"
              listArray={droneList}
              onChange={handleChangeacc}
              onDelete={handleDeleteChipacc}
              value={filters?.drone}
            />
            <Button
              variant="contained"
              onClick={() => handleAdd("battery")}
              sx={{ height: "53px" }}
            >
              Add
            </Button>
          </FilterContainer>

          <div className="totalCountText">
            Total Count : <span>{total_count}</span>
          </div>

          <div className={styles.tableContainer}>
            <CustomTable
              columns={batteryTableColumns}
              data={batteryMasterList}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      )}

      {value === 2 && (
        <div>
          <FilterContainer
            style={{ maxHeight: "40px", marginTop: "20px" }}
            // className={`${styles.marginTopBox}`}
          >
            <CustomSearchFilter
              placeholder="Search by vehicle name, company name..."
              onChange={(e) => {
                setfilters({ ...filters, q: e });
              }}
            />

            <CustomSelectChip
              multiple={false}
              label="Filter By Status"
              name="status"
              listArray={statusOptions}
              onChange={handleChangeacc}
              onDelete={handleDeleteChipacc}
              value={filters?.status}
            />

            <Button
              variant="contained"
              onClick={() => handleAdd("vehicle")}
              sx={{ height: "53px" }}
            >
              Add
            </Button>
          </FilterContainer>
          <div className="totalCountText">
            Total Count : <span>{total_count}</span>
          </div>

          <div className={styles.tableContainer}>
            <CustomTable
              columns={vehicleMasterColumns}
              data={vehicleMasterList}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      )}

      {value === 3 && (
        <div>
          <FilterContainer
            style={{ maxHeight: "40px", marginTop: "20px" }}
            // className={`${styles.marginTopBox}`}
          >
            <CustomSearchFilter
              placeholder="Search by charger name, model..."
              onChange={(e) => {
                setfilters({ ...filters, q: e });
              }}
            />{" "}
            <CustomSelectChip
              multiple={false}
              label="Filter By Status"
              name="status"
              listArray={statusOptions}
              onChange={handleChangeacc}
              onDelete={handleDeleteChipacc}
              value={filters?.status}
            />
            <Button
              variant="contained"
              onClick={() => handleAdd("charger")}
              sx={{ height: "53px" }}
            >
              Add
            </Button>
          </FilterContainer>
          <div className="totalCountText">
            Total Count : <span>{total_count}</span>
          </div>

          <div className={styles.tableContainer}>
            <CustomTable
              columns={chargerMasterColumns}
              data={chargerMasterList}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      )}

      {value === 4 && (
        <div>
          <FilterContainer
            style={{ maxHeight: "40px", marginTop: "20px" }}
            // className={`${styles.marginTopBox}`}
          >
            <CustomSearchFilter
              placeholder="Search by model, manufacturer..."
              onChange={(e) => {
                setfilters({ ...filters, q: e });
              }}
            />{" "}
            <Button
              variant="contained"
              onClick={() => handleAdd("charging_hub")}
              sx={{ height: "53px" }}
            >
              Add
            </Button>
          </FilterContainer>
          <div className="totalCountText">
            Total Count : <span>{total_count}</span>
          </div>

          <div className={styles.tableContainer}>
            <CustomTable
              columns={chargingHubMasterColumns}
              data={chargingHubMatserList}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      )}

      {value === 5 && (
        <div>
          <FilterContainer
            style={{ maxHeight: "40px", marginTop: "20px" }}
            // className={`${styles.marginTopBox}`}
          >
            <CustomSearchFilter
              placeholder="Search by model, manufacturer..."
              onChange={(e) => {
                setfilters({ ...filters, q: e });
              }}
            />{" "}
            <Button
              variant="contained"
              onClick={() => handleAdd("laptop")}
              sx={{ height: "53px" }}
            >
              Add
            </Button>
          </FilterContainer>
          <div className="totalCountText">
            Total Count : <span>{total_count}</span>
          </div>

          <div className={styles.tableContainer}>
            <CustomTable
              columns={laptopMasterColumns}
              data={laptopMasterList}
              // handleClick={handleRedirect}
              // tableFor=""
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </div>
      )}

      <DrawerComp open={open} onClose={() => setOpen(false)} anchor="right">
        {drawerState === "drone" && (
          <DrawerContainer>
            <DrawerTitle
              text={droneForm?.id ? "Update Drone Master" : "Add Drone Master"}
              handleCancelDrw={() => setOpen((prev) => !prev)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <CustomSelectChip
                multiple={false}
                label="Select Category *"
                name="category_id"
                onChange={handleChangeDrone}
                onDelete={handleDeleteChipDrone}
                value={droneForm?.category_id}
                listArray={categoryList}
                error={droneFormErrors?.category_id}
              />
              <InputFields
                required
                label="Title"
                name="title"
                value={droneForm?.title || ""}
                onChange={(e) => {
                  handleText(e, "drone");
                }}
                error={droneFormErrors?.title}
                helperText={droneFormErrors?.title}
              />
              <InputFields
                // required
                label="Tagline"
                name="tagline"
                value={droneForm?.tagline || ""}
                onChange={(e) => {
                  handleText(e, "drone");
                }}
                // error={droneFormErrors?.tagline}
                // helperText={droneFormErrors?.tagline}
              />
              <InputFields
                // required
                label="Certification"
                name="certification"
                value={droneForm?.certification || ""}
                onChange={(e) => {
                  handleText(e, "drone");
                }}
                // error={droneFormErrors?.certification}
                // helperText={droneFormErrors?.certification}
              />
              <InputFields
                required
                label="Model No"
                name="model_no"
                value={droneForm?.model_no || ""}
                onChange={(e) => {
                  handleText(e, "drone");
                }}
                error={droneFormErrors?.model_no}
                helperText={droneFormErrors?.model_no}
              />
              <InputFields
                required
                label="Slug"
                name="slug"
                value={droneForm?.slug || ""}
                onChange={(e) => {
                  handleText(e, "drone");
                }}
                error={droneFormErrors?.slug}
                helperText={droneFormErrors?.slug}
              />
              <InputFields
                label="Price"
                // required
                name="price"
                type="number"
                value={droneForm?.price || ""}
                onChange={(e) => {
                  handleText(e, "drone");
                }}
                // error={droneFormErrors?.price}
                // helperText={droneFormErrors?.price}
              />

              <CustomSelectChip
                multiple={false}
                label="Available For Sale"
                name="available_for_sale"
                onChange={handleChangeDrone}
                onDelete={handleDeleteChipDrone}
                value={droneForm?.available_for_sale}
                listArray={availableOptions}
                // error={droneFormErrors?.available_for_sale}
              />
              <CustomSelectChip
                multiple={false}
                label="Status"
                name="is_available"
                onChange={handleChangeDrone}
                onDelete={handleDeleteChipDrone}
                value={droneForm?.is_available}
                listArray={statusOptions}
                // error={droneFormErrors?.is_available}
              />

              <div className={`${styles.formContainer} mt-20`}>
                <p style={{ marginBottom: "10px" }}>About Drone</p>
                <TextEditor
                  editorRef={descriptionRef}
                  initialValue={droneForm?.about}
                />
                {/* <small style={{ marginTop: "5px", color: "#d32f2f" }}>
                  {droneFormErrors?.about && "Please enter about the drone"}
                </small> */}
              </div>

              <div className={`${styles.formContainer} mt-20`}>
                <div className={`${styles.titleStyles}`}>Bannner </div>

                <div style={{ marginTop: "20px" }}>
                  <div
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, "drone_banner")}
                    className={styles.dropBoxStyles}
                  >
                    <div
                      onClick={() => handleClick("drone_banner")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>{/* <img src={dragDrop} alt="dradanddrop" /> */}</p>
                      <p>Drag & Drop the File or click here</p>
                      <input
                        ref={droneBannerInput}
                        type="file"
                        // id="myfile"
                        // name="myfile"
                        // multiple
                        onChange={(e) => handleDrop(e, "drone_banner")}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              {droneBannerPreview && (
                <div className={`${styles.formContainer} mt-20`}>
                  <div className={styles.commonFieldsBox}>
                    <div>
                      <p
                        // style={{ color: palette?.primary[500] }}
                        className={`${styles.titleStyles}`}
                      >
                        Banner Preview
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={`${styles.flexBetween} ${styles.imgCardBox} `}
                          style={{ marginTop: "10px" }}
                        >
                          {" "}
                          <img
                            src={droneBannerPreview}
                            alt="icon"
                            width={200}
                            height={140}
                          />
                          <div onClick={() => handleClick("drone_banner")}>
                            <Button
                              variant="contained"
                              // onClick={() => {
                              //   handleMediaDelete("image");
                              // }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className={`${styles.formContainer} mt-20`}>
                <div className={`${styles.titleStyles}`}>Image </div>

                <div style={{ marginTop: "20px" }}>
                  <div
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, "drone")}
                    className={styles.dropBoxStyles}
                  >
                    <div
                      onClick={() => handleClick("drone")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>{/* <img src={dragDrop} alt="dradanddrop" /> */}</p>
                      <p>Drag & Drop the File or click here</p>
                      <input
                        ref={droneIconInput}
                        type="file"
                        // id="myfile"
                        // name="myfile"
                        // multiple
                        onChange={(e) => handleDrop(e, "drone")}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              {droneIconPreview && (
                <div className={`${styles.formContainer} mt-20`}>
                  <div className={styles.commonFieldsBox}>
                    <div>
                      <p
                        // style={{ color: palette?.primary[500] }}
                        className={`${styles.titleStyles}`}
                      >
                        Image Preview
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={`${styles.flexBetween} ${styles.imgCardBox} `}
                          style={{ marginTop: "10px" }}
                        >
                          {" "}
                          <img
                            src={droneIconPreview}
                            alt="icon"
                            width={200}
                            height={140}
                          />
                          <div onClick={() => handleClick("drone")}>
                            <Button
                              variant="contained"
                              // onClick={() => {
                              //   handleMediaDelete("image");
                              // }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Button variant="contained" onClick={handleAddDrone}>
                {droneForm?.id ? "Update" : "Add"}{" "}
              </Button>
            </div>
          </DrawerContainer>
        )}

        {drawerState === "vehicle" && (
          <DrawerContainer>
            <DrawerTitle
              text={vehicleId ? "Update Vehicle Master" : "Add Vehicle Master"}
              handleCancelDrw={() => setOpen((prev) => !prev)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <InputFields
                required
                label="Vehicle Name"
                name="name"
                value={vehicleForm?.name || ""}
                onChange={(e) => {
                  handleText(e, "vehicle");
                }}
                error={vehicleFormErrors?.name}
                helperText={vehicleFormErrors?.name}
              />
              <InputFields
                required
                label="Company"
                name="company"
                value={vehicleForm?.company || ""}
                onChange={(e) => {
                  handleText(e, "vehicle");
                }}
                error={vehicleFormErrors?.company}
                helperText={vehicleFormErrors?.company}
              />

              <CustomSelectChip
                multiple={false}
                label="Status *"
                name="status"
                onChange={handleChange}
                onDelete={handleDeleteChip}
                value={vehicleForm?.status}
                listArray={statusOptions}
                error={vehicleFormErrors?.status}
              />

              <div className={`${styles.formContainer} mt-20`}>
                <div className={`${styles.titleStyles}`}>Image </div>

                <div style={{ marginTop: "20px" }}>
                  <div
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, "vehicle")}
                    className={styles.dropBoxStyles}
                  >
                    <div
                      onClick={() => handleClick("vehicle")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>{/* <img src={dragDrop} alt="dradanddrop" /> */}</p>
                      <p>Drag & Drop the File or click here</p>
                      <input
                        ref={vehicleIconInput}
                        type="file"
                        // id="myfile"
                        // name="myfile"
                        // multiple
                        onChange={(e) => handleDrop(e, "vehicle")}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              {vehicleIconPreview && (
                <div className={`${styles.formContainer} mt-20`}>
                  <div className={styles.commonFieldsBox}>
                    <div>
                      <p
                        // style={{ color: palette?.primary[500] }}
                        className={`${styles.titleStyles}`}
                      >
                        Image Preview
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={`${styles.flexBetween} ${styles.imgCardBox} `}
                          style={{ marginTop: "10px" }}
                        >
                          {" "}
                          <img
                            src={vehicleIconPreview}
                            alt="icon"
                            width={200}
                            height={140}
                          />
                          <div onClick={() => handleClick("vehicle")}>
                            <Button
                              variant="contained"
                              // onClick={() => {
                              //   handleMediaDelete("image");
                              // }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Button variant="contained" onClick={handleAddVehicle}>
                {vehicleId ? "Update" : "Add"}{" "}
              </Button>
            </div>
          </DrawerContainer>
        )}

        {drawerState === "charger" && (
          <DrawerContainer>
            <DrawerTitle
              text={chargerId ? "Update Charger Master" : "Add Charger Master"}
              handleCancelDrw={() => setOpen((prev) => !prev)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <InputFields
                required
                label="Charger Name"
                name="name"
                value={chargerForm?.name || ""}
                onChange={(e) => {
                  handleText(e, "charger");
                }}
                error={chargerFormErrors?.name}
                helperText={chargerFormErrors?.name}
              />
              <InputFields
                required
                label="Charger Model"
                name="model"
                value={chargerForm?.model || ""}
                onChange={(e) => {
                  handleText(e, "charger");
                }}
                error={chargerFormErrors?.model}
                helperText={chargerFormErrors?.model}
              />

              <CustomSelectChip
                multiple={false}
                label="Status *"
                name="status"
                onChange={handleChangeCharger}
                onDelete={handleDeleteChipCharger}
                value={chargerForm?.status}
                listArray={statusOptions}
                error={chargerFormErrors?.status}
              />

              <CustomSelectChip
                multiple={false}
                label="Available For Sale *"
                name="available_for_sale"
                onChange={handleChangeCharger}
                onDelete={handleDeleteChipCharger}
                value={chargerForm?.available_for_sale}
                listArray={availableOptions}
                error={chargerFormErrors?.available_for_sale}
              />

              <div className={`${styles.formContainer} mt-20`}>
                <div className={`${styles.titleStyles}`}>Image </div>

                <div style={{ marginTop: "20px" }}>
                  <div
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, "charger")}
                    className={styles.dropBoxStyles}
                  >
                    <div
                      onClick={() => handleClick("charger")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>{/* <img src={dragDrop} alt="dradanddrop" /> */}</p>
                      <p>Drag & Drop the File or click here</p>
                      <input
                        ref={chargerIconInput}
                        type="file"
                        // id="myfile"
                        // name="myfile"
                        // multiple
                        onChange={(e) => handleDrop(e, "charger")}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              {chargerIconPreview && (
                <div className={`${styles.formContainer} mt-20`}>
                  <div className={styles.commonFieldsBox}>
                    <div>
                      <p
                        // style={{ color: palette?.primary[500] }}
                        className={`${styles.titleStyles}`}
                      >
                        Image Preview
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={`${styles.flexBetween} ${styles.imgCardBox} `}
                          style={{ marginTop: "10px" }}
                        >
                          {" "}
                          <img
                            src={chargerIconPreview}
                            alt="icon"
                            width={200}
                            height={140}
                          />
                          <div onClick={() => handleClick("charger")}>
                            <Button
                              variant="contained"
                              // onClick={() => {
                              //   handleMediaDelete("image");
                              // }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Button variant="contained" onClick={handleAddCharger}>
                {chargerId ? "Update" : "Add"}{" "}
              </Button>
            </div>
          </DrawerContainer>
        )}

        {drawerState === "battery" && (
          <DrawerContainer>
            <DrawerTitle
              text={
                batteryForm?.id ? "Update Battery Master" : "Add Battery Master"
              }
              handleCancelDrw={() => setOpen((prev) => !prev)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <InputFields
                required
                label="Battery Name"
                name="name"
                value={batteryForm?.name || ""}
                onChange={(e) => {
                  handleText(e, "battery");
                }}
                error={batteryFormErrors?.name}
                helperText={batteryFormErrors?.name}
              />
              <InputFields
                required
                label="Battery Power"
                name="power"
                value={batteryForm?.power || ""}
                onChange={(e) => {
                  handleText(e, "battery");
                }}
                error={batteryFormErrors?.power}
                helperText={batteryFormErrors?.power}
              />

              <CustomSelectChip
                multiple={false}
                label="Select Drone *"
                name="drone_id"
                listArray={droneList}
                onChange={handleSelectDrone}
                onDelete={handleDeleteSelectDrone}
                value={batteryForm?.drone_id}
                error={batteryFormErrors?.drone_id}
                helperText={batteryFormErrors?.drone_id}
              />

              <div className={`${styles.formContainer} mt-20`}>
                <div className={`${styles.titleStyles}`}>Image </div>

                <div style={{ marginTop: "20px" }}>
                  <div
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, "battery")}
                    className={styles.dropBoxStyles}
                  >
                    <div
                      onClick={() => handleClick("battery")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>{/* <img src={dragDrop} alt="dradanddrop" /> */}</p>
                      <p>Drag & Drop the File or click here</p>
                      <input
                        ref={batteryIconInput}
                        type="file"
                        // id="myfile"
                        // name="myfile"
                        // multiple
                        onChange={(e) => handleDrop(e, "battery")}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              {batteryIconPreview && (
                <div className={`${styles.formContainer} mt-20`}>
                  <div className={styles.commonFieldsBox}>
                    <div>
                      <p
                        // style={{ color: palette?.primary[500] }}
                        className={`${styles.titleStyles}`}
                      >
                        Image Preview
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={`${styles.flexBetween} ${styles.imgCardBox} `}
                          style={{ marginTop: "10px" }}
                        >
                          {" "}
                          <img
                            src={batteryIconPreview}
                            alt="icon"
                            width={200}
                            height={140}
                          />
                          <div onClick={() => handleClick("battery")}>
                            <Button
                              variant="contained"
                              // onClick={() => {
                              //   handleMediaDelete("image");
                              // }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Button variant="contained" onClick={handleAddBattery}>
                {batteryForm?.id ? "Update" : "Add"}{" "}
              </Button>
            </div>
          </DrawerContainer>
        )}

        {drawerState === "charging_hub" && (
          <DrawerContainer>
            <DrawerTitle
              text={
                chargingHubForm?.id
                  ? "Update Charging Hub Master"
                  : "Add Charging Hub Master"
              }
              handleCancelDrw={() => setOpen((prev) => !prev)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <InputFields
                required
                label="Model"
                name="model"
                value={chargingHubForm?.model || ""}
                onChange={(e) => {
                  handleText(e, "charging_hub");
                }}
                error={chargingHubFormErrors?.model}
                helperText={chargingHubFormErrors?.model}
              />
              <InputFields
                required
                label="Manufacturer"
                name="manufacturer"
                value={chargingHubForm?.manufacturer || ""}
                onChange={(e) => {
                  handleText(e, "charging_hub");
                }}
                error={chargingHubFormErrors?.manufacturer}
                helperText={chargingHubFormErrors?.manufacturer}
              />

              <div className={`${styles.formContainer} mt-20`}>
                <div className={`${styles.titleStyles}`}>Image </div>

                <div style={{ marginTop: "20px" }}>
                  <div
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, "charging_hub")}
                    className={styles.dropBoxStyles}
                  >
                    <div
                      onClick={() => handleClick("charging_hub")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>{/* <img src={dragDrop} alt="dradanddrop" /> */}</p>
                      <p>Drag & Drop the File or click here</p>
                      <input
                        ref={chargingHubIconInput}
                        type="file"
                        // id="myfile"
                        // name="myfile"
                        // multiple
                        onChange={(e) => handleDrop(e, "charging_hub")}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              {chargingHubIconPreview && (
                <div className={`${styles.formContainer} mt-20`}>
                  <div className={styles.commonFieldsBox}>
                    <div>
                      <p
                        // style={{ color: palette?.primary[500] }}
                        className={`${styles.titleStyles}`}
                      >
                        Image Preview
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={`${styles.flexBetween} ${styles.imgCardBox} `}
                          style={{ marginTop: "10px" }}
                        >
                          {" "}
                          <img
                            src={chargingHubIconPreview}
                            alt="icon"
                            width={200}
                            height={140}
                          />
                          <div onClick={() => handleClick("charging_hub")}>
                            <Button
                              variant="contained"
                              // onClick={() => {
                              //   handleMediaDelete("image");
                              // }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Button variant="contained" onClick={handleAddChargingHub}>
                {chargingHubForm?.id ? "Update" : "Add"}{" "}
              </Button>
            </div>
          </DrawerContainer>
        )}

        {drawerState === "laptop" && (
          <DrawerContainer>
            <DrawerTitle
              text={
                laptopForm?.id ? "Update Laptop Master" : "Add Laptop Master"
              }
              handleCancelDrw={() => setOpen((prev) => !prev)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "20px",
              }}
            >
              <InputFields
                required
                label="Model"
                name="model"
                value={laptopForm?.model || ""}
                onChange={(e) => {
                  handleText(e, "laptop");
                }}
                error={laptopErrors?.model}
                helperText={laptopErrors?.model}
              />
              <InputFields
                required
                label="Manufacturer"
                name="manufacturer"
                value={laptopForm?.manufacturer || ""}
                onChange={(e) => {
                  handleText(e, "laptop");
                }}
                error={laptopErrors?.manufacturer}
                helperText={laptopErrors?.manufacturer}
              />

              <div className={`${styles.formContainer} mt-20`}>
                <div className={`${styles.titleStyles}`}>Image </div>

                <div style={{ marginTop: "20px" }}>
                  <div
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, "laptop")}
                    className={styles.dropBoxStyles}
                  >
                    <div
                      onClick={() => handleClick("laptop")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>{/* <img src={dragDrop} alt="dradanddrop" /> */}</p>
                      <p>Drag & Drop the File or click here</p>
                      <input
                        ref={laptopIconInput}
                        type="file"
                        // id="myfile"
                        // name="myfile"
                        // multiple
                        onChange={(e) => handleDrop(e, "laptop")}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              {laptopIconPreview && (
                <div className={`${styles.formContainer} mt-20`}>
                  <div className={styles.commonFieldsBox}>
                    <div>
                      <p
                        // style={{ color: palette?.primary[500] }}
                        className={`${styles.titleStyles}`}
                      >
                        Image Preview
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={`${styles.flexBetween} ${styles.imgCardBox} `}
                          style={{ marginTop: "10px" }}
                        >
                          {" "}
                          <img
                            src={laptopIconPreview}
                            alt="icon"
                            width={200}
                            height={140}
                          />
                          <div onClick={() => handleClick("laptop")}>
                            <Button
                              variant="contained"
                              // onClick={() => {
                              //   handleMediaDelete("image");
                              // }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <Button variant="contained" onClick={handleAddingLaptopMaster}>
                {laptopForm?.id ? "Update" : "Add"}{" "}
              </Button>
            </div>
          </DrawerContainer>
        )}
      </DrawerComp>
      <CustomModal open={openDailog} onClose={handleCloseModal} height="300px">
        {modalState === 1 && (
          <div className="alertBox">
            <h3>Are you sure you want to delete the drone master?</h3>
            <div className="alertBtnCon">
              <Button onClick={handleDeleteDrone} variant="contained">
                Yes
              </Button>
              <Button onClick={handleCloseModal} variant="contained">
                No
              </Button>
            </div>
          </div>
        )}
        {modalState === 2 && (
          <div className="alertBox">
            <h3>Are you sure you want to delete the battery master?</h3>
            <div className="alertBtnCon">
              <Button onClick={handleDeleteBattery} variant="contained">
                Yes
              </Button>
              <Button onClick={handleCloseModal} variant="contained">
                No
              </Button>
            </div>
          </div>
        )}{" "}
        {modalState === 3 && (
          <div className="alertBox">
            <h3>Are you sure you want to delete the vehicle master?</h3>
            <div className="alertBtnCon">
              <Button onClick={handleDeleteVehicle} variant="contained">
                Yes
              </Button>
              <Button onClick={handleCloseModal} variant="contained">
                No
              </Button>
            </div>
          </div>
        )}{" "}
        {modalState === 4 && (
          <div className="alertBox">
            <h3>Are you sure you want to delete the charger master?</h3>
            <div className="alertBtnCon">
              <Button onClick={handleDeleteCharger} variant="contained">
                Yes
              </Button>
              <Button onClick={handleCloseModal} variant="contained">
                No
              </Button>
            </div>
          </div>
        )}{" "}
        {modalState === 5 && (
          <div className="alertBox">
            <h3>Are you sure you want to delete the charger hub master?</h3>
            <div className="alertBtnCon">
              <Button onClick={handleDeleteChargingHub} variant="contained">
                Yes
              </Button>
              <Button onClick={handleCloseModal} variant="contained">
                No
              </Button>
            </div>
          </div>
        )}{" "}
        {modalState === 6 && (
          <div className="alertBox">
            <h3>Are you sure you want to delete the laptop master?</h3>
            <div className="alertBtnCon">
              <Button onClick={handleDeleteLaptopMaster} variant="contained">
                Yes
              </Button>
              <Button onClick={handleCloseModal} variant="contained">
                No
              </Button>
            </div>
          </div>
        )}
      </CustomModal>
    </Container>
  );
};

export default DroneMaster;

const tableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "drone_name",
    label: "Drone",
  },
  {
    id: "drone_category",
    label: "Category",
  },
  {
    id: "is_sale",
    label: "Available for Sale",
  },
  {
    id: "certificate",
    label: "Certificate",
  },
  {
    id: "banner",
    label: "Banner",
  },
  {
    id: "model_no",
    label: "Model No.",
  },
  {
    id: "drone_price",
    label: "Price",
  },
  {
    id: "drone_status",
    label: "Status",
  },
  {
    id: "actions",
    label: "Action",
  },
];

const batteryTableColumns = [
  {
    id: "id",
    label: "Battery ID",
  },
  {
    id: "name",
    label: "Battery Name",
  },
  {
    id: "power",
    label: "Battery Power",
  },
  {
    id: "image",
    label: "Battery Image",
  },
  {
    id: "drone",
    label: "Drone",
  },

  {
    id: "actions",
    label: "Action",
  },
];

const vehicleMasterColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Vehicle Name",
  },
  {
    id: "company",
    label: "Vehicle Company",
  },
  {
    id: "image",
    label: "Vehicle Image",
  },
  {
    id: "status",
    label: "Vehicle Status",
  },

  {
    id: "action",
    label: "Action",
  },
];

const chargerMasterColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Charger Name",
  },
  {
    id: "model",
    label: "Charger Model",
  },
  {
    id: "image",
    label: "Charger Image",
  },
  {
    id: "status",
    label: "Charger Status",
  },
  {
    id: "is_available",
    label: "Available for Sale",
  },

  {
    id: "action",
    label: "Action",
  },
];

const chargingHubMasterColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "model",
    label: "Model",
  },
  {
    id: "manufacturer",
    label: "Manufacturer",
  },
  {
    id: "image",
    label: "Image",
  },

  {
    id: "action",
    label: "Action",
  },
];
const laptopMasterColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "model",
    label: "Model",
  },
  {
    id: "manufacturer",
    label: "Manufacturer",
  },
  {
    id: "image",
    label: "Image",
  },

  {
    id: "action",
    label: "Action",
  },
];

const vehicleData = [
  {
    id: 1,
    name: "Vehicle 1",
    company: "XYZ company",
    status: "Active",
  },
  {
    id: 2,
    name: "Vehicle 2",
    company: "XYZ company",
    status: "Inactive",
  },
  {
    id: 3,
    name: "Vehicle 3",
    company: "XYZ company",
    status: "Active",
  },
  {
    id: 4,
    name: "Vehicle 4",
    company: "XYZ company",
    status: "active",
  },
  {
    id: 5,
    name: "Vehicle 1",
    company: "XYZ company",
    status: "Inactive",
  },
];

const chargerData = [
  {
    id: 1,
    name: "Charger 1",
    model: "XYZ model",
    is_available: true,
    status: "Active",
  },
  {
    id: 2,
    name: "Charger 2",
    model: "XYZ model",
    is_available: false,
    status: "Active",
  },
  {
    id: 3,
    name: "Charger 3",
    model: "XYZ model",
    is_available: true,
    status: "Inactive",
  },
  {
    id: 4,
    name: "Charger 4",
    model: "XYZ model",
    is_available: false,
    status: "Inactive",
  },
  {
    id: 5,
    name: "Charger 1",
    model: "XYZ model",
    is_available: true,
    status: "Inctive",
  },
];

const statusOptions = [
  { title: "Active", id: true },
  { title: "Inactive", id: false },
];

const availableOptions = [
  { title: "Available", id: true },
  { title: "Not Available", id: false },
];
