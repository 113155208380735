import React, { useEffect, useState, useRef } from "react";
import Container from "../../Component/Container/Container";
import BookingDetailsCard from "../../Component/BookingDetailsCard/BookingDetailsCard";
import {
  Routes,
  Route,
  useParams,
  useNavigate,
  Link,
  useSearchParams,
} from "react-router-dom";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import styles from "./CourseBookDetails.module.css";
import dummyimg from "../../Assets/dummyimg.png";
import Drone from "../../Assets/Drone.png";
import House from "../../Assets/House.png";
import { token_api, base_url } from "../../Utils/network";
import colortick from "../../Assets/colortick.png";
import graytick from "../../Assets/graytick.png";
import {
  helperdayMonthYearFull,
  formatString,
  extractDateTime,
} from "../../Utils/helper";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import documentDownload from "../../Assets/pdfDownload.svg";
import uploaded from "../../Assets/greenUploaded.svg";
import notUploaded from "../../Assets/greyUploaded.svg";
import orangeSelect from "../../Assets/orangeUploaded.svg";
import uploadVector from "../../Assets/uploadVector.svg";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import { Button } from "@mui/material";
import paymentIcon from "../../Assets/paymentIcon.svg";
import noTransaction from "../../Assets/noTransactions.svg";
import dragDrop from "../../Assets/drag_drop.svg";
import axios from "axios";
import { useSelector } from "react-redux";

const CourseBookDetail = () => {
  let { id } = useParams();
  const [searchParams] = useSearchParams();

  const fromCalendar = searchParams.get("from_calendar");

  let navigate = useNavigate();
  const { access_token } = useSelector((state) => state.auth);

  const [data, setData] = useState({});
  const [paymentData, setPaymentData] = useState([]);
  const [transactionData, setTransactiondData] = useState({});
  const [invoiceData, setInvoiceData] = useState(null);
  const [documentsDetails, setDocumentsDetails] = useState({});
  const [bookingStatus, setBookingStatus] = useState({
    status: [],
  });
  const certificateInput = useRef(null);
  const [certificate, setCertificate] = useState("");
  const [certificatePreview, setCertificatePreview] = useState("");

  // console.log(data?.payment[0]?.payment_status_str,"hariData")
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link
      to="/bookings/course-bookings"
      className="orange-1 bread breadElement"
    >
      Course Bookings{" "}
    </Link>,
    <div className="orange-1 bread">Course Booking Details</div>,
  ];

  const getCourseBookingDetails = () => {
    token_api
      .get(`/course/course_book/${id}`)
      .then((response) => {
        setData(response?.data);
        setCertificatePreview(response?.data?.certificate);
        // setPaymentData(response?.data?.payment);

        let isverified = bookingStatusList?.filter(
          (info) => info?.id == response?.data?.purchase_status
        );

        setBookingStatus({
          status: isverified,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log(certificate, certificatePreview, "grararrsrara");
  const getPaymentData = () => {
    token_api
      .get(`payment/pay/?course_purchase=${id}`)
      // .get(`payment/pay/?booking_service=216`)

      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          setPaymentData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCourseBookingDetails();
    getPaymentData();
  }, []);

  const handleChangeBookingStats = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setBookingStatus({
        ...bookingStatus,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setBookingStatus({
        ...bookingStatus,
        [selectName]: [e],
      });
    }
  };

  const handleDeleteBookingStatus = (id, selectName) => {
    setBookingStatus({
      ...bookingStatus,
      [selectName]: bookingStatus?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
  };
  const handleChangeBookingStatus = () => {
    let data = {
      purchase_status: bookingStatus?.status[0]?.id,
    };

    token_api
      .patch(`course/admin_course_book/${id}/`, data)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Course Booking Status Updated Successfully!");
          getCourseBookingDetails();
          getDocumentsData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useEffect(() => {
  //   const fetchInvoiceData = () => {
  //     token_api
  //       .get(`/course/invoice/?course_purchase=${id}`)
  //       .then((response) => {
  //         const data = response?.data?.data[0];
  //         if (data && data.hasOwnProperty("invoice_file")) {
  //           const pdfUrl = data.invoice_file;
  //           setInvoiceData(pdfUrl);
  //           // window.open(pdfUrl, "_blank");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching invoice data:", error);
  //       });
  //   };

  //   fetchInvoiceData();
  // }, []);

  // const handleDownloadBtn = () => {
  //   if (invoiceData) {
  //     window.open(invoiceData, "_blank");
  //   } else {
  //     alert("Please Generate Invoice");
  //   }
  // };

  function getDocumentsData() {
    token_api
      .get(`/users/registration/profile_documents?user=${data?.user?.id}`)
      .then((response) => {
        setDocumentsDetails(response?.data?.data);
      })
      .catch((err) => [console.log(err)]);
  }

  useEffect(() => {
    if (data?.user?.id) {
      getDocumentsData();
    }
  }, [data?.user?.id]);
  const handleNavigateBack = () => {
    navigate(fromCalendar ? `/calendar/events` : `/bookings/course-bookings`);
  };

  //Upload course cerification...

  const handleClick = () => {
    certificateInput.current.click();
  };
  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleDrop(e) {
    e.preventDefault();
    if (e?.target?.files) {
      setCertificate(e?.target?.files[0]);
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      setCertificatePreview(imageUrl);
    } else if (e?.dataTransfer?.files) {
      setCertificate(e?.dataTransfer?.files[0]);
      const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
      setCertificatePreview(imageUrl);
    }
  }

  const handleUploadCertificate = async () => {
    try {
      if (certificate) {
        let certificateForm = new FormData();
        certificateForm.append("certificate", certificate);
        const addCertificate = await axios({
          method: "patch",
          url: `${base_url}course/admin_course_certificate/${data?.id}/`,
          data: certificateForm,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${access_token}`,
          },
        });

        if (
          addCertificate?.status === 200 ||
          addCertificate?.status === 201 ||
          addCertificate?.status === 204
        ) {
          alert("Course Completion Certificate Uploaded Successfully!");
          setCertificate("");
          getCourseBookingDetails();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className={styles.paddingTwelvepx}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>
      </div>

      <div className={`${styles.bookingContainer}  ${styles.paddingTwelvepx} `}>
        <div>
          <small>Booking ID</small>
          <p
            className={`${styles.marginZero} ${styles.fontBold} ${styles.idprop}  `}
          >
            {id}
          </p>
        </div>

        <div>
          <p
            className={`${styles.successbgColor}  ${styles.whiteColor}  ${styles.confirmedbtn} `}
          >
            {data?.purchase_status_str}

            {/* {paymentData && paymentData[0] && paymentData[0].payment_status_str
              ? paymentData[0].payment_status_str
              : "-"} */}
          </p>
        </div>

        <div className={styles.topContainerRight}>
          {/* Service Booking Status :{" "} */}
          <CustomSelectChip
            multiple={false}
            label="Course Booking Status"
            name="status"
            listArray={bookingStatusList}
            onChange={handleChangeBookingStats}
            onDelete={handleDeleteBookingStatus}
            value={bookingStatus?.status}
          />
          <Button
            variant="contained"
            onClick={handleChangeBookingStatus}
            disabled={!bookingStatus?.status[0]?.id}
            style={{
              backgroundColor: bookingStatus?.status[0]?.id
                ? "linear-gradient( 90deg, rgb(255, 153, 8) 0%, rgb(237, 103, 31) 100.02% )"
                : "light-grey",
              color: "white",
            }}
          >
            Submit
          </Button>
          {/* <span style={{ cursor: "pointer" }}>
            {bookingDetails?.status_str}{" "}
          </span> */}
        </div>

        {/* <div>
          <button
            className={styles.downloadInvoice}
            onClick={() => {
              handleDownloadBtn();
            }}
          >
            Download Invoice
          </button>
        </div> */}
      </div>

      <Container>
        <BookingDetailsCard isEditable={false} cardTitle="">
          <div className={styles.flexWrapper}>
            <div className={`${styles.flexContainerleft}`}>
              <p
                className={` ${styles.fontBold}  ${styles.primaryOrangeColor} `}
              >
                {data?.course_name}
              </p>

              <p className={`  ${styles.marginZero} ${styles.fontBold}`}>
                {data?.batch_name}
              </p>

              <p>
                Starting from{" "}
                {data?.batch?.start_date
                  ? helperdayMonthYearFull(data?.batch?.start_date)
                  : "-"}
              </p>
            </div>

            {/* <div className={`${styles.flexContainerright}`}>
              <img
                className={styles.widthhundrede}
                src={data?.course?.banner}
                alt="dummyimg"
              />
            </div> */}
          </div>
        </BookingDetailsCard>

        <div className={styles.droneAndAccCardCOn}>
          <div>
            <div className={styles.droneAndAccCard}>
              <div>
                <img src={Drone} />
              </div>
              <div>
                <p className={`${styles.marginZero} ${styles.colorGray}  `}>
                  Flying Slot
                </p>
                <p className={`${styles.marginZero}`}>
                  {data?.flying_slot &&
                  data?.flying_slot.start_date &&
                  data?.flying_slot.end_date
                    ? `${helperdayMonthYearFull(
                        data?.flying_slot?.start_date
                      )} to ${helperdayMonthYearFull(
                        data?.flying_slot?.end_date
                      )}`
                    : "-"}
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className={styles.droneAndAccCard}>
              <div>
                <img src={House} />
              </div>
              <div>
                <p className={`${styles.marginZero} ${styles.colorGray}  `}>
                  Accomodation{" "}
                  <span className={styles.primaryOrangeColor}>
                    (₹{data?.accomodation_amt}/- per day)
                  </span>
                </p>
                <p className={`${styles.marginZero}`}>
                  {data?.user_accommodation?.check_in_date &&
                  data?.user_accommodation?.check_out_date
                    ? `${helperdayMonthYearFull(
                        data.user_accommodation.check_in_date
                      )} to ${helperdayMonthYearFull(
                        data.user_accommodation.check_out_date
                      )}`
                    : "-"}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.marginTwelve} ${styles.widthContainer} `}>
          <div className={styles.leftWrapperbooking}>
            <div className={`${styles.marginToptwelve}`}>
              <BookingDetailsCard
                isEditable={false}
                cardTitle="Student Details"
              >
                <div className={`${styles.infoContainer}  `}>
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Name
                  </p>
                  <p>{data?.user_name}</p>
                </div>

                <div
                  className={`${styles.infoContainer} ${styles.marginfive} `}
                >
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Mobile
                  </p>
                  <p>{data?.mobile}</p>
                </div>

                <div
                  className={`${styles.infoContainer} ${styles.marginfive} `}
                >
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Email
                  </p>
                  <p>{data?.user?.email ? data?.user?.email : "-"}</p>
                </div>

                <div
                  className={`${styles.infoContainer} ${styles.marginfive} `}
                >
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Billing Address
                  </p>
                  <p>
                    {data?.address_line_1 || data?.address_line_2
                      ? `${data?.address_line_1 || ""} ${
                          data?.address_line_2 || ""
                        }`
                      : "-"}
                    <br />
                    {data?.city ? data?.city : "-"}{" "}
                    {data?.pincode ? data?.pincode : "-"} <br />
                    {data?.state ? data?.state : "-"}
                  </p>
                </div>

                <div
                  className={`${styles.infoContainer} ${styles.marginfive} `}
                >
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    City
                  </p>
                  <p>{data?.city ? data?.city : "-"}</p>
                </div>
              </BookingDetailsCard>
            </div>

            <div className={`${styles.marginToptwelve}`}>
              <BookingDetailsCard
                isEditable={false}
                cardTitle="Payment Details"
              >
                {/* <div className={`${styles.infoContainer}  `}>
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Booking Number
                  </p>
                  <p>{data?.id}</p>
                </div> */}

                <div
                  className={`${styles.infoContainer} ${styles.marginfive} `}
                >
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Booking Date
                  </p>
                  <p> {helperdayMonthYearFull(data?.created)}</p>
                </div>

                <div
                  className={`${styles.infoContainer} ${styles.marginfive} `}
                >
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Total Amount
                  </p>
                  <p>
                    {data?.total_payable ? `Rs. ${data?.total_payable}` : "N.A"}
                  </p>
                </div>
                {/* <div
                  className={`${styles.infoContainer} ${styles.marginfive} `}
                >
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Payment Status
                  </p>
                  {data?.payment_status_str ? (
                    <p
                      className={`${styles.successbgColor}  ${styles.whiteColor}  ${styles.confirmedbtnand} `}
                    >
                      {data?.payment_status_str}
                    </p>
                  ) : (
                    <p>N.A</p>
                  )}
                </div> */}

                {data &&
                  data?.payment &&
                  data?.payment.length > 0 &&
                  data?.payment?.map((paymentItem, idx) => {
                    return (
                      <div key={idx}>
                        {/* <div
                          className={`${styles.infoContainer} ${styles.marginfive} `}
                        >
                          <p
                            className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                          >
                            Booking Date
                          </p>
                          <p> {helperdayMonthYearFull(paymentItem?.created)}</p>
                        </div>

                        <div
                          className={`${styles.infoContainer} ${styles.marginfive} `}
                        >
                          <p
                            className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                          >
                            Total Amount
                          </p>
                          <p>
                            {paymentItem?.amount
                              ? `Rs. ${paymentItem?.amount}`
                              : "N.A"}
                          </p>
                        </div> */}

                        {paymentItem?.emi_payment.length > 0 &&
                          paymentItem?.emi_payment.map((item, index) => {
                            return (
                              <div
                                style={{
                                  margin: "30px 0px",
                                  columnGap: "10px",
                                }}
                                key={index}
                              >
                                <div
                                  className={`${styles.infoContainer} ${styles.marginfive} `}
                                >
                                  <p
                                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                                  >
                                    Paid Date
                                  </p>
                                  <p>
                                    {item?.modified
                                      ? helperdayMonthYearFull(item?.modified)
                                      : "N.A"}
                                  </p>
                                </div>{" "}
                                <div
                                  className={`${styles.infoContainer} ${styles.marginfive} `}
                                >
                                  <p
                                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                                  >
                                    Due Date
                                  </p>
                                  <p>
                                    {item?.due_by
                                      ? helperdayMonthYearFull(item?.due_by)
                                      : "N.A"}
                                  </p>
                                </div>
                                <div
                                  className={`${styles.infoContainer} ${styles.marginfive} `}
                                >
                                  <p
                                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                                  >
                                    Paid Amount
                                  </p>
                                  <p>
                                    {item?.paid_amount
                                      ? `Rs. ${item?.paid_amount}`
                                      : "N.A"}
                                  </p>
                                </div>
                                <div
                                  className={`${styles.infoContainer} ${styles.marginfive} `}
                                >
                                  <p
                                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                                  >
                                    Payment Mode
                                  </p>
                                  <p>
                                    {item?.payment_method_str
                                      ? item?.payment_method_str
                                      : "N.A"}
                                  </p>
                                </div>
                                <div
                                  className={`${styles.infoContainer} ${styles.marginfive} `}
                                >
                                  <p
                                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                                  >
                                    Transaction ID
                                  </p>
                                  <p>
                                    {item?.emi_transaction_id
                                      ? item?.emi_transaction_id
                                      : "N.A"}
                                  </p>
                                </div>
                                <div
                                  className={`${styles.infoContainer} ${styles.marginfive} `}
                                >
                                  <p
                                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                                  >
                                    Payment Status
                                  </p>
                                  {item?.payment_status_str ? (
                                    <p
                                      className={`${styles.successbgColor}  ${styles.whiteColor}  ${styles.confirmedbtnand} `}
                                    >
                                      {item?.payment_status_str}
                                    </p>
                                  ) : (
                                    <p>N.A</p>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </BookingDetailsCard>
            </div>

            <div className={`${styles.marginToptwelve}`}>
              <BookingDetailsCard isEditable={false} cardTitle="Slot Details">
                <div
                  className={`${styles.infoContainer} ${styles.marginfive} `}
                >
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Flying Slot
                  </p>
                  <p>
                    {data?.flying_slot?.start_date &&
                    data?.flying_slot?.end_date
                      ? ` ${helperdayMonthYearFull(
                          data?.flying_slot?.start_date
                        )} To ${helperdayMonthYearFull(
                          data?.flying_slot?.end_date
                        )}`
                      : "-"}
                  </p>
                </div>

                <div
                  className={`${styles.infoContainer} ${styles.marginfive} `}
                >
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Flying Site
                  </p>
                  <p>{data?.flying_slot?.location?.city}</p>
                </div>
              </BookingDetailsCard>
            </div>

            <div className={`${styles.marginToptwelve}`}>
              <BookingDetailsCard
                isEditable={false}
                cardTitle="Accommodation Details"
              >
                <div
                  className={`${styles.infoContainer} ${styles.marginfive} `}
                >
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Guest House
                  </p>
                  <p>
                    {data?.user_accommodation?.accommodation?.hotel?.hotel_name
                      ? data?.user_accommodation?.accommodation?.hotel
                          ?.hotel_name
                      : "-"}
                  </p>
                </div>

                <div
                  className={`${styles.infoContainer} ${styles.marginfive} `}
                >
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Booking Dates
                  </p>
                  <p>
                    {data?.user_accommodation?.check_in_date &&
                    data?.user_accommodation?.check_out_date
                      ? `${helperdayMonthYearFull(
                          data?.user_accommodation?.check_in_date
                        )} to ${helperdayMonthYearFull(
                          data?.user_accommodation?.check_out_date
                        )}`
                      : "-"}
                  </p>
                </div>

                <div
                  className={`${styles.infoContainer} ${styles.marginfive} `}
                >
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Booked on
                  </p>
                  <p>
                    {data?.accomodation_date
                      ? helperdayMonthYearFull(data?.accomodation_date)
                      : "-"}
                  </p>
                </div>

                <div
                  className={`${styles.infoContainer} ${styles.marginfive} `}
                >
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Occupancy Type
                  </p>
                  <p>
                    {data?.user_accommodation?.accommodation?.occupancy_type_str
                      ? formatString(
                          data?.user_accommodation?.accommodation
                            ?.occupancy_type_str
                        )
                      : "N.A"}
                  </p>
                </div>
                <div
                  className={`${styles.infoContainer} ${styles.marginfive} `}
                >
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Room Number
                  </p>
                  <p>
                    {data?.user_accommodation?.accommodation?.room_no
                      ? formatString(
                          data?.user_accommodation?.accommodation?.room_no
                        )
                      : "N.A"}
                  </p>
                </div>

                <div
                  className={`${styles.infoContainer} ${styles.marginfive} `}
                >
                  <p
                    className={`${styles.minwidthtw} ${styles.colorlightgray}  `}
                  >
                    Payment Status
                  </p>
                  <p>
                    {data?.user_accommodation?.payment_status_str
                      ? data?.user_accommodation?.payment_status_str
                      : "N.A"}
                  </p>
                </div>
              </BookingDetailsCard>
            </div>

            <div>
              <h1
                style={{ color: "black", fontSize: "20px", marginTop: "20px" }}
              >
                Add Course Completion Certificate
              </h1>

              <div style={{ marginTop: "20px" }}>
                <div
                  onDragOver={handleDragOver}
                  onDrop={(e) => handleDrop(e)}
                  className={styles.dropBoxStyles}
                >
                  <div
                    onClick={() => handleClick()}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p>
                      <img src={dragDrop} alt="dradanddrop" />
                    </p>
                    <p>Drag & Drop the File or click here</p>
                    <input
                      ref={certificateInput}
                      type="file"
                      // id="myfile"
                      // name="myfile"
                      // multiple
                      onChange={(e) => handleDrop(e)}
                      style={{ display: "none" }}
                    ></input>
                  </div>
                </div>
              </div>

              {certificatePreview && (
                <div className={`${styles.formContainer} mt-20`}>
                  <div className={styles.commonFieldsBox}>
                    <div>
                      <p
                        style={{
                          color: "black",
                          fontSize: "20px",
                          marginBottom: "20px",
                        }}
                        className={`${styles.titleStyles}`}
                      >
                        Course Completion Certificate Preview
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={`${styles.flexBetween} ${styles.imgCardBox} `}
                        >
                          {" "}
                          <img
                            src={certificatePreview}
                            alt="banner"
                            width={200}
                            height={140}
                          />
                          {/* <div onClick={() => handleClick()}>
                            <Button
                              variant="contained"
                              // onClick={() => {
                              //   handleMediaDelete("image");
                              // }}
                            >
                              Update
                            </Button> */}
                          {/* </div> */}
                          <Button
                            variant="contained"
                            onClick={handleUploadCertificate}
                          >
                            Upload
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.rightWrapperbooking}>
            <div className={styles.progress}>
              <p className={`${styles.fontBold} ${styles.studentDocument} `}>
                Student Documents
              </p>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src={
                      documentsDetails?.aadhar &&
                      !documentsDetails?.is_aadhar_verified
                        ? orangeSelect
                        : documentsDetails?.aadhar &&
                          documentsDetails?.is_aadhar_verified
                        ? uploaded
                        : notUploaded
                    }
                    // style={{ width: "30px", height: "30px" }}
                  />{" "}
                  <p>Aadhar Card</p>
                </div>
                <a href={documentsDetails?.aadhar} target="_blank">
                  <img src={documentDownload} />
                </a>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src={
                      documentsDetails?.passport_photo &&
                      !documentsDetails?.is_passport_photo_verified
                        ? orangeSelect
                        : documentsDetails?.passport_photo &&
                          documentsDetails?.is_passport_photo_verified
                        ? uploaded
                        : notUploaded
                    }
                    // style={{ width: "30px", height: "30px" }}
                  />
                  <p>Passport / Voter Id / Driver License / Ration Card</p>
                </div>
                <a href={documentsDetails?.passport_photo} target="_blank">
                  <img src={documentDownload} />
                </a>{" "}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src={
                      documentsDetails?.marksheet_10th &&
                      !documentsDetails?.is_marksheet_10th_verified
                        ? orangeSelect
                        : documentsDetails?.marksheet_10th &&
                          documentsDetails?.is_marksheet_10th_verified
                        ? uploaded
                        : notUploaded
                    }
                    // style={{ width: "30px", height: "30px" }}
                  />
                  <p>Copy Of 10th Certificate/Marksheet</p>
                </div>
                <a href={documentsDetails?.marksheet_10th} target="_blank">
                  <img src={documentDownload} />
                </a>{" "}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src={
                      documentsDetails?.medical_fitness &&
                      !documentsDetails?.is_medical_fitness_verified
                        ? orangeSelect
                        : documentsDetails?.medical_fitness &&
                          documentsDetails?.is_medical_fitness_verified
                        ? uploaded
                        : notUploaded
                    }
                    // style={{ width: "30px", height: "30px" }}
                  />
                  <p>Medical Certificate Of Fitness</p>
                </div>
                <a href={documentsDetails?.medical_fitness} target="_blank">
                  <img src={documentDownload} />
                </a>{" "}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src={
                      documentsDetails?.other_photo_proof &&
                      !documentsDetails?.is_other_photo_proof_verified
                        ? orangeSelect
                        : documentsDetails?.other_photo_proof &&
                          documentsDetails?.is_other_photo_proof_verified
                        ? uploaded
                        : notUploaded
                    }
                    // style={{ width: "30px", height: "30px" }}
                  />
                  <p>Photographs (Passport Sized)</p>
                </div>
                <a href={documentsDetails?.other_photo_proof} target="_blank">
                  <img src={documentDownload} />
                </a>{" "}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src={
                      documentsDetails?.registration_form &&
                      !documentsDetails?.is_registration_form_verified
                        ? orangeSelect
                        : documentsDetails?.registration_form &&
                          documentsDetails?.is_registration_form_verified
                        ? uploaded
                        : notUploaded
                    }
                    // style={{ width: "30px", height: "30px" }}
                  />
                  <p>Registration Form</p>
                </div>
                <a href={documentsDetails?.registration_form} target="_blank">
                  <img src={documentDownload} />
                </a>{" "}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src={
                      (documentsDetails?.attested_proof_1 ||
                        documentsDetails?.attested_proof_2) &&
                      !(
                        documentsDetails?.is_attested_proof_1_verified ||
                        documentsDetails?.is_attested_proof_2_verified
                      )
                        ? orangeSelect
                        : (documentsDetails?.attested_proof_1 ||
                            documentsDetails?.attested_proof_2) &&
                          (documentsDetails?.is_attested_proof_1_verified ||
                            documentsDetails?.is_attested_proof_2_verified)
                        ? uploaded
                        : notUploaded
                    }
                    // style={{ width: "30px", height: "30px" }}
                  />{" "}
                  <p>Self-Attested Copies Of 2 ID Proofs</p>
                </div>
                <a href={documentsDetails?.attested_proof_1} target="_blank">
                  <img src={documentDownload} />
                </a>{" "}
              </div>
            </div>

            <div className={styles.paymentDetailsMainCon}>
              {/* <h3 className={styles.paymentTitle}>Payment Details</h3> */}
              <div className={styles.paymentDetailsCon}>
                {/* <div className={styles.transactionListCon}>
                  <div
                    style={{
                      background: "rgba(255, 183, 77, 1)",
                    }}
                    className={styles.transactionCon}
                  >
                    <h4>Total Service Amount</h4>
                    <p>Incl. GST</p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <img src={paymentIcon} alt="payment_icon" />
                      <h3>{`₹  ${
                        transactionData?.total_service_amt || "N.A"
                      }`}</h3>
                    </div>
                  </div>
                  <div
                    style={{
                      background: "rgba(139, 195, 74, 1)",
                    }}
                    className={styles.transactionCon}
                  >
                    <h4>Total Paid Amount</h4>
                    <p>Incl. GST</p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <img src={paymentIcon} alt="payment_icon" />
                      <h3>{`₹  ${
                        transactionData?.total_paid_amt || "N.A"
                      }`}</h3>
                    </div>{" "}
                  </div>{" "}
                  <div
                    style={{
                      background: "rgba(255, 115, 102, 1)",
                    }}
                    className={styles.transactionCon}
                  >
                    <h4>Total Pending Amount</h4>
                    <p>Incl. GST</p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <img src={paymentIcon} alt="payment_icon" />
                      <h3>{`₹  ${
                        transactionData?.total_pending_amt || "N.A"
                      }`}</h3>
                    </div>{" "}
                  </div>
                </div> */}

                {paymentData && paymentData.length > 0 ? (
                  <div>
                    <h3>Transaction History</h3>

                    <div className={styles.withdrawListCon}>
                      {paymentData.map((withdraw, indx) => {
                        return (
                          <div key={indx} className={styles.withdrawCon}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginBottom: "15px",
                                width: "100%",
                              }}
                            >
                              <div>
                                <h3 className={styles.withdrawBlack}>
                                  {withdraw?.name}
                                </h3>
                                <h3 className={styles.withdrawGrey}>
                                  {" "}
                                  {helperdayMonthYearFull(
                                    withdraw?.created
                                  )}, {extractDateTime(withdraw?.created)}
                                </h3>
                              </div>
                              <div>
                                <h3 className={styles.withdrawBlack}>
                                  ₹ {withdraw?.amount}
                                </h3>
                                <button
                                  className={styles.downloadBtn}
                                  style={{
                                    background: `${
                                      withdraw?.payment_status === 4
                                        ? "linear-gradient(to right, rgba(9, 154, 6, 1), rgba(20, 219, 16, 1))"
                                        : "linear-gradient(to right, rgba(200, 8, 8, 1), rgba(255, 124, 116, 1))"
                                    }`,
                                  }}
                                >
                                  {withdraw?.payment_status_str}
                                </button>
                              </div>
                            </div>

                            {withdraw?.transaction_id && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "10px",
                                  width: "100%",
                                }}
                              >
                                <h3 className={styles.withdrawRed}>
                                  {withdraw?.payment_method_str}
                                </h3>
                                <h3 className={styles.withdrawRed}>
                                  {withdraw?.transaction_id || "N.A"}
                                </h3>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div style={{ marginTop: "20px" }}>
                    <h3>Transaction History</h3>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px",
                        boxSizing: "border-box",
                      }}
                    >
                      <img src={noTransaction} />
                      <h1
                        style={{
                          color: "grey",
                          fontSize: "24px",
                          fontWeight: "800",
                          marginBottom: "10px",
                        }}
                      >
                        NO TRANSACTIONS{" "}
                      </h1>
                      <p>You haven't made any transactions yet.</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default CourseBookDetail;

const bookingStatusList = [
  {
    id: 1,
    title: "Pending",
  },
  {
    id: 2,
    title: "Confirmed",
  },
  {
    id: 3,
    title: "OnHold",
  },
  {
    id: 4,
    title: "Cancelled",
  },
];
