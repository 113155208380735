import React, { useState, useEffect } from "react";
import { formatDroneMasterList } from "../../Pages/DroneMaster/droneUtility";
import { token_api } from "../../Utils/network";
import CustomTable from "../CustomTable/CustomTable";
import {
  extractDateTime,
  getTooltipComponent,
  helperdayMonthYearFull,
  formatDate,
} from "../../Utils/helper";
import styles from "../../Pages/DroneBookings/index.module.css";

const DroneTabforDashboard = () => {
  const [droneMasterList, setDroneMasterList] = useState([]);

  const getPurchaseStatusStyles = (status) => {
    switch (status) {
      case 1:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "#E95E33",

              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Pending
          </div>
        );
      case 2:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(87, 182, 122)",

              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Confirmed{" "}
          </div>
        );
      case 3:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(235, 137, 235)",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            On Hold{" "}
          </div>
        );
      case 4:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "#FF7366",
              // border: "1px solid rgb(81, 79, 255)",
              color: "white",
              fontSize: "12px",
            }}
          >
            Cancelled{" "}
          </div>
        );
      case 5:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "green",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Completed{" "}
          </div>
        );
      case 6:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(245, 199, 99)",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Out For Delivery{" "}
          </div>
        );

      case 7:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(87, 194, 255)",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Delivered{" "}
          </div>
        );

      default:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "black",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            None
          </div>
        );
    }
  };
  const getStatusStyles = (status) => {
    switch (status) {
      case 1:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "#E95E33",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Pending{" "}
          </div>
        );
      case 2:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(245, 199, 99)",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Partially Paid{" "}
          </div>
        );

      case 3:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(87, 182, 122)",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Paid{" "}
          </div>
        );
      case 4:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "#FF7366",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Failed{" "}
          </div>
        );
      default:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "black",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            None
          </div>
        );
    }
  };

  const getDroneHubBookings = (finalFilters) => {
    token_api
      .get(`drone/shop_order?ordering=-total_amount&total_amt_not_null=true`)
      .then((response) => {
        let modifiedArray = response?.data?.data?.map((item) => {
          return {
            id: (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "12px",
                  // textDecoration: "underline",
                }}
                // onClick={() => {
                //   handleRedirect(item?.id);
                // }}
              >
                {item?.id}
              </div>
            ),
            name: (
              <div style={{ cursor: "pointer" }}>
                <div
                  style={{ fontSize: "12px" }}
                >{`${item?.user?.first_name} ${item?.user?.last_name}`}</div>
                <div
                  style={{ fontSize: "12px" }}
                >{`${item?.user?.mobile}`}</div>
              </div>
            ),
            product_name: (
              <div style={{ fontSize: "12px" }}>
                {item?.order_product && item?.order_product.length > 0
                  ? item?.order_product.length === 1
                    ? item?.order_product[0]?.product?.name
                    : `${item?.order_product[0]?.product?.name} +${
                        item?.order_product.length - 1
                      } products`
                  : "N.A"}
              </div>
            ),
            total_amount:
              <p style={{ fontSize: "12px" }}>&#8377;{item?.total_amount}</p> ||
              "N.A",
            billing_address: (
              <div
                style={{ fontSize: "12px" }}
              >{`${item?.billing_address_line_1} ${item?.billing_address_line_2} ${item?.billing_city} ${item?.billing_district} ${item?.billing_state} ${item?.billing_country} ${item?.billing_pincode}`}</div>
            ),
            shipping_address: (
              <div
                style={{ fontSize: "12px" }}
              >{`${item?.shipping_address_line_1} ${item?.shipping_address_line_2} ${item?.shipping_city} ${item?.shipping_district} ${item?.shipping_state} ${item?.shipping_country} ${item?.shipping_pincode}`}</div>
            ),

            zoho_order_id: (
              <div style={{ fontSize: "12px" }}>
                {item?.zoho_sales_order_id || "N.A"}
              </div>
            ),

            created: (
              <div>
                <div style={{ fontSize: "12px" }}>
                  {helperdayMonthYearFull(item?.created)}
                </div>
                <div style={{ fontSize: "12px" }}>
                  {extractDateTime(item?.created)}
                </div>
              </div>
            ),
            delivery: (
              <div>
                <div style={{ fontSize: "12px" }}>
                  {helperdayMonthYearFull(item?.delivery_date)}
                </div>
              </div>
            ),

            status: getStatusStyles(item?.payment_status),
            purchase_status: getPurchaseStatusStyles(item?.purchase_status),
            // actions: (
            //   <div
            //     style={{ display: "flex", flexDirection: "row", gap: "10px" }}
            //   >
            //     <div
            //       style={{ cursor: "pointer" }}
            //       onClick={() => {
            //         handleEditBookingDetails(item?.id);
            //       }}
            //     >
            //       <img src={edit_icon} />
            //     </div>

            //   </div>
            // ),
          };
        });
        setDroneMasterList([...modifiedArray]);
      })
      .catch((err) => {
        console.log("BookingErr", err);
      });
  };

  useEffect(() => {
    getDroneHubBookings();
  }, []);

  return (
    <div style={{ height: "300px", overflow: "auto" }}>
      <CustomTable
        columns={tableColumns}
        data={droneMasterList}
        // handleClick={handleRedirect}
        // tableFor=""
      />
    </div>
  );
};

export default DroneTabforDashboard;

// const tableColumns = [
//   {
//     id: "id",
//     label: "Booking ID",
//   },
//   {
//     id: "name",
//     label: "Name",
//   },
//   {
//     id: "drone_category",
//     label: "Category",
//   },
//   {
//     id: "is_sale",
//     label: "Available for Sale",
//   },
//   {
//     id: "certificate",
//     label: "Certificate",
//   },
//   {
//     id: "drone_qty",
//     label: "Quantity",
//   },
//   {
//     id: "model_no",
//     label: "Model No.",
//   },
//   {
//     id: "drone_price",
//     label: "Price",
//   },
//   {
//     id: "drone_status",
//     label: "Status",
//   },
// //   {
// //     id: "actions",
// //     label: "",
// //   },
// ];

const tableColumns = [
  {
    id: "id",
    label: "Booking ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "product_name",
    label: "Product Name",
  },
  {
    id: "total_amount",
    label: "Total Amount",
  },
  // {
  //   id: "billing_address",
  //   label: "Billing Address",
  // },
  // {
  //   id: "shipping_address",
  //   label: "Shipping Address",
  // },
  {
    id: "zoho_order_id",
    label: "Zoho Order ID",
  },
  {
    id: "created",
    label: "Created Date",
  },
  {
    id: "delivery",
    label: "Expected Date",
  },

  // {
  //   id: "status",
  //   label: "Payment Status",
  // },
  {
    id: "purchase_status",
    label: "Booking Status",
  },
  // {
  //   id: "actions",
  //   label: "Actions",
  // },
];
