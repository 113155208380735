import React, { useEffect, useState } from "react";
import InputFields from "../InputFields/InputFields";
import CustomSelectChip from "../CustomSelectChip/CustomSelectChip";
import { Button } from "@mui/material";
import { token_api } from "../../Utils/network";

const AddHotelModal = ({ amenitiesList, handleClose, id, roomObj }) => {
  const [hotelData, setHotelData] = useState({
    id: null,
    room_no: "",
    floor_no: "",
    price: "",
    occupancy_type: null,
    status: null,
    amenities: [],
  });
  const [formErrors, setFormErrors] = useState({});

  //   const handleChange = (field, value) => {
  //     setHotelData({ ...hotelData, [field]: value });
  //   };

  const handleDeleteChip = (id, selectName) => {
    setHotelData({
      ...hotelData,
      [selectName]: hotelData?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setHotelData({
        ...hotelData,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setHotelData({
        ...hotelData,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setHotelData({
        ...hotelData,
        [name]: value,
      });
    }
  };

  const handleSubmitClick = (e) => {
    e.preventDefault();

    const data = {
      hotel_id: id,
      room_no: hotelData?.room_no,
      floor_no: hotelData?.floor_no,
      price: hotelData?.price,
      occupancy_type: hotelData?.occupancy_type[0]?.id,
      status: hotelData?.status[0]?.id,
      amenities_id: hotelData?.amenities?.map((info) => info?.id),
    };
    if (!hotelData?.id) {
      token_api
        .post(`/course/accommodation/`, data)
        .then((response) => {
          if (response?.status == 201 || response?.status === 200) {
            alert("Room Details Added Successfully!");
            handleClose();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      token_api
        .patch(`/course/accommodations/${hotelData?.id}/`, data)
        .then((response) => {
          if (response?.status == 201 || response?.status === 200) {
            alert("Room Details Updated Successfully!");
            handleClose();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (roomObj && roomObj?.id) {
      const {
        room_no,
        floor_no,
        status,
        amenities,
        price,
        occupancy_type,
        id,
      } = roomObj;

      let statusItem = activeinactiveList.filter((item) => item?.id === status);
      let occupancyItem = Occupancylisting.filter(
        (item) => item?.id === occupancy_type
      );
      let amenitiesItem = amenities.map((item) => {
        return { id: item?.id, title: item?.title };
      });

      setHotelData({
        id: id,
        room_no: room_no,
        floor_no: floor_no,
        price: price,
        occupancy_type: occupancyItem,
        status: statusItem,
        amenities: amenitiesItem,
      });
    }
  }, [roomObj]);


  return (
    <div>
      <p style={{ margin: "20px 0px" }}>
        Please {hotelData?.id ? "Update" : "Add"} Room Details
      </p>
      <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <div style={{ flex: 1 }}>
          <InputFields
            label="Room Number"
            name="room_no"
            value={hotelData?.room_no}
            onChange={(e) => handleChange(e, "text", "room_no", false)}
            error={formErrors?.room_no}
          />
        </div>
        <div style={{ flex: 1 }}>
          <InputFields
            label="Floor Number"
            name="floor_no"
            value={hotelData?.floor_no}
            onChange={(e) => handleChange(e, "text", "floor_no", false)}
            error={formErrors?.floor_no}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
        <div style={{ flex: 1 }}>
          <InputFields
            type="number"
            label="Price"
            name="price"
            value={hotelData?.price}
            onChange={(e) => handleChange(e, "text", "price", false)}
          />
        </div>
        <div style={{ flex: 1 }}>
          <CustomSelectChip
            multiple={false}
            label="Occupancy type"
            name="occupancy_type"
            onChange={handleChange}
            onDelete={handleDeleteChip}
            value={hotelData?.occupancy_type}
            listArray={Occupancylisting}
            error={formErrors?.occupancy_type}
          />
        </div>
      </div>
      <div style={{ display: "flex", gap: "20px" }}>
        <div style={{ flex: 1 }}>
          <CustomSelectChip
            multiple={true}
            label="Amenities"
            name="amenities"
            onChange={handleChange}
            onDelete={handleDeleteChip}
            value={hotelData?.amenities}
            listArray={amenitiesList}
            error={formErrors?.amenities}
          />
        </div>

        <div style={{ flex: 1 }}>
          <CustomSelectChip
            multiple={false}
            label="Status"
            name="status"
            onChange={handleChange}
            onDelete={handleDeleteChip}
            value={hotelData?.status}
            listArray={activeinactiveList}
            error={formErrors?.status}
          />
        </div>
      </div>
      <div
        style={{
          margin: "20px 0px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div style={{ display: "flex", gap: "10px" }}>
          <Button onClick={handleSubmitClick} variant="contained">
            {hotelData?.id ? "UPDATE" : "ADD"}
          </Button>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddHotelModal;

const Occupancylisting = [
  {
    id: 1,
    title: "Single occupancy",
  },
  {
    id: 2,
    title: "Double occupancy",
  },
];

const activeinactiveList = [
  {
    id: true,
    title: "Active",
  },
  {
    id: false,
    title: "Inactive",
  },
];
