import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  drone_list: [],
  drone_list_select: [],
};

const droneSlice = createSlice({
  name: "drone",
  initialState,
  reducers: {
    setDroneList: (state, action) => {
      let modifiedArray = action?.payload?.data?.map((item) => {
        return {
          id: item?.id || null,
          title: item?.title || "N.A",
          sub_topic: `${item?.license_no || "N.A"} | ${
            item?.category || "N.A"
          }`,
        };
      });
      state.drone_list = modifiedArray || [];
    },
  },
});

export const { setDroneList } = droneSlice.actions;

export default droneSlice.reducer;
