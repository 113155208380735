import React, { useEffect, useState } from "react";
import CustomTableForDrones from "../CustomTableForDrones/CustomTableForDrones";
import {
  formatDroneInventoryList,
  Validate,
} from "../../Pages/DroneInventory/utility";
import { token_api } from "../../Utils/network";
import defaultImg from "../../Assets/defaultImg.svg";

const DroneInventoryTab = () => {
  const [paginationData, setPaginationData] = useState({});
  const [droneInventoryList, setDroneInventoryList] = useState([]);

  const handleEditDrone = () => {};

  const handleDeleteDrone = () => {};

  const getDroneInventoryListing = async () => {
    try {
      const fetchDroneInventory = await token_api.get(`drone/inventory`);
      if (fetchDroneInventory.status !== 200) {
        throw new Error("Error in fetching");
      }
      setPaginationData(fetchDroneInventory?.data);
      const modifiedData = formatDroneInventoryList(
        fetchDroneInventory?.data?.data,
        handleEditDrone,
        handleDeleteDrone
      );
      setDroneInventoryList(modifiedData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDroneInventoryListing();
  }, []);

  return (
    <div style={{ height: "300px", overflow: "auto" }}>
      <CustomTableForDrones columns={tableColumns} data={droneInventoryList} />
    </div>
  );
};

export default DroneInventoryTab;

const tableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "drone_name",
    label: "Drone",
  },
  {
    id: "drone_category",
    label: "Category",
  },
  {
    id: "serial_no",
    label: "Serial No.",
  },
  {
    id: "location",
    label: "Location",
  },
  {
    id: "registered_on",
    label: "Registered On",
  },
  {
    id: "updated_on",
    label: "Updated On",
  },
  {
    id: "updated_by",
    label: "Updated By",
  },
  {
    id: "drone_status",
    label: "Status",
  },
  //   {
  //     id: "actions",
  //     label: "Actions",
  //   },
];
