import React, { useEffect, useState } from "react";
import { token_api } from "../../Utils/network";
import { modifyCourseBookData } from "../../Pages/CourseBookings/utility";
import CustomTable from "../CustomTable/CustomTable";
import { Link, useNavigate } from "react-router-dom";

const CourseTableforDashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const [courseListing, setCourseListing] = useState([]);

  const getAllCourseBookingDetails = async () => {
    try {
      const fetchCourseBookings = await token_api.get(
        `course/course_book?ordering=-total_amt&total_amt_not_null=true`
      );

      if (fetchCourseBookings?.status !== 200) {
        throw new Error("NETWORk Error");
      }
      const modifiedBookingArray = modifyCourseBookData(
        fetchCourseBookings?.data?.data,
        handleRedirect
      );
      setLoading(false);
      setBookingList(modifiedBookingArray);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllCourseBookingDetails();
  }, []);

  const handleRedirect = (id, type = "none") => {
    if (type == "edit") {
      navigate(`/bookings/edit/create-course-bookings`, {
        state: { id },
      });
      return;
    }

    if (type == "generate") {
      token_api
        .post(`/course/create_invoice/`, {
          course_purchase_id: id?.id,
        })
        .then((response) => {
          if (response?.status == 200 || response?.status == 201) {
            alert(`invoice request generated`);
          } else {
            throw new Error("Something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });

      return;
    }

    navigate(`/bookings/course-booking-details/${id}`);
  };
  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div style={{ height: "300px", overflow: "auto" }}>
          <CustomTable
            columns={tableColumns}
            data={bookingList}
            handleClick={handleRedirect}
            tableFor="courseOrders"
          />
        </div>
      )}
    </div>
  );
};

export default CourseTableforDashboard;

const tableColumns = [
  {
    id: "bookin_status",
    label: "Booking Status",
  },
  {
    id: "book_id",
    label: "Booking ID",
  },
  {
    id: "book_date",
    label: "Booking Date",
  },
  {
    id: "user_name",
    label: "User Name",
  },
  {
    id: "course_name",
    label: "Course Name",
  },
  {
    id: "batch_name",
    label: "Batch Name",
  },
  {
    id: "mode",
    label: "Mode",
  },
  {
    id: "start_date",
    label: "Start Date",
  },

  {
    id: "slot_fly",
    label: "Flying Slot",
  },
  {
    id: "slot_flying_center",
    label: "Slot Flying Center",
  },
  {
    id: "location",
    label: "Location(City)",
  },
  {
    id: "payment_status",
    label: "Payment Status",
  },
  {
    id: "amount",
    label: "Amount",
  },
  {
    id: "accommodation",
    label: "Accomodation",
  },
  {
    id: "accomodation_date",
    label: "Accomodation Date",
  },
  {
    id: "certificate",
    label: "Certificate",
  },
];
