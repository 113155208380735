import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from "./PilotMaps.module.css";
import Container from "../../Component/Container/Container";
import pilotIcon from "../../Assets/pilotIcon.svg";
import PilotMap from "../../Assets/pilotMap.svg";
import pilotCalendar from "../../Assets/pilotCalendar.svg";
import TrackingMap from "../../Component/TrackingMap/TrackingMap";
import { open_api, token_api, token_api1 } from "../../Utils/network";
import axios from "axios";
import WeekDayView from "../../Component/WeekDayView/WeekDayView";
import { format } from "date-fns";
import { Button } from "@mui/material";
import {
  checkColor,
  checkColorDrone,
  convertStringToDateTime,
  isToday,
} from "../../Utils/helper";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import SlideOutPanel from "../../Component/SlideOutPanel/SlideOutPanel";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import DronePilot from "../../Component/DronePilot/DronePilot";
const PilotMaps = () => {
  const [loc, updLoc] = useState({
    lat: 28.627805609150325,
    lng: 77.21850520718955,
  });
  const { checkMapData } = useContext(UserCredsContext);
  const { vehicle, drone, pilot } = useMemo(
    () => getMapDisplayData(),
    [checkMapData]
  );

  function getMapDisplayData() {
    let arrayData = { vehicle: false, drone: false, pilot: false };
    for (var i = 0; i < checkMapData.length; i++) {
      const _data = checkMapData[i].id;
      const _data1 = checkMapData[i];
      arrayData = { ...arrayData, [_data]: _data1.checked };
    }
    return arrayData;
  }

  const [isFullscreen, setIsFullscreen] = useState(false);
  const divRef = useRef(null);
  const [listingData, setListingData] = useState({});
  const [pilots, setPilots] = useState([]);
  const [pilotSelected, setPilotSelected] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [pincode, setPincode] = useState(110001);
  const [status, setStatus] = useState({ completed: null, pending: null });
  const [droneLocation, setDroneLocation] = useState([]);
  const [vehicleTracking, setVechicleTracking] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [pilotsLocation, setPilotLocation] = useState([]);
  const [selectLocation, setSelectLocation] = useState({
    lat: null,
    lng: null,
    user: null,
  });
  const [filters, setFilters] = useState({ pilot: [] });
  const [closePilots, setClosePilots] = useState(false);

  // function countPilots() {
  //   const data = pilotsLocation.filter((item, index) => {
  //     return item.hasOwnProperty("message") && item.message
  //       ? item.message?.lat !== null
  //         ? isToday(item?.message?.created)
  //           ? true
  //           : false
  //         : false
  //       : false;
  //   });
  //   return data;
  // }
  // const livePilots = useMemo(() => countPilots(), [pilotsLocation]);

  const [currentLive, setCurrentLive] = useState({ lat: null, lng: null });
  const [statusListing, setStatusListing] = useState({
    idle: {},
    running: {},
    unreachable: {},
    stopped: {},
  });
  const [droneStatusListing, setDroneStatusListing] = useState({
    unknown: "",
    offline: "",
    online: "",
  });
  const [selectedDate, setSelectedDate] = useState(
    format(new Date(), "yyyy-MM-dd")
  );

  useEffect(() => {
    // getPilotListing();
    getVechileListing();
    getDroneLocation();
    const intervalId = setInterval(() => {
      getVechileListing();
      getDroneLocation();
    }, 90000); // Run every 90 second (90000 milliseconds)
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      let filtersObject = {};
      if (filters?.pilot[0]) {
        console.log(filters?.pilot[0], "pilot_name");
        const ids = filters?.pilot?.map((info) => info?.user?.first_name);
        filtersObject.q = ids[0];
      }

      getPilotListing(filtersObject);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [filters?.pilot]);

  const getVechileListing = () => {
    try {
      token_api1
        .get(`/drone/vehicle/fetch_data`)
        .then((response) => {
          setVechicleTracking(response.data.data.data);
          const vehicleData = response.data.data.data;
          let separatedByStatus = {
            ...statusListing,
          };
          separatedByStatus = vehicleData.reduce((acc, item) => {
            const status = item.vehicle_status.toLowerCase();
            acc[status] = acc[status] || [];
            acc[status].push(item);
            return acc;
          }, {});
          const { idle, running, stopped, unreachable } = separatedByStatus;
          setStatusListing({ idle, running, stopped, unreachable });
        })
        .catch((error) => {});
    } catch (error) {}
  };

  useEffect(() => {
    if (pilotSelected.hasOwnProperty("id")) getData();
  }, [pilotSelected, selectedDate]);

  const getDroneLocation = () => {
    token_api1
      .get(`/drone/drone_location/live_tracking`)
      .then((response) => {
        setDroneLocation(response.data.data);

        const droneData = response.data.data;
        let separatedByStatus = {
          ...droneStatusListing,
        };
        separatedByStatus = droneData.reduce((acc, item) => {
          const status = item.status.toLowerCase();
          acc[status] = acc[status] || [];
          acc[status].push(item);
          return acc;
        }, {});
        const { unknown, offline, online } = separatedByStatus;
        setDroneStatusListing({ unknown, offline, online });
      })
      .catch((error) => {});
  };
  const getData = () => {
    setListingData([]);
    setStatus({
      completed: null,
      pending: null,
    });
    token_api
      .get(
        `/service/pilot/booking_data?pilot_id=${pilotSelected?.id}&date=${selectedDate}`
      )
      .then((response) => {
        setListingData(response.data.data);
        const data = response.data.data;
        if (
          data &&
          data.hasOwnProperty("Service Bookings") &&
          data["Service Bookings"].length > 0
        ) {
          setStatus({
            completed: data["Total Completed Service Bookings"],
            pending: data["Total Scheduled Service Bookings"],
          });
          if (
            data["Service Bookings"][0].lat != null &&
            data["Service Bookings"][0].long != null
          ) {
            getPincode(
              data["Service Bookings"][0].lat,
              data["Service Bookings"][0].long
            );
          }
        }
      })
      .catch((error) => {});
  };

  const getPilotListing = async (filterObj) => {
    token_api
      .get(`/users/profile/pilot?page_size=10000`, {
        params: { ...filterObj },
        paramsSerializer: { indexes: null },
      })
      .then((response) => {
        setPilots(response.data.data);
        setPilotLocation(response.data.data);
        if (response.data.data.length > 0) {
          setPilotSelected(response.data.data[0]);
        }
      })
      .catch((error) => {});
  };

  const handlePilot = (_data) => {
    setPilotSelected(_data);
  };

  const getPincode = (latitude, longitude) => {
    const apiKey = "AIzaSyCMHN__LczEziaYuKXQ-SKdiMfx5AiY66o";
    // Create a request URL for reverse geocoding
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    // Make the API request
    axios
      .get(apiUrl)
      .then((response) => {
        // Extract postal code from the API response
        const results = response.data.results;
        if (results.length > 0) {
          const postalCode = results[0].address_components.find((component) =>
            component.types.includes("postal_code")
          ).long_name;
          setPincode(postalCode);
          updLoc({ lat: latitude, lng: longitude });
        } else {
        }
      })
      .catch((error) => {});
  };

  // const getPincodeAddress = async () => {
  //   await axios
  //     .get(`https://api.postalpincode.in/pincode/${pin}`)
  //     .then(async (response) => {
  //       if (
  //         response.data.length !== 0 &&
  //         response.data[0].PostOffice.length !== 0
  //       ) {
  //         var pinData = response.data[0].PostOffice[0];
  //         const { District, Division, State, Name, Region, Country } = pinData;
  //         if (type === "add") {
  //           // const latLongObject = await getLatLong(pin);
  //           if (lat === "" || long === "") {
  //           } else {
  //           }
  //         }
  //       }
  //     });
  // };

  // const toggleFullScreen = () => {
  //   setIsFullScreen(!isFullScreen);
  // };

  const toggleFullscreen = () => {
    const elem = divRef.current;

    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch((err) => {
        console.error("Failed to enable fullscreen:", err);
      });
    } else {
      document.exitFullscreen();
    }

    setIsFullscreen((prevState) => !prevState);
  };
  const handleLiveLocation = (e, _data) => {
    e.stopPropagation();
    if (_data.hasOwnProperty("message")) {
      const { lat, long, pilot } = _data.message;
      setCurrentLive({ lat: lat, lng: long, id: pilot });
    }
  };

  const handleSearchPilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        pilot: selectedValues,
      }));
    }
  };
  return (
    <div
      ref={divRef}
      className={`${styles.fullscreenContent} ${
        isFullScreen ? styles.fullscreen : styles.beforefullscreen
      }`}
    >
      <div className={styles.mainContainer}>
        <div
          // style={{
          //   backgroundImage: `url(${PilotMap})`,
          // }}
          className={styles.rightCon}
        >
          <SlideOutPanel />
          <TrackingMap
            width="100%"
            height="100%"
            search={`${pincode}`}
            loc={loc}
            updLoc={updLoc}
            listingData={listingData}
            pilotSelected={pilotSelected?.user?.id}
            pilots={pilots}
            pilotsLocation={pilotsLocation}
            setPilotLocation={setPilotLocation}
            vehicleTracking={vehicleTracking}
            selectLocation={selectLocation}
            droneLocation={droneLocation}
            currentLive={currentLive}
            showVehicle={{
              vehicle,
              drone,
              pilot,
            }}
          />
          <div className={styles.zoomButtons} onClick={toggleFullscreen}>
            {isFullScreen ? (
              <ZoomOutMapIcon fontSize="large" />
            ) : (
              <ZoomInMapIcon fontSize="large" />
            )}
          </div>
          <div></div>

          <div className={styles.leftCon}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setClosePilots(!closePilots);
                }}
              >
                {closePilots ? <ArrowForwardIcon /> : <ArrowBackIcon />}
              </div>
              <div
                style={{ margin: "10px 0px 10px 10px" }}
                className={styles.pilotsText}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <p>Pilots</p>
                  {/* <CustomSearchFilter
                    placeholder="Search by pilot name..."
                    onChange={(e) => {
                      setFilters({ ...filters, q: e });
                    }}
                  /> */}
                  <DronePilot
                    value={filters?.pilot}
                    onChange={handleSearchPilot}
                  />
                </div>
                <div className={styles.dividepilot}>
                  <p
                    style={{
                      color: "rgba(37, 164, 85, 1)",
                      marginRight: "8px",
                    }}
                  >
                    {/* Online- {livePilots.length}{" "} */}
                  </p>
                  <p style={{ color: "rgba(239, 100, 100, 1)" }}>
                    {/* Offline- {pilotsLocation.length - livePilots.length} */}
                  </p>
                </div>
              </div>
            </div>
            <div
              className={styles.pilotsListingCon}
              style={{ display: closePilots ? "none" : "block" }}
            >
              {pilotsLocation.map((data, index) => {
                console.log(data, "location data");

                var currentLocation = "";
                if (data.message !== null) {
                  currentLocation = `${
                    data?.location || "N.A"
                  }, ${convertStringToDateTime(data?.message?.created)}
                    `;
                }
                return (
                  <div
                    className={`${styles.pilotCon} ${
                      pilotSelected?.user?.id === data?.user?.id
                        ? styles.activePilot
                        : styles.inactivePilot
                    }`}
                    key={index}
                    onClick={(e) => {
                      handlePilot(data);
                      data.message &&
                        data.message.user !== null &&
                        handleLiveLocation(e, data);
                    }}
                  >
                    <div className={styles.topCon}>
                      <div>
                        <img
                          src={data.avatar}
                          style={{ width: "30px", borderRadius: "50px" }}
                        />
                      </div>
                      <div>
                        <h3 style={{ fontSize: "16px" }}>
                          {data?.user?.first_name} {data?.user?.last_name}
                        </h3>
                        <p style={{ fontSize: "14px" }}>
                          {/* {data?.license?.license_no} */}
                          {data?.user?.mobile}
                        </p>
                      </div>
                      <Button
                        style={{
                          textTransform: "capitalize",
                          height: "20px",
                          position: "absolute",
                          top: "-18px",
                          right: "-5px",
                        }}
                        size="small"
                        variant="contained"
                        onClick={(e) => handleLiveLocation(e, data)}
                        disabled={
                          data.message
                            ? data.message.user !== null
                              ? false
                              : true
                            : true
                        }
                      >
                        Live Location
                      </Button>
                    </div>
                    <p
                      className={styles.locationTitle}
                      style={{ fontSize: "14px" }}
                    >
                      Last Location :{" "}
                      <span>{currentLocation ? currentLocation : "-"}</span>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.bottomContainer} style={{ zIndex: "999" }}>
            {/* <Button variant="contained">Go live</Button> */}
            <div style={{ width: "50%" }}>
              <div>
                {vehicle && (
                  <div>
                    <p
                      style={{
                        background: "#E95E33",
                        display: "inline-block",
                        marginBottom: "10px",
                        fontWeight: "900",
                        padding: "5px",
                        fontWeight: "20px",
                        color: "white",
                        borderRadius: "5px",
                      }}
                    >
                      Vehicles
                    </p>
                    <div className={styles.typeListing}>
                      <div
                        className={styles.indiCard}
                        style={{
                          background: `#808080DB`,
                          wordBreak: "break-all",
                        }}
                      >
                        <p>Total</p>
                        <p>{vehicleTracking.length}</p>
                      </div>
                      {Object.entries(statusListing).map(([key, value]) => {
                        return (
                          <div
                            className={styles.indiCard}
                            style={{
                              background: `${checkColor(key)}DB`,
                              wordBreak: "break-all",
                            }}
                            key={key}
                          >
                            <p>{key ? key : "-"}</p>
                            <p>{value ? value.length : "-"}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              <div style={{ width: "100%", background: "white" }}>
                <WeekDayView setDate={setSelectedDate} />
              </div>
            </div>
            <div>
              {drone && (
                <div>
                  <p
                    style={{
                      background: "#E95E33",
                      display: "inline-block",
                      marginBottom: "10px",
                      fontWeight: "900",
                      padding: "5px",
                      fontWeight: "20px",
                      color: "white",
                      borderRadius: "5px",
                    }}
                  >
                    Drones
                  </p>
                  <div className={styles.typeListing}>
                    <div
                      className={styles.indiCard1}
                      style={{
                        background: `#808080DB`,
                        wordBreak: "break-all",
                      }}
                    >
                      <p>Total</p>
                      <p>{droneLocation.length}</p>
                    </div>
                    {Object.entries(droneStatusListing).map(([key, value]) => {
                      return (
                        <div
                          className={styles.indiCard1}
                          style={{
                            background: `${checkColorDrone(key)}DB`,
                            wordBreak: "break-all",
                          }}
                          key={key}
                        >
                          <p>{key ? key : "-"}</p>
                          <p>{value ? value.length : "-"}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className={styles.statusClass}>
                <div className={styles.completedCon}>
                  <h1> {status.completed ? status.completed : "-"}</h1>
                  <h3>Completed</h3>
                </div>
                <div className={styles.pendingCon}>
                  <h1> {status.pending ? status.pending : "-"}</h1>
                  <h3>Pending</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PilotMaps;

const pilotsData = [
  {
    id: 1,
    name: "Premchand",
    serviceId: "KG423525",
    location: "Koilpur, Uttar Pradesh",
  },
  {
    id: 2,
    name: "Premchand",
    serviceId: "KG423525",
    location: "Koilpur, Uttar Pradesh",
  },
  {
    id: 3,
    name: "Premchand",
    serviceId: "KG423525",
    location: "Koilpur, Uttar Pradesh",
  },
  {
    id: 4,
    name: "Premchand",
    serviceId: "KG423525",
    location: "Koilpur, Uttar Pradesh",
  },
];

// Service Bookings
// :
// []
// Total Completed Service Bookings
// :
// 0
// Total Scheduled Service Bookings
// :
// 0
// Total Service Bookings
// :
// 0
