export const Validate = (value) => {
  return new Promise((resolve, reject) => {
    const { city, state, district, country, pincode } = value;

    const errors = { error: false };

    if (!district) {
      errors.district = "Please enter district";
      errors.error = true;
    }

    if (!country) {
      errors.country = "Please enter country";
      errors.error = true;
    }

    if (!state) {
      errors.state = "Please enter state";
      errors.error = true;
    }

    if (!city) {
      errors.city = "Please enter city/town name";
      errors.error = true;
    }

    if (!pincode) {
      errors.pincode = "Please enter pincode";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};

export const ValidateDroneOrder = (value) => {
  return new Promise((resolve, reject) => {
    const { user, products } = value;

    console.log(value, "hariValue");

    const errors = { error: false };

    if (!user?.id) {
      errors.user = "Please select user";
      errors.error = true;
    }
    if (!products || products?.length === 0) {
      errors.products = "Please select products";
      errors.error = true;
    }

    // if (!country) {
    //   errors.country = "Please enter country";
    //   errors.error = true;
    // }

    // if (!state) {
    //   errors.state = "Please enter state";
    //   errors.error = true;
    // }

    // if (!city) {
    //   errors.city = "Please enter city/town name";
    //   errors.error = true;
    // }

    // if (!pincode) {
    //   errors.pincode = "Please enter pincode";
    //   errors.error = true;
    // }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};
