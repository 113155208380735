import React, { useContext, useEffect, useState } from "react";
import styles from "./ServiceList.module.css";
import { Link, useNavigate } from "react-router-dom";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import { Button } from "@mui/material";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import CustomButton from "../../Component/CustomButton/CustomButton";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { token_api, base_url } from "../../Utils/network";
import editIcon from "../../Assets/editIcon.png";
import delete_icon from "../../Assets/delete_icon.svg";
import CustomModal from "../../Component/CustomModal/CustomModal";
import { describeDuration } from "../../Utils/helper";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import axios from "axios";

const ServicesList = () => {
  let navigate = useNavigate();
  const { user_permissions } = useContext(UserCredsContext);
  const [filters, setfilters] = useState({});
  const [serviceList, setServiceList] = useState([]);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [categoryList, setCategoryList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviceId, setServiceId] = useState(null);

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Services</div>,
  ];

  const handleEditclick = (e, item) => {
    navigate(`/droneservices/serviceslist/edit-service/${item?.slug}`);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setServiceId(null);
  };

  const handleOpenDeleteModal = (ItemId) => {
    setServiceId(ItemId);
    setIsModalOpen(true);
  };

  const handleDeleteService = () => {
    token_api
      .delete(`service/${serviceId}/`)
      .then((response) => {
        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 204
        ) {
          alert("Service deleted successfully!");
          setIsModalOpen(false);
          setServiceId(null);
          getServiceListing();
          getCategoryList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getServiceListing = async (filterObject) => {
    try {
      const fetchList = await token_api.get(`service`, {
        params: { ...filterObject },
        paramsSerializer: {
          indexes: null,
        },
      });
      if (fetchList?.status !== 200) {
        throw new Error("Network Error");
      }
      setPaginationData(fetchList?.data);

      let modifiedArray = fetchList?.data?.data?.map((item) => {
        // const { bookingaddonservice } = item;

        return {
          id: <div style={{ fontSize: "12px" }}>{item?.id}</div>,
          name: <div style={{ fontSize: "12px" }}> {item?.title}</div>,

          category: (
            <p style={{ fontSize: "12px" }}>{`${
              item?.category?.title || "N.A"
            }`}</p>
          ),
          drone: (
            <p style={{ fontSize: "12px" }}>{`${
              item?.drone[0] ? item?.drone[0] : "N.A"
            }`}</p>
          ),
          area_covered: (
            <p style={{ fontSize: "12px" }}>{`${
              item?.area_covered || "N.A"
            }`}</p>
          ),
          duration: (
            <p style={{ fontSize: "12px" }}>
              {item?.duration ?
              //  describeDuration
              (item?.duration) : "N.A"}
            </p>
          ),

          price: (
            <p style={{ fontSize: "12px" }}>{`${
              item?.price ? `Rs.${item?.price}` : "N.A"
            }`}</p>
          ),
          // area: `${item?.area || "N.A"}`,

          action: (
            <div className={styles.languageCon}>
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleEditclick(e, item)}
                >
                  <img src={editIcon} />
                </div>
              )}
              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpenDeleteModal(item?.id)}
                >
                  <img src={delete_icon} />
                </div>
              )}
            </div>
          ),
        };
      });

      setServiceList([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      let filterObject = {};

      if (pageState) {
        filterObject.page = pageState;
      }
      if (filters?.q) {
        filterObject.q = filters?.q;
      }
      if (filters?.category) {
        const ids = filters?.category?.map((info) => info?.slug);
        filterObject.category = ids[0];
      }

      getServiceListing(filterObject);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [pageState, filters?.q, filters?.category]);

  const getCategoryList = () => {
    token_api
      .get(`/service/category`)
      .then((response) => {
        setCategoryList(response?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCategoryList();
  }, []);
  const handleFilter = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };
  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  const handleExportData = async () => {
    try {
      let filterObj = {};
      if (filters?.q) {
        filterObj.q = filters?.q;
      }
      if (filters?.category) {
        const ids = filters?.category?.map((info) => info?.slug);
        filterObj.category = ids[0];
      }

      const response = await axios.get(`${base_url}service/export`, {
        params: { ...filterObj },
        paramsSerializer: { indexes: null },
      });
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      if (
        response?.status === 200 ||
        response?.status === 201 ||
        response?.status === 204
      ) {
        alert("Services export file downloaded successfully!");

        setfilters({});
      }
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };

  return (
    <Container>
      <div className="flexBetween">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>{" "}
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>

        {user_permissions?.write && (
          <Link to="/droneservices/serviceslist/create-service">
            <Button variant="contained" className="btnLinearGradient">
              + New Service{" "}
            </Button>
          </Link>
        )}
      </div>

      <FilterContainer
        style={{ maxHeight: "40px", marginTop: "20px" }}
        // className={`${styles.marginTopBox}`}
      >
        <CustomSearchFilter
          placeholder="Search by service name, category..."
          value={filters?.q}
          onChange={(e) => {
            setfilters({ ...filters, q: e });
          }}
        />
        <CustomSelectChip
          multiple={false}
          label="Filter By  Category"
          name="category"
          listArray={categoryList}
          onChange={handleFilter}
          onDelete={handleDeleteChip}
          value={filters?.category}
        />

        <CustomButton
          text={"Export"}
          maxWidth="110px"
          onClick={handleExportData}
          //   onClick={() => setOpenDrawer(true)}
        />
      </FilterContainer>
      <div className="totalCountText">
        Total Count : <span>{total_count}</span>
      </div>

      <div className={styles.tableContainer}>
        <CustomTable
          columns={tableColumns}
          data={serviceList}
          //   handleClick={handleRedirect}
          tableFor="serviceListing"
        />
      </div>
      <div className={styles.paginationCon}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>

      <CustomModal open={isModalOpen} onClose={handleCloseModal} height="400px">
        <div className={styles.deleteConfirmCon}>
          <p className={styles.reallyText}>
            Do you really want to delete this service?
          </p>
          <div className={styles.bottomCon}>
            <h1 className={styles.yesText} onClick={handleDeleteService}>
              Yes
            </h1>
            <h1 className={styles.noText} onClick={handleCloseModal}>
              No
            </h1>
          </div>
        </div>
      </CustomModal>
    </Container>
  );
};

export default ServicesList;

const tableColumns = [
  // {
  //   id: "checkBox",
  //   label: "",
  // },
  {
    id: "id",
    label: "Service ID",
  },
  {
    id: "name",
    label: "Name Of Service",
  },
  {
    id: "category",
    label: "Category",
  },
  {
    id: "drone",
    label: "Drone Used",
  },
  {
    id: "area_covered",
    label: "Area Covered",
  },
  {
    id: "duration",
    label: "Duration",
  },

  {
    id: "price",
    label: "Price Per Acre",
  },
  // {
  //   id: "area",
  //   label: "Area Covered/Day",
  // },
  {
    id: "action",
    label: "Action",
  },
];
