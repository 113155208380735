import React, { useEffect, useState } from "react";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import DashboardHeader from "../../Component/DashboardHeader/DashboardHeader";
import { serviceOrderCard, bannerDetails } from "../../Utils/constants";
import { token_api } from "../../Utils/network";
import BoxWithShadow from "../../Component/BoxWithShadow/BoxWithShadow";
import StackedAreaChart from "../../Component/StackedAreaChart/StackedAreaChart";
import ServiceCourseDroneTabs from "../../Component/ServiceCourseDroneTabs/ServiceCourseDroneTabs";
import MapforDashboard from "../../Component/MapforDashboard/MapforDashboard";
import brokenDrone from "../../Assets/brokenDrone.svg";
import workingDrone from "../../Assets/workingDrone.svg";
import Pilot from "../../Assets/Pilot.svg";
import batteries from "../../Assets/batteries.svg";
import DronePilotBatteryTabs from "../../Component/DronePilotBatteryTabs/DronePilotBatteryTabs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import styles from "./Dashboard.module.css";
import { convertDaterange } from "../../Utils/helper";
import PilotApplication from "../../Component/PilotApplication/PilotApplication";
import ReactCarasolSlider from "../../Component/ReactCarasolSlider/ReactCarasolSlider";

const DashBoard = () => {
  const firstDivWidth = "20%";
  const gapWidth = "20px";
  const secondDivWidth = `calc(79% - ${gapWidth})`;
  const [countData, setCountData] = useState([]);
  const [bookingList, setBookingList] = useState([]);
  const [inventoryCo, setinventoryCount] = useState([]);
  const [averageCount, setAverageCount] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  const breadcrumbs = [
    <div className="orange-1 bread">Home</div>,
    <div className="orange-1 bread">Dashboard</div>,
  ];
  function getCount() {
    const apiParams = {};

    if (startDate && endDate) {
      const { start, end } = convertDaterange(startDate, endDate);

      apiParams.start_date = start;
      apiParams.end_date = end;
    }
    token_api
      .get(`/base/dashboard`, {
        params: apiParams,
      })
      .then((response) => {
        let newresponse = response?.data?.data?.data;
        const modifiedBannerDetails = bannerDetails.map((info) => {
          if (info?.id == 1) {
            return {
              ...info,
              booking_count: "",
              total_revenue: newresponse?.users?.total_new_users,
            };
          } else if (info?.id == 2) {
            return {
              ...info,
              booking_count: newresponse?.service_booking?.booking_count,
              total_revenue: newresponse?.service_booking?.total_revenue
                ? newresponse?.service_booking?.total_revenue.toFixed(2)
                : "N.A",
            };
          } else if (info?.id == 3) {
            return {
              ...info,
              booking_count: newresponse?.course_booking?.booking_count,
              total_revenue: newresponse?.course_booking?.total_revenue
                ? newresponse?.course_booking?.total_revenue.toFixed(2)
                : "N.A",
            };
          } else if (info?.id == 4) {
            return {
              ...info,
              booking_count: "",
              total_revenue: "",
            };
          }

          return info;
        });


        setCountData(modifiedBannerDetails);

      })
      .catch((error) => {
        console.log(error);
      });
  }
  function inventoryCount() {
    token_api
      .get(`/drone/inventory_status_count`)
      .then((response) => {
        let newresponse = response?.data?.data?.data;

        let newarray = mapInfo?.map((info) => ({
          ...info,
          count: newresponse[info?.hint],
        }));

        setinventoryCount(newarray);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getAverageCount() {
    const apiParams = {};

    if (startDate && endDate) {
      const { start, end } = convertDaterange(startDate, endDate);

      apiParams.start_date = start;
      apiParams.end_date = end;
    }

    token_api
      .get(`/base/statistic`, {
        params: apiParams,
      })
      .then((response) => {
        const newres = response?.data?.data?.data;
        setAverageCount(newres);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    inventoryCount();
  }, []);
  useEffect(() => {
    getCount();

    getAverageCount();
  }, [startDate, endDate]);

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "10px",
          gap: "20px",
        }}
      >
        <p>Select Date Range:</p>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div>
            <CustomDatePicker
              label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
            />
          </div>
          <div>
            <CustomDatePicker
              label="End Date"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
            />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", columnGap: "20px" }}>
        {countData?.map((item, index) => {
          return (
            <DashboardHeader
              key={index}
              id={item?.id}
              cardTitle={item?.title}
              cardIcon={item?.icon}
              primaryGardient={item?.primaryGradient}
              secondaryGradient={item?.secondarayGradient}
              value={item?.value || "N.A"}
              countvalue={countData}
              hint={item?.hint}
              present={true}
              item={item}
              eclipseIcon={item?.eclipseIcon}
            />
          );
        })}
      </div>

      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "space-between",
          gap: gapWidth,
        }}
      >
        <div
          style={{
            width: firstDivWidth,
            boxSizing: "border-box",
            alignSelf: "stretch",
          }}
        >
          <BoxWithShadow>
            <div style={{ padding: "15px" }}>
              <p style={{ fontWeight: "bold", fontSize: "18px" }}>Statistics</p>
              <div style={{ marginBottom: "40px" }}>
                <p style={{ margin: "2px", fontSize: "16px" }}>
                  Average Service Order Value
                </p>
                <p
                  style={{
                    color: "rgba(233, 94, 51, 1)",
                    margin: "2px",
                    fontWeight: "bold",
                  }}
                >
                  ₹ {averageCount?.service_booking?.avg_revenue}
                </p>
              </div>
              <div style={{ marginBottom: "40px" }}>
                <p style={{ margin: "2px", fontSize: "16px" }}>
                  Average Training Order Value
                </p>
                <p
                  style={{
                    color: "rgba(233, 94, 51, 1)",
                    margin: "2px",
                    fontWeight: "bold",
                  }}
                >
                  ₹ {averageCount?.course_booking?.avg_revenue}
                </p>
              </div>
            </div>
          </BoxWithShadow>
        </div>

        <div style={{ width: secondDivWidth, boxSizing: "border-box" }}>
          <BoxWithShadow>
            <div style={{ padding: "15px" }}>
              <p style={{ fontWeight: "bold", fontSize: "18px" }}>Bookings</p>
              <StackedAreaChart startDate={startDate} endDate={endDate} />
            </div>
          </BoxWithShadow>
        </div>
      </div>

      <div
        style={{
          marginTop: "20px",
          display: "flex",

          gap: "31px",
        }}
      >
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            alignSelf: "stretch",
          }}
        >
          <BoxWithShadow>
            <div style={{ padding: "10px" }}>
              <p style={{ fontWeight: "bold", fontSize: "18px" }}>
                Order History{" "}
                <span style={{ fontWeight: "normal", fontSize: "16px" }}>
                  (Top 10)
                </span>
                <ServiceCourseDroneTabs />
              </p>
            </div>
          </BoxWithShadow>
        </div>   
      </div>

      <div style={{ marginTop: "20px" }}>
        <BoxWithShadow>
          <div style={{ padding: "15px" }}>
            <p style={{ fontWeight: "bold", fontSize: "18px" }}>Inventory</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  height: "max-content",
                  marginTop: "40px",
                }}
              >
                {inventoryCo?.map((info, index) => (
                  <div
                    key={index}
                    style={{
                      width: "40%",
                      boxSizing: "border-box",
                      padding: "5px",
                      margin: `5px 0 ${index < 2 ? "20px" : "5px 0"}`,
                    }}
                  >
                    <div>
                      <p
                        style={{
                          textAlign: "center",
                          color: "rgba(233, 94, 51, 1)",
                          fontSize: "24px",
                          fontWeight: "700",
                        }}
                      >
                        {info?.count}
                      </p>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <img
                          src={info?.icon}
                          style={{ width: "100%", maxWidth: "100px" }}
                          alt={info?.title}
                        />
                      </div>
                      <p
                        style={{
                          textAlign: "center",
                          color: "rgba(0, 0, 0, 1)",
                          fontSize: "14px",
                        }}
                      >
                        {info?.title}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              <MapforDashboard />
            </div>
            <div style={{ marginTop: "20px" }}>
              <DronePilotBatteryTabs />
            </div>
          </div>
        </BoxWithShadow>
      </div>
    </Container>
  );
};

export default DashBoard;

const mapInfo = [
  {
    title: "Working Drones",
    icon: workingDrone,
    hint: "working_drones",
  },
  {
    title: "Damaged Drones",
    icon: brokenDrone,
    hint: "damaged_drones",
  },
  {
    title: "Pilots",
    icon: Pilot,
    hint: "pilot_profile",
  },
  {
    title: "Batteries",
    icon: batteries,
    hint: "batteries",
  },
];
