import {
  formatDate,
  helperdayMonthYearFull,
  getStatusStyles,
} from "../../Utils/Utils";
import styles from "./DroneBooking.module.css";
import editIcon from "../../Assets/editIcon.png";
import deleteIcon from "../../Assets/delete.png";
import defaultImg from "../../Assets/defaultImg.svg";
import droneAvailable from "../../Assets/droneAvailable.svg";
import droneNotAvailable from "../../Assets/droneNotAvailable.svg";
import { Button } from "@mui/material";

export const formatDroneInventoryList = (
  data,
  handleEditDrone,
  handleOpenDeleteModal,
  handleToShowAssignList,
  handleOpenAssignPilot
) => {
  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Working";
      case 2:
        return "Sold";
      case 3:
        return "Damaged";
      case 4:
        return "Under Repair";
      default:
        return "Unknown";
    }
  };

  const modifiedData = data?.map((item) => {
    // const {
    //   id,
    //   certificate,
    //   drone: {
    //     title: droneTitle,
    //     category: { title: categoryTitle },
    //     available_for_sale,
    //     location,
    //     banner,
    //   },
    //   stationed_at: { city },
    //   serial_no,
    //   pilot,
    //   // pilot: {
    //   //   first_name: pilotFirst,
    //   //   last_name: pilotLast,
    //   //   mobile: pilotMobile,
    //   // },
    //   model_no,
    //   price,
    //   registration_date,
    //   status,
    //   modified_by: { first_name, last_name },
    //   modified,
    // } = item;

    const droneStatusStyles = getStatusStyles(item?.status);
    return {
      id: (
        <p
          onClick={() => {
            handleToShowAssignList(item);
          }}
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            fontSize: "12px",
          }}
        >
          {item?.id}
        </p>
      ),
      pdrl_id: <p style={{ fontSize: "12px" }}>{item?.pdrl_id}</p>,
      drone_name: <p style={{ fontSize: "12px" }}>{item?.drone_name}</p>,
      drone: (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div>
            <img
              src={item?.drone?.banner ? item?.drone?.banner : defaultImg}
              style={{ width: "50px", height: "50px" }}
              onError={(e) => {
                e.target.src = defaultImg;
              }}
            />
          </div>{" "}
          <p style={{ fontSize: "12px" }}>{item?.drone?.title}</p>{" "}
        </div>
      ),
      drone_category: (
        <p style={{ fontSize: "12px" }}>
          {item?.drone?.category?.title || "N.A"}
        </p>
      ),
      serial_no: <p style={{ fontSize: "12px" }}>{item?.serial_no}</p>,
      pilot_name: (
        <div style={{ fontSize: "12px" }}>
          {item?.pilot?.first_name && item?.pilot?.last_name
            ? `${item?.pilot?.first_name} ${item?.pilot?.last_name}`
            : "NA"}
        </div>
      ),
      pilot_no: (
        <div style={{ fontSize: "12px" }}>
          {item?.pilot?.mobile ? `${item?.pilot?.mobile} ` : "NA"}
        </div>
      ),
      registered_on: (
        <p style={{ fontSize: "12px" }}>
          {formatDate(item?.registration_date)}
        </p>
      ),
      // registered_on: helperdayMonthYearFull(registration_date),
      location: (
        <div style={{ fontSize: "12px" }}>
          {item?.block && item?.block.hasOwnProperty("block_name")
            ? `${item.block?.block_name}, ${item.block?.district}, ${item.block?.state}`
            : "N.A"}
        </div>
      ),
      stationed_at: (
        <div style={{ fontSize: "12px" }}>
          {item?.stationed_at
            ? `${item.stationed_at?.area}, ${item.stationed_at?.city}, ${item.stationed_at?.state}`
            : "N.A"}
        </div>
      ),
      updated_on:
        (item?.modified && (
          <p style={{ fontSize: "12px" }}>
            {helperdayMonthYearFull(item?.modified)}
          </p>
        )) ||
        "N.A",
      updated_by: (
        <div style={{ fontSize: "12px" }}>
          {item?.modified_by?.first_name && item?.modified_by?.last_name
            ? `${item?.modified_by?.first_name} ${item?.modified_by?.last_name}`
            : "N.A"}{" "}
        </div>
      ),
      area_covered: (
        <div style={{ fontSize: "12px" }}>
          {item?.pdrl_data.hasOwnProperty("total_flight_area") &&
          item?.pdrl_data["total_flight_area"]
            ? convertToAcres(
                item?.pdrl_data["total_flight_area"].toFixed(2)
              ).toFixed(2)
            : "N.A"}
        </div>
      ),
      time_covered: (
        <div style={{ fontSize: "12px" }}>
          {item?.pdrl_data.hasOwnProperty("total_flight_time") &&
          item?.pdrl_data["total_flight_time"]
            ? convertToHrsMin(item?.pdrl_data["total_flight_time"].toFixed(2))
            : "N.A"}
        </div>
      ),
      drone_status: (
        <span
          style={{
            padding: "5px",
            borderRadius: "50px",
            ...droneStatusStyles,
            minWidth: "100%",
            font: "bold",
            display: "inline-block",
            width: "100px",
            fontSize: "12px",
          }}
        >
          {getStatusText(item?.status)}
        </span>
      ),

      assign: (
        <Button
          variant="contained"
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleOpenAssignPilot(item?.id);
          }}
        >
          Assign
        </Button>
      ),
      is_available: (
        <img
          src={
            item?.drone?.available_for_sale ? droneAvailable : droneNotAvailable
          }
        />
      ),
      actions: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "10px",
            justifyContent: "center",
          }}
        >
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleEditDrone(item)}
          >
            <img src={editIcon} />
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleOpenDeleteModal(item?.id)}
          >
            <img src={deleteIcon} />
          </span>
        </div>
      ),
    };
  });
  return modifiedData;
};

export const Validate = (value) => {
  return new Promise((resolve, reject) => {
    const {
      id,
      drone_id,
      // stationed_at_id,
      registration_date,
      serial_no,
      status,
      drone_name,
      application,
    } = value;

    const errors = { error: false };

    if (!registration_date) {
      errors.registration_date = "Enter registration_date";
      errors.error = true;
    }

    if (!drone_id || Object.keys(drone_id).length === 0) {
      errors.drone_id = "Drone is required";
      errors.error = true;
    }

    // if (!pilot_id || Object.keys(pilot_id).length === 0) {
    //   errors.pilot_id = "Pilot is required";
    //   errors.error = true;
    // }

    // if (!stationed_at_id || Object.keys(stationed_at_id).length === 0) {
    //   errors.stationed_at_id = "Location is required";
    //   errors.error = true;
    // }

    // if (!selectedBlock || !selectedBlock[0]) {
    //   errors.selectedBlock = "Please select block";
    //   errors.error = true;
    // }

    if (!serial_no) {
      errors.serial_no = "Serial no is required";
      errors.error = true;
    }
    if (!drone_name) {
      errors.drone_name = "Enter drone name";
      errors.error = true;
    }

    if (!status || !status[0]) {
      errors.status = "Drone status is required";
      errors.error = true;
    }

    if (!application || !application[0]) {
      errors.application = "Please select application";
      errors.error = true;
    }

    // if (!pilotSelected || Object.keys(pilotSelected).length === 0) {
    //   errors.pilotSelected = "Pilot is required";
    //   errors.error = true;
    // }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};

export const formatDroneBatteryList = (
  data,
  handleEditDrone,
  handleDeleteDrone
) => {
  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Working";
      case 2:
        return "Sold";
      case 3:
        return "Damaged";
      case 4:
        return "Under Repair";
      default:
        return "Unknown";
    }
  };

  const getStatus = (input) => {
    if (input) {
      return "Active";
    } else {
      return "InActive";
    }
  };

  const modifiedData = data?.map((item) => {
    const {
      id,
      certificate,
      battery: { name, power, quantity },
      stationed_at: { city },
      serial_no,
      registration_date,
      status,
      modified_by: { first_name, last_name },
      modified,
      battery: {
        drone: { title },
        image: batteryImg,
      },
    } = item;

    return {
      id: <p style={{ fontSize: "12px" }}>{id}</p>,
      name: <p style={{ fontSize: "12px" }}>{name}</p>,
      serial_no: <p style={{ fontSize: "12px" }}>{serial_no}</p>,
      registered_date: (
        <p style={{ fontSize: "12px" }}>
          {(registration_date && helperdayMonthYearFull(registration_date)) ||
            "N.A"}
        </p>
      ),
      location: <p style={{ fontSize: "12px" }}>{city || "N.A"}</p>,
      updated_on: (
        <p style={{ fontSize: "12px" }}>
          {(modified && helperdayMonthYearFull(modified)) || "N.A"}
        </p>
      ),
      updated_by: (
        <div style={{ fontSize: "12px" }}>
          {first_name && last_name ? `${first_name} ${last_name}` : "NA"}{" "}
        </div>
      ),
      power: <p style={{ fontSize: "12px" }}>{power || "NA"}</p>,
      quantity: <p style={{ fontSize: "12px" }}>{quantity || "Na"}</p>,
      drone: (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div>
            <img src={batteryImg} style={{ width: "56px", height: "56px" }} />
          </div>
          <p style={{ fontSize: "12px" }}> {title}</p>
        </div>
      ),

      status: (
        <span
          style={{
            backgroundColor: status
              ? "rgba(198, 239, 205, 1)"
              : "rgba(255, 170, 170, 1",
            color: status ? "rgba(3, 98, 2, 1)" : "rgba(119, 0, 0, 1)",
            padding: "8px",
            borderRadius: "15px",
            display: "inline-block",
            width: "100px",
            fontSize: "12px",
          }}
        >
          {getStatus(status)}
        </span>
      ),
      actions: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "10px",
            justifyContent: "center",
          }}
        >
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleEditDrone(item)}
          >
            <img src={editIcon} />
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleDeleteDrone(item?.id)}
          >
            <img src={deleteIcon} />
          </span>
        </div>
      ),
    };
  });
  return modifiedData;
};

export const ValidateDrones = (value) => {
  return new Promise((resolve, reject) => {
    const {
      battery_id,
      // registration_date,
      serial_no,
      // stationed_at_id,
      status,
      drone_id,
    } = value;

    const errors = { error: false };

    // if (!registration_date) {
    //   errors.registration_date = "Enter valid registration date";
    //   errors.error = true;
    // }

    if (!battery_id || battery_id.length === 0) {
      errors.battery_id = "Battery is required";
      errors.error = true;
    }
    if (!drone_id || Object.keys(drone_id).length === 0) {
      errors.drone_id = "Drone Inventory is required";
      errors.error = true;
    }

    // if (!stationed_at_id || stationed_at_id.length === 0) {
    //   errors.stationed_at_id = "Location is required";
    //   errors.error = true;
    // }

    if (!serial_no) {
      errors.serial_no = "Serial no is required";
      errors.error = true;
    }

    if (!status || status.length === 0) {
      errors.status = "Inventory status is required";
      errors.error = true;
    }

    if (errors.error) {
      reject(errors);
    } else {
      resolve("success");
    }
  });
};

function convertToHrsMin(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "0hrs";
  var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "0mins";
  // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay;
}

function convertToAcres(_value) {
  return _value * 0.000247105;
}
