import React, { useEffect, useState } from "react";
// import { Scheduler } from "@aldabil/react-scheduler";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from "react-calendar-timeline";
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css";
import "./style.css";
import moment from "moment";
import DataConvertHelper from "./DataConvertHelper";
import CustomSelectChip from "../CustomSelectChip/CustomSelectChip";
import Container from "../Container/Container";
import { token_api } from "../../Utils/network";
import { RoomsAndBookings } from "./utility";
import singleRm from "../../Assets/singleBed.svg";
// import doubleRm from "../../Assets/doubleBed.svg";
import doubleRm from "../../Assets/beds.svg";
import SearchAndDropdown from "../SearchAndDropdown/SearchAndDropdown";
import CustomPagination from "../CustomPagination/CustomPagination";
import { useNavigate } from "react-router-dom";

// const groups = [
//   { id: 1, title: "Room 1" },
//   { id: 2, title: "Room 2" },
// ];

const items = [
  {
    id: 1,
    group: 1,
    title: "Yogin Patil",
    start_time: moment("2023-12-27 18:30:00"),
    end_time: moment("2023-12-29 20:30:00"),
  },
  {
    id: 2,
    group: 2,
    title: "PremChand Lohar",
    start_time: moment("2023-12-22 18:30:00"),
    end_time: moment("2023-12-24 20:30:00"),
  },
  {
    id: 3,
    group: 1,
    title: "Vaibhav Gupta",
    start_time: moment("2023-12-15 18:30:00"),
    end_time: moment("2023-12-18 20:30:00"),
  },
];

const OpenCalendar = (props) => {
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [roomsListing, setRoomsListing] = useState([]);
  const [roomBookingDetails, setRoomBookingDetails] = useState([]);
  const [accForm, setAccForm] = useState({});
  const [locationList, setLocationList] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  const [roomBook, setRoomBook] = useState([]);
  //   const [items, setItems] = useState(itemsR);
  const [typedValue, setTypedValue] = useState("");
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [isLoading, setIsLoading] = useState(true);
  //   const [selectedGroups, updateSelectedGroups] = useState(groups);

  useEffect(() => {
    // props?.dataComponent
    //   ?.getData()
    //   .then((response) => {
    //     setGroups(
    //       DataConvertHelper.convertTrucksToTimelineGroups(response.trucks)
    //     );
    //     setItems(
    //       DataConvertHelper.convertOrdersToTimelineItems(
    //         response.orders,
    //         response.trucks
    //       )
    //     );
    //     setIsLoading(false);
    //   })
    //   .catch((error) => console.log(error));
  }, [props?.dataComponent]);

  //   const handleInputChange = (_event, newInput) => {
  //     updateSelectedGroups(newInput);
  //   };

  const mapTruckNames = () => {
    // return groups.map((group: TimelineGroup) => group.title);
  };

  //   const getGroupsToShow = () => {
  //     return selectedGroups.length
  //       ? groups.filter((group) => selectedGroups.includes(group.title))
  //       : groups;
  //   };

  const handleDeleteChip = (id, type) => {
    setAccForm({
      ...accForm,
      [type]: accForm[type]?.filter((item) => item?.id !== id),
    });
  };

  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setAccForm({
        ...accForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
      return;
    } else if (type === "select" && selectName && !multiple) {
      setAccForm({
        ...accForm,
        [selectName]: [e],
      });
    }
  };
  const getLocationList = (value) => {
    let apiEndpoint = "/course/location";

    if (value) {
      apiEndpoint += `?q=${value}`;
    }

    token_api
      .get(apiEndpoint)
      .then((res) => {
        if (res.data.data) {
          let userloc = res.data.data?.map((info) => {
            return {
              id: info?.id,
              title: `${info?.line_1}, ${info?.line_2}, ${info?.area}, ${info?.city_new}, ${info?.state}, ${info?.country}`,
            };
          });
          setLocationList(userloc);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleNavigate = (item) => [
    navigate(
      `/bookings/course-booking-details/${item?.course_booking_detail[0]?.course_booking_id}`
    ),
  ];

  useEffect(() => {
    if (
      Array.isArray(accForm?.accommodation) &&
      accForm?.accommodation?.length > 0
    ) {
      const { accommodation } = accForm;
      const roomBookingService = new RoomsAndBookings(
        accommodation[0]?.id,
        pageState,
        handleNavigate
      );
      roomBookingService
        .getRoomAndBookingsData()
        .then((data) => {
          const { roomData, modifiedBookings, paginatedData } = data;


          const modifiedRoomData = roomData?.map((item) => {
            let roomComponent = (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "5px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <span>{item?.title}</span>

                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {
                      <img
                        src={item?.capacity == 1 ? singleRm : doubleRm}
                        height={35}
                        alt="capacity_icon"
                      />
                    }
                  </span>
                  <br />
                  {/* <span>{`Floor: ${item?.floor_no}`}</span> */}
                </div>
              </div>
            );
            return {
              id: item?.id,
              title: roomComponent,
            };
          });
          setPaginationData(paginatedData);

          setRoomsListing(modifiedRoomData);
          setRoomBookingDetails(modifiedBookings);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setRoomsListing([]);
      setRoomBookingDetails([]);
    }
  }, [accForm?.accommodation, pageState]);


  useEffect(() => {
    if (accForm?.location && accForm?.location?.id) {
      const extractCity = accForm?.location?.id;
      // console.log("YUYUYU", extractCity[0]);
      const getHotelList = async (city) => {
        try {
          const fetchHotels = await token_api.get(
            `course/hotels?page_size=300&location=${city}`
          );

          if (fetchHotels?.status !== 200) {
            throw new Error("HOTEL FETCH ERROR");
          }

          const modifiedList = fetchHotels?.data?.data?.map((item) => {
            return {
              id: item?.id,
              title: item?.hotel_name,
            };
          });

          setHotelList(modifiedList);
        } catch (error) {
          console.log("HotelError", error);
        }
      };

      getHotelList(extractCity);
    }
  }, [accForm?.location]);

  useEffect(() => {
    let timer;

    if (typedValue) {
      timer = setTimeout(() => {
        getLocationList(typedValue);
      }, 500);
    } else {
      getLocationList();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [typedValue]);

  const handleChangeforSearch = (event, newValue) => {
    setTypedValue(newValue);
    setAccForm((prev) => ({
      ...prev,
      location: newValue,
    }));
  };

  return (
    <>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
            marginTop: "15px",
            width: "50%",
          }}
        >
          {/* <CustomSelectChip
            multiple={false}
            label="Location"
            name="location"
            onChange={handleChange}
            onDelete={handleDeleteChip}
            value={accForm?.location}
            listArray={locationList}
          /> */}

          <SearchAndDropdown
            label="Filter By Location"
            value={accForm?.location}
            onChange={handleChangeforSearch}
            listing={locationList}
            typedValue={typedValue}
            setTypedValue={setTypedValue}
          />

          <CustomSelectChip
            disabled={accForm?.location ? false : true}
            multiple={false}
            label="Hotel"
            name="accommodation"
            onChange={handleChange}
            onDelete={handleDeleteChip}
            value={accForm?.accommodation}
            listArray={hotelList}
          />
        </div>

        {roomList.length > 0 && roomBook.length > 0 && (
          <div id="helper-text">
            <p>To zoom in and out, use CTRL + scroll</p>
          </div>
        )}

        {!accForm.accommodation && (
          <div id="helper-text">
            <p>Please select hotel.</p>
          </div>
        )}
        {!accForm?.location && (
          <div id="helper-text">
            <p>Please select location.</p>
          </div>
        )}

        {roomsListing?.length > 0 && roomBookingDetails?.length > 0 && (
          <Timeline
            groups={roomsListing}
            items={roomBookingDetails}
            defaultTimeStart={moment(new Date()).add(-1, "month")}
            defaultTimeEnd={moment(new Date()).add(1.5, "month")}
            maxZoom={1.5 * 365.24 * 86400 * 1000}
            minZoom={1.24 * 86400 * 1000 * 7 * 3}
            fullUpdate
            lineHeight={100}
            // sidebarContent="Rooms"
            // traditionalZoom={true} -> this does not work :()
            timeSteps={{
              minute: 5,
              hour: 1,
              day: 1,
              month: 1,
              year: 1,
            }}
            // maxZoom={30 * 86400 * 1000}
          >
            <TimelineHeaders>
              <SidebarHeader>
                {({ getRootProps }) => {
                  return (
                    <div {...getRootProps()} className="customSideHeader">
                      Rooms
                    </div>
                  );
                }}
              </SidebarHeader>
              <DateHeader unit="primaryHeader" className="customMainHeader" />
              <DateHeader />
            </TimelineHeaders>
          </Timeline>
        )}

        {roomsListing?.length > 0 && roomBookingDetails?.length > 0 && (
          <div style={{ marginTop: "25px", marginLeft: "auto" }}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        )}
      </Container>
    </>
  );
};

export default OpenCalendar;

const DummyRooms = [
  { id: 1, title: "Room 1" },
  { id: 2, title: "Room 2" },
  { id: 3, title: "Room 3" },
  { id: 4, title: "Room 4" },
];

const apiRoomList = [
  {
    title: "Room-101",
    id: 1,
    color: "#ea7a57",
    capacity: 1,
    type: "Single Occupancy",
    bookingDetails: [
      {
        id: 1,
        title: "Premchand Lohar",
        description: "Meeting to discuss business goal of 2021.",
        start_ime: "2023-12-10 18:30:00",
        end_time: "2023-12-12 20:30:00",
        room_id: 1,
      },
      {
        id: 2,
        title: "Yogin Patil",
        description: "Meeting to discuss business goal of 2021.",
        start_ime: "2023-12-19 18:30:00",
        end_time: "2023-12-20 20:30:00",
        room_id: 1,
      },
    ],
  },
  {
    title: "Room-202",
    id: 2,
    color: "#ea7a57",
    capacity: 1,
    type: "Single Occupancy",
    bookingDetails: [
      {
        id: 1,
        title: "Premchand Lohar",
        description: "Meeting to discuss business goal of 2021.",
        start_ime: "2023-12-14 18:30:00",
        end_time: "2023-12-16 20:30:00",
        group: 2,
      },
      {
        id: 2,
        title: "Yogin Patil",
        description: "Meeting to discuss business goal of 2021.",
        start_ime: "2023-12-22 18:30:00",
        end_time: "2023-12-24 20:30:00",
        room_id: 2,
      },
    ],
  },
];

let response = [
  {
    id: 1,
    title: "Premchand Lohar",
    description: "Meeting to discuss business goal of 2021.",
    start_ime: "2023-12-14 18:30:00",
    end_time: "2023-12-16 20:30:00",
    group: 1, //mapping to room id
  },
];
