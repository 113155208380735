import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "./ServiceProducts.module.css";
import Container from "../../Component/Container/Container";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import { Button } from "@mui/material";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import editIcon from "../../Assets/edit_icon.svg";
import deleteIcon from "../../Assets/delete_icon.svg";
import dummyBrand from "../../Assets/dummyBrandIcon.svg";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import InputFields from "../../Component/InputFields/InputFields";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import { token_api, base_url } from "../../Utils/network";
import { ValidateProduct, ValidateBrand, ValidateCategory } from "./Utility";
import axios from "axios";
import { useSelector } from "react-redux";
import dummyProduct from "../../Assets/dummyProductIcon.svg";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import CustomModal from "../../Component/CustomModal/CustomModal";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ServiceProducts = () => {
  const { access_token } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const { user_permissions } = useContext(UserCredsContext);
  const [value, setTabValue] = useState(0);
  const [filters, setfilters] = useState({});
  const [productsData, setProductsData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [addTabValue, setAddTabValue] = useState(0);
  const [modalState, setModalState] = useState(1);
  const [openDailog, setOpenDailog] = useState(false);
  const [deletedProduct, setDeletedProduct] = useState(null);
  const [deletedBrand, setDeletedBrand] = useState(null);
  const [deletedCategory, setDeleteCategory] = useState(null);

  //Add Brand Data...

  const [brandForm, setBrandForm] = useState({
    id: null,
    brand_name: "",
  });
  const [brandFormErrors, setBrandFormErrors] = useState({});
  const brandIconInput = useRef(null);
  const [brandIconObj, setBrandIconObj] = useState("");
  const [brandIconPreview, setBrandIconPreview] = useState("");

  //Add Category Data....

  const [categoryForm, setCategoryForm] = useState({
    id: null,
    name: "",
    parent_category: "",
  });
  const [categoryFormErrors, setCategoryFormErrors] = useState({});

  //Add Product Data...
  const [productForm, setProductForm] = useState({
    id: null,
    product_name: "",
    brand: [],
    description: "",
    category: [],
    mrp: "",
    selling_price: "",
    gst: "",
    zoho_id: "",
  });
  const [productFormErrors, setProductFormErrors] = useState({});
  const productIconInput = useRef(null);
  const [productIconObj, setProductIconObj] = useState("");
  const [productIconPreview, setProductIconPreview] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setProductBrandList] = useState([]);
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Products & Brands</div>,
  ];

  //Image Uploading....

  function handleDrop(e, type) {
    e.preventDefault();

    if (type === "brand") {
      if (e?.target?.files) {
        setBrandIconObj(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setBrandIconPreview(imageUrl);
      } else if (e?.dataTransfer?.files) {
        setBrandIconObj(e?.dataTransfer?.files[0]);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setBrandIconPreview(imageUrl);
      }
    } else if (type === "product") {
      if (e?.target?.files) {
        setProductIconObj(e?.target?.files[0]);
        const imageUrl = URL.createObjectURL(e.target.files[0]);
        setProductIconPreview(imageUrl);
      } else if (e?.dataTransfer?.files) {
        setProductIconObj(e?.dataTransfer?.files[0]);
        const imageUrl = URL.createObjectURL(e.dataTransfer.files[0]);
        setProductIconPreview(imageUrl);
      }
    }
  }

  const handleClick = (type) => {
    if (type === "brand") {
      brandIconInput.current.click();
    } else if (type === "product") {
      productIconInput.current.click();
    }
  };
  function handleDragOver(e) {
    e.preventDefault();
  }

  //Tab Changing....
  const handleTabChange = (event, newValue) => {
    setPageState(1);
    setTabValue(newValue);
  };

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  //PRODUCTS CRUD OPERATIONS.....
  const getProductsList = async (filters) => {
    try {
      const fetchList = await token_api.get(`service/booking/products`, {
        params: { ...filters },
        paramsSerializer: {
          indexes: null,
        },
      });
      if (fetchList?.status !== 200) {
        throw new Error("Network Error");
      }
      setPaginationData(fetchList?.data);

      let modifiedArray = fetchList?.data?.data?.map((item) => {
        return {
          id: <p style={{ fontSize: "12px" }}>{item?.id}</p>,

          product_name: (
            <p style={{ fontSize: "12px" }}>{item?.product_name || "N.A"}</p>
          ),
          thumbanail: (
            <img
              src={item?.thumbnail ? item?.thumbnail : dummyProduct}
              alt="product_icon"
              style={{ width: "80px", height: "80px" }}
            />
          ),
          brand: (
            <p style={{ fontSize: "12px" }}>{item?.brand?.name || "N.A"}</p>
          ),
          description: (
            <p style={{ fontSize: "12px" }}>{item?.description || "N.A"}</p>
          ),
          category: (
            <p style={{ fontSize: "12px" }}>{item?.category?.name || "N.A"}</p>
          ),
          selling_price: (
            <p style={{ fontSize: "12px" }}>
              {item?.selling_price ? `Rs. ${item?.selling_price}` : "N.A"}
            </p>
          ),

          action: (
            <div className={styles.actionContainer}>
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleEditProduct(item)}
                >
                  <img src={editIcon} />
                </div>
              )}
              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteProductModal(item?.id)}
                >
                  <img src={deleteIcon} />
                </div>
              )}
            </div>
          ),
        };
      });
      setProductsData([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditProduct = (item) => {
    console.log(brandList, categoryList, "hariCategoryData");

    console.log(item, brandList, categoryList, "hariEditedData");
    const {
      id,
      product_name,
      brand,
      category,
      mrp,
      selling_price,
      zoho_books_id,
      description,
      gst_percent,
      thumbnail,
    } = item;
    let brandItem = brand ? [{ id: brand?.id, title: brand?.name }] : []; //brandList.filter((item) => item?.id === brand?.id);
    let categoryItem = category
      ? [{ id: category?.id, title: category?.name }]
      : []; //categoryList.filter((item) => item?.id === category?.id);

    setOpenDrawer(true);
    setAddTabValue(0);
    setProductForm({
      id: id,
      product_name: product_name,
      brand: brandItem,
      category: categoryItem,
      mrp: mrp,
      selling_price: selling_price,
      zoho_id: zoho_books_id,
      description: description,
      gst: gst_percent,
    });
    setProductIconPreview(thumbnail);
    setProductFormErrors({});
  };

  const handleDeleteProduct = () => {
    token_api
      .delete(`service/booking/products/${deletedProduct}/`)
      .then((response) => {
        if (
          response?.status === 201 ||
          response?.status === 200 ||
          response?.status === 204
        ) {
          alert(`Product deleted successfully!`);
          setDeletedProduct(null);
          setOpenDailog(false);
          setModalState(1);
          getProductsList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteProductModal = (productId) => {
    setDeletedProduct(productId);
    setOpenDailog(true);
    setModalState(1);
  };

  //BRANDS CRUD OPERATIONS.....
  const getBrandsList = async (filters) => {
    try {
      const fetchList = await token_api.get(`service/booking/product_brand`, {
        params: { ...filters },
        paramsSerializer: {
          indexes: null,
        },
      });
      if (fetchList?.status !== 200) {
        throw new Error("Network Error");
      }
      setPaginationData(fetchList?.data);
      let modifiedArray = fetchList?.data?.data?.map((item) => {
        return {
          id: <p style={{ fontSize: "12px" }}>{item?.id}</p>,

          brand_name: <p style={{ fontSize: "12px" }}>{item?.name || "N.A"}</p>,
          brand_image: (
            <img
              src={item?.icon ? item?.icon : dummyBrand}
              alt="brand_image"
              style={{ width: "80px", height: "80px" }}
            />
          ),
          action: (
            <div className={styles.actionContainer}>
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => handleEditBrand(item)}
                >
                  <img src={editIcon} />
                </div>
              )}
              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteBrandModal(item?.id)}
                >
                  <img src={deleteIcon} />
                </div>
              )}
            </div>
          ),
        };
      });
      setBrandsData([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditBrand = (brandItem) => {
    const { id, name, icon } = brandItem;
    setOpenDrawer(true);
    setAddTabValue(1);
    setBrandForm({
      id: id,
      brand_name: name,
    });
    setBrandIconPreview(icon);
    setBrandFormErrors({});
  };

  const handleDeleteBrand = () => {
    token_api
      .delete(`service/booking/product_brand/${deletedBrand}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert(`Brand deleted successfully!`);
          setOpenDailog(false);
          setModalState(2);
          setDeletedBrand(null);
          getBrandsList();
        }
      });
  };

  const handleDeleteBrandModal = (brandId) => {
    setDeletedBrand(brandId);
    setOpenDailog(true);
    setModalState(2);
  };
  const getProductCategoryList = () => {
    token_api
      .get(`service/booking/product_category?page_size=100`)
      .then((res) => {
        if (res.data.data) {
          let newList = res?.data?.data.map((item) => {
            return {
              id: item?.id,
              title: item?.name,
            };
          });

          setCategoryList(newList);
        }
      })
      .catch((err) => console.log(err));
  };

  const getProductBrandsList = () => {
    token_api
      .get(`service/booking/product_brand?page_size=100`)
      .then((res) => {
        if (res.data.data) {
          let newList = res?.data?.data.map((item) => {
            return {
              id: item?.id,
              title: item?.name,
            };
          });

          setProductBrandList(newList);
        }
      })
      .catch((err) => console.log(err));
  };

  //Categories CRUD operations...

  const handleEditCategory = (item) => {
    setOpenDrawer(true);
    const { id, name } = item;
    setCategoryForm({
      id: id,
      name: name,
    });
    setAddTabValue(2);
    setCategoryFormErrors({});
  };

  const getCategoryListing = async (filters) => {
    try {
      const fetchList = await token_api.get(
        `service/booking/product_category`,
        {
          params: { ...filters },
          paramsSerializer: {
            indexes: null,
          },
        }
      );
      if (fetchList?.status !== 200) {
        throw new Error("Network Error");
      }
      setPaginationData(fetchList?.data);
      let modifiedArray = fetchList?.data?.data?.map((item) => {
        return {
          id: <p style={{ fontSize: "12px" }}>{item?.id}</p>,

          name: <p style={{ fontSize: "12px" }}>{item?.name || "N.A"}</p>,

          action: (
            <div className={styles.actionContainer}>
              {user_permissions?.update && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleEditCategory(item);
                  }}
                >
                  <img src={editIcon} />
                </div>
              )}
              {user_permissions?.delete && (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDeleteCategoryModal(item?.id)}
                >
                  <img src={deleteIcon} />
                </div>
              )}
            </div>
          ),
        };
      });
      setCategoryData([...modifiedArray]);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProductCategoryList();
    getProductBrandsList();
  }, [value, openDrawer]);

  useEffect(() => {
    if (value === 0) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};
        if (pageState) {
          filtersObject.page = pageState;
        }

        if (filters?.q) {
          filtersObject.q = filters?.q;
        }

        getProductsList(filtersObject);
      }, 500);

      return () => clearTimeout(timeOut);
    } else if (value === 1) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};
        if (pageState) {
          filtersObject.page = pageState;
        }

        if (filters?.q) {
          filtersObject.q = filters?.q;
        }

        getBrandsList(filtersObject);
      }, 500);

      return () => clearTimeout(timeOut);
    } else if (value === 2) {
      const timeOut = setTimeout(() => {
        let filtersObject = {};
        if (pageState) {
          filtersObject.page = pageState;
        }

        if (filters?.q) {
          filtersObject.q = filters?.q;
        }

        getCategoryListing(filtersObject);
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [filters?.q, value, pageState]);

  const handleDeleteCategory = () => {
    token_api
      .delete(`service/booking/product_category/${deletedCategory}/`)
      .then((response) => {
        if (
          response?.status === 201 ||
          response?.status === 200 ||
          response?.status === 204
        ) {
          alert(`Category deleted successfully!`);
          setDeleteCategory(null);
          setOpenDailog(false);
          setModalState(3);
          getCategoryListing();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteCategoryModal = (categoryId) => {
    setDeleteCategory(categoryId);
    setOpenDailog(true);
    setModalState(3);
  };
  //Products Add Drawer....

  const handleAddProduct = () => {
    setOpenDrawer(true);
    setAddTabValue(0);
    setProductFormErrors({});
    setProductForm({});
    setProductIconObj("");
    setProductIconPreview("");
  };

  const handleAddBrand = () => {
    setOpenDrawer(true);
    setAddTabValue(1);
    setBrandForm({
      id: null,
      brand_name: "",
    });
    setBrandFormErrors({});
    setBrandIconObj("");
    setBrandIconPreview("");
  };

  const handleAddCategory = () => {
    setOpenDrawer(true);
    setAddTabValue(2);
    setCategoryForm({
      id: null,
      name: "",
      parent_category: "",
    });
  };

  const handleAddTabChange = (event, newValue) => {
    setAddTabValue(newValue);
    //Brand..
    setBrandForm({
      brand_name: "",
    });
    setBrandFormErrors({});
    //Product Form...
    setProductForm({
      product_name: "",
      brand: [],
      description: "",
      category: [],
      mrp: "",
      selling_price: "",
      gst: "",
      zoho_id: "",
    });
    setProductFormErrors({});
  };

  //Add Brand.....

  const handleBrandChange = (event) => {
    const { name, value } = event.target;
    setBrandForm({
      ...brandForm,
      [name]: value,
    });
  };

  //Add Category...
  const handleCategoryChange = (event) => {
    const { name, value } = event.target;
    setCategoryForm({
      ...categoryForm,
      [name]: value,
    });
  };

  //Add Product...
  const handleProductChange = (event) => {
    const { name, value } = event.target;
    setProductForm({
      ...productForm,
      [name]: value,
    });
  };

  const handleChangeCategory = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setProductForm({
        ...productForm,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setProductForm({
        ...productForm,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setProductForm({
        ...productForm,
        [name]: value,
      });
    }
    // const { name, target } = e.target;
  };

  const handleDeleteChipCategory = (id, selectName) => {
    setProductForm({
      ...productForm,
      [selectName]: productForm?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
  };

  const handleCreateProduct = (e) => {
    e.preventDefault();
    let data = {
      product_name: productForm?.product_name,
      ...(productForm?.brand && { brand_id: productForm?.brand[0]?.id }),
      description: productForm?.description,
      ...(productForm?.category && {
        category_id: productForm?.category[0]?.id,
      }),

      mrp: productForm?.mrp,
      selling_price: productForm?.selling_price,
      gst_percent: productForm?.gst,
      zoho_books_id: productForm?.zoho_id,
    };

    ValidateProduct(productForm)
      .then(async (response) => {
        if (response == "success") {
          if (!productForm?.id) {
            const productData = await token_api.post(
              `service/booking/products/`,
              data
            );

            if (productIconObj) {
              let productIconItem = new FormData();
              productIconItem.append("thumbnail", productIconObj);

              await axios({
                method: "patch",
                url: `${base_url}service/booking/products/${productData?.data?.data?.id}/`,
                data: productIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }

            if (productData?.status === 200 || productData?.status === 201) {
              alert(`Product created successfully!`);
              setOpenDrawer(false);
              setProductForm({
                ...productForm,
                id: null,
                product_name: "",
                brand: [],
                description: "",
                category: [],
                mrp: "",
                selling_price: "",
                gst: "",
                zoho_id: "",
              });
              getProductsList();
              setProductIconObj("");
              setProductIconPreview("");
            }
          } else {
            const productData = await token_api.patch(
              `service/booking/products/${productForm?.id}/`,
              data
            );

            if (productIconObj) {
              let productIconItem = new FormData();
              productIconItem.append("thumbnail", productIconObj);

              await axios({
                method: "patch",
                url: `${base_url}service/booking/products/${productData?.data?.data?.id}/`,
                data: productIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            if (productData?.status === 200 || productData?.status === 201) {
              alert(`Product updated successfully!`);
              setOpenDrawer(false);
              setProductForm({
                ...productForm,
                id: null,
                product_name: "",
                brand: [],
                description: "",
                category: [],
                mrp: "",
                selling_price: "",
                gst: "",
                zoho_id: "",
              });
              getProductsList();
              getProductBrandsList();
              getProductCategoryList();
              setProductIconObj("");
              setProductIconPreview("");
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setProductFormErrors(error);
      });
  };

  const handleCreateBrand = (e) => {
    e.preventDefault();
    let data = {
      name: brandForm?.brand_name,
    };

    ValidateBrand(brandForm)
      .then(async (response) => {
        if (response == "success") {
          if (!brandForm?.id) {
            const brandData = await token_api.post(
              `service/booking/product_brand/`,
              data
            );

            if (brandIconObj) {
              let brandIconItem = new FormData();
              brandIconItem.append("icon", brandIconObj);

              await axios({
                method: "patch",
                url: `${base_url}service/booking/product_brand/${brandData?.data?.data?.id}/`,
                data: brandIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            if (brandData?.status === 200 || brandData?.status === 201) {
              alert(`Brand created successfully!`);
              setOpenDrawer(false);
              setBrandForm({
                ...brandForm,
                id: null,
                brand_name: "",
              });
              setTabValue(1);
              getBrandsList();
              setBrandIconObj("");
              setBrandIconPreview("");
              setBrandFormErrors({});
            }
          } else {
            const brandData = await token_api.patch(
              `service/booking/product_brand/${brandForm?.id}/`,
              data
            );

            if (brandIconObj) {
              let brandIconItem = new FormData();
              brandIconItem.append("icon", brandIconObj);

              await axios({
                method: "patch",
                url: `${base_url}service/booking/product_brand/${brandData?.data?.data?.id}/`,
                data: brandIconItem,
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${access_token}`,
                },
              });
            }
            if (brandData?.status === 200 || brandData?.status === 201) {
              alert(`Brand updated successfully!`);
              setOpenDrawer(false);
              setBrandForm({
                ...brandForm,
                id: null,
                brand_name: "",
              });
              setTabValue(1);
              getBrandsList();
              setBrandIconObj("");
              setBrandIconPreview("");
              setBrandFormErrors({});
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setBrandFormErrors(error);
      });
  };

  const handleCreateCategory = (e) => {
    e.preventDefault();
    let data = {
      name: categoryForm?.name,
    };

    ValidateCategory(categoryForm)
      .then(async (response) => {
        if (response == "success") {
          if (!categoryForm?.id) {
            const categoryData = await token_api.post(
              `service/booking/product_category/`,
              data
            );

            if (categoryData?.status === 200 || categoryData?.status === 201) {
              alert(`Category created successfully!`);
              setOpenDrawer(false);
              setCategoryForm({
                ...categoryForm,
                id: null,
                name: "",
                parent_category: "",
              });
              setTabValue(2);
              setCategoryFormErrors({});
              getCategoryListing();
            }
          } else {
            const categoryData = await token_api.patch(
              `service/booking/product_category/${categoryForm?.id}/`,
              data
            );

            if (categoryData?.status === 200 || categoryData?.status === 201) {
              alert(`Category updated successfully!`);
              setOpenDrawer(false);
              setCategoryForm({
                ...categoryForm,
                id: null,
                name: "",
                parent_category: "",
              });
              setTabValue(2);
              setCategoryFormErrors({});
              getCategoryListing();
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setCategoryFormErrors(error);
      });
  };

  const handleCloseModal = () => {
    setOpenDailog(false);
    setDeletedBrand(null);
    setDeletedProduct(null);
    setDeleteCategory(null);
    setModalState(1);
  };

  return (
    <Container>
      <div className={styles.breadCrumbsCon}>
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      </div>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Products" {...a11yProps(0)} />
          <Tab label="Brands" {...a11yProps(1)} />
          <Tab label="Categories" {...a11yProps(2)} />
        </Tabs>
      </Box>

      {value === 0 && (
        <div>
          <FilterContainer style={{ maxHeight: "40px", marginTop: "20px" }}>
            <div style={{ width: "400px" }}>
              <CustomSearchFilter
                placeholder="Search by product name..."
                onChange={(e) => {
                  setfilters({ ...filters, q: e });
                }}
              />
            </div>

            {user_permissions?.write && (
              <Button
                sx={{ height: "53px" }}
                variant="contained"
                onClick={handleAddProduct}
              >
                + Add Product
              </Button>
            )}
          </FilterContainer>
          <div className="totalCountText">
            Total Count : <span>{total_count}</span>
          </div>
          <div className={styles.tableContainer}>
            <CustomTable columns={productTableColumns} data={productsData} />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>{" "}
        </div>
      )}

      {value === 1 && (
        <div>
          <FilterContainer style={{ maxHeight: "40px", marginTop: "20px" }}>
            <div style={{ width: "400px" }}>
              <CustomSearchFilter
                placeholder="Search by brand name..."
                onChange={(e) => {
                  setfilters({ ...filters, q: e });
                }}
              />
            </div>

            {user_permissions?.write && (
              <Button
                sx={{ height: "53px" }}
                variant="contained"
                onClick={handleAddBrand}
              >
                + Add Brand
              </Button>
            )}
          </FilterContainer>
          <div className="totalCountText">
            Total Count : <span>{total_count}</span>
          </div>
          <div className={styles.tableContainer}>
            <CustomTable columns={brandTableColumns} data={brandsData} />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>{" "}
        </div>
      )}

      {value === 2 && (
        <div>
          <FilterContainer style={{ maxHeight: "40px", marginTop: "20px" }}>
            <div style={{ width: "400px" }}>
              <CustomSearchFilter
                placeholder="Search by category name..."
                onChange={(e) => {
                  setfilters({ ...filters, q: e });
                }}
              />
            </div>

            {user_permissions?.write && (
              <Button
                sx={{ height: "53px" }}
                variant="contained"
                onClick={handleAddCategory}
              >
                + Add Category
              </Button>
            )}
          </FilterContainer>
          <div className="totalCountText">
            Total Count : <span>{total_count}</span>
          </div>
          <div className={styles.tableContainer}>
            <CustomTable columns={categoryTableColumns} data={categoryData} />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>{" "}
        </div>
      )}

      <DrawerComp
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
      >
        {addTabValue === 0 && (
          <DrawerContainer>
            <DrawerTitle
              text={productForm?.id ? "Update Product" : "Add Product"}
              handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
            />

            <div style={{ marginTop: "20px", rowGap: "10px" }}>
              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>
                  Product Name<span>*</span>
                </h3>
                <InputFields
                  label="Product Name"
                  required
                  name="product_name"
                  value={productForm?.product_name || ""}
                  onChange={handleProductChange}
                  error={productFormErrors?.product_name}
                  helperText={productFormErrors?.product_name}
                />
              </div>

              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>
                  Brand Name<span>*</span>
                </h3>
                <CustomSelectChip
                  multiple={false}
                  label="Select Brand *"
                  name="brand"
                  onChange={handleChangeCategory}
                  onDelete={handleDeleteChipCategory}
                  value={productForm?.brand}
                  listArray={brandList}
                  error={productFormErrors?.brand}
                />
              </div>

              <div className={`${styles.formContainer} mt-20`}>
                <div className={`${styles.compoHead}`}>Thumbnail:</div>

                <div style={{ margin: "20px 0px" }}>
                  <div
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, "product")}
                    className={styles.dropBoxStyles}
                  >
                    <div
                      onClick={() => handleClick("product")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>Drag & Drop the File or click here</p>
                      <input
                        ref={productIconInput}
                        type="file"
                        // id="myfile"
                        // name="myfile"
                        // multiple
                        onChange={(e) => handleDrop(e, "product")}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              {productIconPreview && (
                <div className={`${styles.formContainer} mt-20`}>
                  <div className={styles.commonFieldsBox}>
                    <div>
                      <p
                        // style={{ color: palette?.primary[500] }}
                        className={`${styles.compoHead}`}
                      >
                        Tumbnail Preview
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={`${styles.flexBetween} ${styles.imgCardBox} `}
                          style={{ marginTop: "10px" }}
                        >
                          {" "}
                          <img
                            src={productIconPreview}
                            alt="icon"
                            width={200}
                            height={140}
                          />
                          <div onClick={() => handleClick("product")}>
                            <Button
                              variant="contained"
                              // onClick={() => {
                              //   handleMediaDelete("image");
                              // }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>Description:</h3>
                <textarea
                  rows={10}
                  style={{
                    padding: "20px 10px",
                    boxSizing: "border-box",
                    width: "100%",
                  }}
                  placeholder="Description"
                  name="description"
                  value={productForm?.description}
                  onChange={handleProductChange}
                />
              </div>
              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>Category:</h3>
                <CustomSelectChip
                  multiple={false}
                  label="Select Category"
                  name="category"
                  onChange={handleChangeCategory}
                  onDelete={handleDeleteChipCategory}
                  value={productForm?.category}
                  listArray={categoryList}
                  error={productFormErrors?.category}
                />
              </div>

              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>MRP:</h3>
                <InputFields
                  label="MRP"
                  type="number"
                  name="mrp"
                  value={productForm?.mrp || ""}
                  onChange={handleProductChange}
                  error={productFormErrors?.mrp}
                  helperText={productFormErrors?.mrp}
                />
              </div>

              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>Selling Price:</h3>
                <InputFields
                  label="Selling Price"
                  type="number"
                  name="selling_price"
                  value={productForm?.selling_price || ""}
                  onChange={handleProductChange}
                  error={productFormErrors?.selling_price}
                  helperText={productFormErrors?.selling_price}
                />
              </div>
              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>GST Percentage:</h3>
                <InputFields
                  label="GST Percentage"
                  name="gst"
                  value={productForm?.gst || ""}
                  onChange={handleProductChange}
                  error={productFormErrors?.gst}
                  helperText={productFormErrors?.gst}
                />
              </div>

              <div className={styles.inputCon}>
                <h3 className={styles.compoHead}>Zoho Books Id:</h3>
                <InputFields
                  label="Zoho Books ID"
                  name="zoho_id"
                  value={productForm?.zoho_id || ""}
                  onChange={handleProductChange}
                  error={productFormErrors?.zoho_id}
                  helperText={productFormErrors?.zoho_id}
                />
              </div>

              <div className={styles.buttonContainer}>
                <Button variant="contained" onClick={handleCreateProduct}>
                  Submit
                </Button>
              </div>
            </div>
          </DrawerContainer>
        )}

        {addTabValue === 1 && (
          <DrawerContainer>
            <DrawerTitle
              text={brandForm?.id ? "Update Brand" : "Add Brand"}
              handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
            />
            <div style={{ marginTop: "20px", rowGap: "10px" }}>
              <div>
                <h3 className={styles.compoHead}>
                  Brand Name<span>*</span>
                </h3>
                <InputFields
                  label="Brand Name"
                  required
                  name="brand_name"
                  value={brandForm?.brand_name || ""}
                  onChange={handleBrandChange}
                  error={brandFormErrors?.brand_name}
                  helperText={brandFormErrors?.brand_name}
                />
              </div>

              <div className={`${styles.formContainer} mt-20`}>
                <div className={`${styles.compoHead}`}>Thumbnail:</div>

                <div style={{ margin: "20px 0px" }}>
                  <div
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, "brand")}
                    className={styles.dropBoxStyles}
                  >
                    <div
                      onClick={() => handleClick("brand")}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p>{/* <img src={dragDrop} alt="dradanddrop" /> */}</p>
                      <p>Drag & Drop the File or click here</p>
                      <input
                        ref={brandIconInput}
                        type="file"
                        // id="myfile"
                        // name="myfile"
                        // multiple
                        onChange={(e) => handleDrop(e, "brand")}
                        style={{ display: "none" }}
                      ></input>
                    </div>
                  </div>
                </div>
              </div>

              {brandIconPreview && (
                <div className={`${styles.formContainer} mt-20`}>
                  <div className={styles.commonFieldsBox}>
                    <div>
                      <p
                        // style={{ color: palette?.primary[500] }}
                        className={`${styles.compoHead}`}
                      >
                        Thumbnail Preview
                      </p>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <div
                          className={`${styles.flexBetween} ${styles.imgCardBox} `}
                          style={{ marginTop: "10px" }}
                        >
                          {" "}
                          <img
                            src={brandIconPreview}
                            alt="icon"
                            width={200}
                            height={140}
                          />
                          <div onClick={() => handleClick("brand")}>
                            <Button
                              variant="contained"
                              // onClick={() => {
                              //   handleMediaDelete("image");
                              // }}
                            >
                              Update
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className={styles.buttonContainer}>
                <Button variant="contained" onClick={handleCreateBrand}>
                  Submit
                </Button>
              </div>
            </div>
          </DrawerContainer>
        )}

        {addTabValue === 2 && (
          <DrawerContainer>
            <DrawerTitle
              text={categoryForm?.id ? "Update Category" : "Add Category"}
              handleCancelDrw={() => setOpenDrawer((prev) => !prev)}
            />
            <div style={{ marginTop: "20px", rowGap: "10px" }}>
              <div>
                <h3 className={styles.compoHead}>
                  Category Name<span>*</span>
                </h3>
                <InputFields
                  label="Category Name"
                  required
                  name="name"
                  value={categoryForm?.name || ""}
                  onChange={handleCategoryChange}
                  error={categoryFormErrors?.name}
                  helperText={categoryFormErrors?.name}
                />
              </div>

              <div className={styles.buttonContainer}>
                <Button variant="contained" onClick={handleCreateCategory}>
                  Submit
                </Button>
              </div>
            </div>
          </DrawerContainer>
        )}
      </DrawerComp>

      <CustomModal open={openDailog} onClose={handleCloseModal} height="300px">
        {modalState === 1 && (
          <div className="alertBox">
            <h3>Are you sure you want to delete the product?</h3>
            <div className="alertBtnCon">
              <Button onClick={handleDeleteProduct} variant="contained">
                Yes
              </Button>
              <Button onClick={handleCloseModal} variant="contained">
                No
              </Button>
            </div>
          </div>
        )}

        {modalState === 2 && (
          <div className="alertBox">
            <h3>Are you sure you want to delete the brand?</h3>
            <div className="alertBtnCon">
              <Button onClick={handleDeleteBrand} variant="contained">
                Yes
              </Button>
              <Button onClick={handleCloseModal} variant="contained">
                No
              </Button>
            </div>
          </div>
        )}

        {modalState === 3 && (
          <div className="alertBox">
            <h3>Are you sure you want to delete the category?</h3>
            <div className="alertBtnCon">
              <Button onClick={handleDeleteCategory} variant="contained">
                Yes
              </Button>
              <Button onClick={handleCloseModal} variant="contained">
                No
              </Button>
            </div>
          </div>
        )}
      </CustomModal>
    </Container>
  );
};

export default ServiceProducts;

const productTableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "product_name",
    label: "Product Name",
  },
  {
    id: "thumbanail",
    label: "Icon",
  },
  {
    id: "brand",
    label: "Brand",
  },
  {
    id: "description",
    label: "Description",
  },
  {
    id: "category",
    label: "Category",
  },
  {
    id: "selling_price",
    label: "Selling Price",
  },
  {
    id: "action",
    label: "Actions",
  },
];

const brandTableColumns = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "brand_image",
    label: "Brand Image",
  },
  {
    id: "brand_name",
    label: "Brand Name",
  },

  {
    id: "action",
    label: "Actions",
  },
];

const categoryTableColumns = [
  {
    id: "id",
    label: "ID",
  },

  {
    id: "name",
    label: "Category Name",
  },

  {
    id: "action",
    label: "Actions",
  },
];
