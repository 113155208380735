import React, { useEffect, useState } from "react";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import styles from "../../Pages/Services/services.module.css";
import InputFields from "../../Component/InputFields/InputFields";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import TextEditor from "../../Component/TextEditor/TextEditor";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import { Button } from "@mui/material";
import CustomButton from "../../Component/CustomButton/CustomButton";
import DragAndDrop from "../../Component/DragAndDrop/DragAndDrop";
import IosSwitch from "../../Component/IosSwitch/IosSwitch";
import { useDispatch, useSelector } from "react-redux";
import { getServiceBaseApi } from "../../state/actions/serviceActions";

const breadcrumbs = [
  <div className="orange-1 bread">Home</div>,
  <div className="orange-1 bread">Services</div>,
  <div className="orange-1 bread">Add-Service</div>,
];

const basicService = {};

const ServiceForm = () => {
  const dispatch = useDispatch();
  const allServiceState = useSelector((state) => state.service);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [bannerState, setBannerState] = useState([]);
  const [enblAutoPrice, setEnblAutoPrice] = useState(true);
  const [addServices, setAddServices] = useState({
    title: "",
    service_id: "",
    category_id: "",
    service: "",
    drone: [],
    description: "",
  });

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddServices({ ...addServices, [name]: value });
  };

  const handleAddBanners = () => {
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setBannerState([]);
  };

  const AddBannersComponent = () => {
    return (
      <DrawerContainer>
        <DrawerTitle text={`Add Banners`} handleCancelDrw={handleCloseDrawer} />
        {/* <div>Drag And Drop Component</div> */}
        <DragAndDrop
          bannerState={bannerState}
          setBannerState={setBannerState}
        />
        <div className={`mt-20`}>
          {bannerState.length === 0 ? (
            "No banners added."
          ) : (
            <>
              {bannerState.map((item) => {
                return (
                  <img src={item} alt="bannerImage" width={470} height={200} />
                );
              })}
            </>
          )}
          No banners added.
        </div>
        <div
          className={`mt-20`}
          style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}
        >
          <Button variant="contained">Add</Button>
          <Button variant="contained">Cancel</Button>
        </div>
      </DrawerContainer>
    );
  };

  useEffect(() => {
    dispatch(getServiceBaseApi(null, "category"));
  }, []);

  return (
    <Container>
      <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
      <div className={`${styles.formContainer} mt-20`}>
        <div className={styles.commonFieldsBox}>
          <InputFields
            label="Service Id"
            required
            name="service_id"
            value={"SP100"}
            disabled
            onChange={handleChange}
          />
          <CustomSelect
            multiple="false"
            label="Category"
            name="category_id"
            // value={bookService?.service}
            listArray={[]}
            onChange={handleChange}
          />
          <InputFields
            label="Service Name"
            required
            name="title"
            value={addServices?.title}
            onChange={handleChange}
          />
          <CustomSelect
            multiple="false"
            label="Drones"
            name="drone"
            value={addServices?.drone}
            listArray={[]}
            onChange={handleChange}
          />

          <div>
            <TextEditor />
          </div>

          <InputFields
            label="Description"
            name="description"
            value={addServices?.description}
            onChange={handleChange}
          />

          {/* banners code */}
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
            >
              <p>Banners</p>
              <p onClick={handleAddBanners}>Edit</p>
            </div>

            <div>No Banners Added</div>
          </div>
          {/* enable auto pricing */}
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p>Enable Auto Pricing</p>

              <div style={{ marginLeft: "25px" }}>
                <IosSwitch
                  checked={enblAutoPrice}
                  switchState={setEnblAutoPrice}
                />
              </div>
            </div>

            {!enblAutoPrice && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "10px",
                }}
              >
                <InputFields
                  label="Base Price"
                  name="price"
                  value={addServices?.price}
                  onChange={handleChange}
                />
                <InputFields
                  label="Surcharge"
                  name="surcharge"
                  value={addServices?.surcharge}
                  onChange={handleChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
        <Button variant="contained">Add Service</Button>
        <Button variant="contained">Cancel</Button>
      </div>
      <DrawerComp
        anchor={"right"}
        open={openDrawer}
        onClose={handleCloseDrawer}
      >
        <AddBannersComponent />
      </DrawerComp>
    </Container>
  );
};

export default ServiceForm;
