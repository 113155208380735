import React, { useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Login from "../Authentication/Login";
import MainLayout from "../MainLayout/MainLayout";
import ErrorPage from "../Pages/ErrorPage/ErrorPage";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Services from "../Pages/Services/Services";
import Drones from "../Pages/Drones/Drones";
import ServiceReport from "../Pages/ServiceReport.js/ServiceReport";
import { UserCredsContext } from "../ContextApi/UserCredsContext/UserCredsContext";
import { useContext } from "react";
import { useSelector } from "react-redux";
import ServiceForm from "../Pages/Services/ServiceForm";
import Quotation from "../Pages/Quotation/Quotation";
import { saveToLocal } from "../Utils/helper";
import ServiceOrder from "../Pages/ServiceOrder/ServiceOrder";
import ServiceBookingDetails from "../Pages/ServiceBookingDetails/ServiceBookingDetails";
import DroneMaster from "../Pages/DroneMaster/DroneMaster";
import DroneBookings from "../Pages/DroneBookings/DroneBookings";
import CustomCalendar from "./CustomCalendar/CustomCalendar";
import CoursesList from "../Pages/Courses/CoursesList";
import CreateCourse from "../Pages/Courses/CreateCourse";
import PilotList from "../Pages/PilotModule/PilotList";
import PilotSlotList from "../Pages/PilotSlotList/PilotSlotList";
import SystemUsersList from "../Pages/UsersModule/SystemUsers/SystemUsersList";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import CourseBookings from "../Pages/CourseBookings/CourseBookings";
import AccommodationCalendar from "../Pages/AccommodationCalendar/AccommodationCalendar";
import Profile from "../Pages/Profile/Profile";
import DDUsers from "../Pages/DDUsers/DDUsers";
import UserProfile from "../Pages/DDUsers/UserProfile";
import SlotMaster from "../Pages/SlotMaster/SlotMaster";
import DroneInventory from "../Pages/DroneInventory/DroneInventory";
import CourseBookDetail from "../Pages/CourseBookDetail/CourseBookDetail";
import AddCourseBooking from "../Pages/AddCourseBooking/AddCourseBooking";
import Banners from "../Pages/Banners/Banners";
import CreateServiceBooking from "../Pages/CreateServiceBooking/CreateServiceBooking";
import DashBoard from "../Pages/Dashboard/DashBoard";
import Attendance from "../Pages/Attendance/Attendance";
import Hotel from "../Pages/Hotel/Hotel";
import Organization from "../Pages/Organization/Organization";
import HotelDetails from "../Pages/HotelDetails/HotelDetails";
import OrganizationDetails from "../Pages/OrganizationDetails/OrganizationDetails";
import Locations from "../Pages/Locations/Locations";
import Instructors from "../Pages/Instructors/Instructors";
import Review from "../Pages/Review/Review";
import ServicesList from "../Pages/ServicesList/ServicesList";
import CreateService from "../Pages/CreateService/CreateService";
import ActivityReportList from "../Pages/ActivityReportList/ActivityReportList";
import PilotMaps from "../Pages/PilotMaps/PilotMaps";
import Testing from "../Pages/Testing/Testing";
import PilotProfile from "../Pages/PilotProfile/PilotProfile";
import Coupons from "../Pages/Coupons/Coupons";
import CreateCoupon from "../Pages/CreateCoupon/CreateCoupon";
import CustomCalendar1 from "./CustomerCalendar1/CustomCalendar1";
import DraftBookings from "../Pages/DraftBookings/DraftBookings";
import ServiceProducts from "../Pages/ServiceProducts/ServiceProducts";
import Dealers from "../Pages/Dealers/Dealers";
import DealerDetails from "../Pages/DealerDetails/DealerDetails";
import PilotAttendance from "../Pages/PilotAttendance/PilotAttendance";
import DroneHubBookingDetails from "../Pages/DroneHubBookingDetails/DroneHubBookingDetails";
import CreateDroneBooking from "../Pages/CreateDroneBooking/CreateDroneBooking";

function AppRouter() {
  const { theme } = useContext(UserCredsContext);
  const isAuth = useSelector((state) => state.auth.access_token);
  // const currentLocation = window.location.href.split("/");
  useEffect(() => {
    saveToLocal("activeTab", "services");
  }, []);

  return (
    <div>
      {/* <BrowserRouter> */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <Navbar /> */}
        <Routes>
          <Route
            path="/"
            element={
              // isAuth ? <Navigate to="/bookings/service-bookings" /> : <Login />   //Commented for after entering, it should take user to dashboard
              isAuth ? <Navigate to="/dashboard" /> : <Login />
            }
          />
          <Route
            path="/login"
            element={
              // isAuth ? <Navigate to="/bookings/service-bookings" /> : <Login />   //Commented for after entering, it should take user to dashboard
              isAuth ? <Navigate to="/dashboard" /> : <Login />
            }
          />
          <Route
            path="dashboard"
            element={isAuth ? <MainLayout /> : <Navigate to="/login" />}
          >
            <Route index element={<DashBoard />} />
            {/* <Route index element={<Drones />}></Route> */}
            {/* <Route path="item2" element={<TestPage2 />} /> */}
            <Route path="drones" element={<Drones />} />
            <Route path="service-report" element={<ServiceReport />} />
          </Route>

          {/* service routes */}
          <Route
            path="services"
            element={isAuth ? <MainLayout /> : <Navigate to="/login" />}
          >
            <Route index element={<Services />}></Route>
            {/* <Route path="add-service" element={<AddService />} /> */}
            <Route path="add-service" element={<ServiceForm />} />
            <Route path="create-quotation" element={<Quotation />} />
            <Route path="edit-quotation" element={<Quotation />} />
          </Route>

          {/* Bookings */}
          <Route
            path="bookings"
            element={isAuth ? <MainLayout /> : <Navigate to="/login" />}
          >
            <Route
              path="/bookings/service-bookings"
              element={<ServiceOrder />}
            ></Route>

            <Route
              path="/bookings/service-bookings/create-service"
              element={<CreateServiceBooking />}
            ></Route>
            <Route
              path="/bookings/service-bookings/create-service/testing"
              element={<Testing />}
            ></Route>
            <Route
              path="service-booking-details/:id"
              element={<ServiceBookingDetails />}
            />
            <Route
              path="drone-hub-booking-details/:id"
              element={<DroneHubBookingDetails />}
            />
            <Route
              path="/bookings/drone-hub-bookings"
              element={<DroneBookings />}
            ></Route>
            <Route
              path="/bookings/create-drone-hub-booking"
              element={<CreateDroneBooking />}
            ></Route>
            <Route
              path="/bookings/edit-drone-hub-booking-details/:id"
              element={<CreateDroneBooking />}
            ></Route>

            <Route
              path="/bookings/draft-bookings"
              element={<DraftBookings />}
            ></Route>
            <Route
              path="/bookings/course-bookings"
              element={<CourseBookings />}
            ></Route>
            <Route
              path="/bookings/course-booking-details/:id"
              element={<CourseBookDetail />}
            ></Route>
            <Route
              path="/bookings/create-course-bookings"
              element={<AddCourseBooking />}
            ></Route>

            <Route
              path="/bookings/edit-course-bookings/:id"
              element={<AddCourseBooking />}
            ></Route>
          </Route>

          {/* calendar routes */}
          <Route
            path="calendar"
            element={isAuth ? <MainLayout /> : <Navigate to="/login" />}
          >
            <Route path="/calendar/events" element={<CustomCalendar />}></Route>
            <Route
              path="/calendar/block-calendar"
              element={<CustomCalendar1 />}
            ></Route>

            <Route
              path="/calendar/events1"
              element={<CustomCalendar1 />}
            ></Route>
            <Route
              path="/calendar/accommodation"
              element={<AccommodationCalendar />}
            ></Route>
          </Route>

          {/* drone routes */}
          <Route
            path="drones"
            element={isAuth ? <MainLayout /> : <Navigate to="/login" />}
          >
            <Route
              path="/drones/drone-master"
              element={<DroneMaster />}
            ></Route>
            <Route
              path="/drones/drone-inventory"
              element={<DroneInventory />}
            ></Route>
            {/* <Route element={<Drones />}></Route> */}
          </Route>
          {/* pilot routes */}
          <Route
            path="drones"
            element={isAuth ? <MainLayout /> : <Navigate to="/login" />}
          >
            <Route index element={<Drones />}></Route>
          </Route>

          <Route
            path="pilots"
            element={isAuth ? <MainLayout /> : <Navigate to="/login" />}
          >
            <Route path="/pilots/pilotslist" element={<PilotList />}></Route>
            <Route
              path="/pilots/pilot-attendance"
              element={<PilotAttendance />}
            ></Route>

            <Route
              path="/pilots/pilotslist/:id"
              element={<PilotProfile />}
            ></Route>

            <Route path="/pilots/profile" element={<Profile />}></Route>
            <Route
              path="/pilots/pilotslots"
              element={<PilotSlotList />}
            ></Route>
            <Route path="/pilots/pilotmaps" element={<PilotMaps />}></Route>
          </Route>

          {/* Services */}
          <Route
            path="droneservices"
            element={isAuth ? <MainLayout /> : <Navigate to="/login" />}
          >
            <Route path="serviceslist" element={<ServicesList />}></Route>
            <Route
              path="serviceslist/create-service"
              element={<CreateService />}
            ></Route>
            <Route
              path="serviceslist/edit-service/:slug"
              element={<CreateService />}
            ></Route>{" "}
            <Route
              path="activity-report"
              element={<ActivityReportList />}
            ></Route>
            <Route path="products" element={<ServiceProducts />}></Route>
          </Route>

          {/* lms */}

          <Route
            path="lms"
            element={isAuth ? <MainLayout /> : <Navigate to="/login" />}
          >
            <Route path="courses" element={<CoursesList />}></Route>

            <Route path="slot-master" element={<SlotMaster />}></Route>
            <Route path="attendance" element={<Attendance />}></Route>
            <Route path="instructors" element={<Instructors />}></Route>
            <Route path="locations" element={<Locations />}></Route>
            <Route
              path="courses/create-course"
              element={<CreateCourse />}
            ></Route>
            <Route
              path="courses/edit-course/:id"
              element={<CreateCourse />}
            ></Route>

            <Route path="hotel" element={<Hotel />}></Route>

            <Route path="createhotel/" element={<HotelDetails />}></Route>
            <Route
              path="edit/createhotel/:id"
              element={<HotelDetails />}
            ></Route>
          </Route>
          {/* system users routes */}
          <Route
            path="users"
            element={isAuth ? <MainLayout /> : <Navigate to="/login" />}
          >
            <Route
              path="/users/system-users"
              element={<SystemUsersList />}
            ></Route>
            <Route path="/users/dd-users" element={<DDUsers />}></Route>
            <Route path="/users/dealers" element={<Dealers />}></Route>
            <Route
              path="/users/dealers/profile/:id"
              element={<DealerDetails />}
            ></Route>

            <Route
              path="/users/dd-users/profile/:id"
              element={<UserProfile />}
            ></Route>
            <Route path="/users/review" element={<Review />}></Route>

            {/* 

            <Route
              path="/users/createhotel/"
              element={<HotelDetails />}
            ></Route>
            <Route
              path="/users/edit/createhotel/:id"
              element={<HotelDetails />}
            ></Route> */}

            <Route
              path="/users/organization"
              element={<Organization />}
            ></Route>

            <Route
              path="/users/createorganization/"
              element={<OrganizationDetails />}
            ></Route>

            <Route
              path="/users/edit/createorganization/:id"
              element={<OrganizationDetails />}
            ></Route>
          </Route>
          {/* coupons routes */}
          {/* <Route path="coupons" element={<Coupons />}></Route> */}

          <Route
            path="orders"
            element={isAuth ? <MainLayout /> : <Navigate to="/login" />}
          >
            <Route index element={<Drones />}></Route>
          </Route>
          {/* transaction ledger routes */}
          <Route
            path="coupons"
            element={isAuth ? <MainLayout /> : <Navigate to="/login" />}
          >
            <Route path="/coupons" element={<Coupons />}></Route>
            <Route
              path="/coupons/create-coupon/"
              element={<CreateCoupon />}
            ></Route>
            <Route
              path="/coupons/edit-coupon/:id"
              element={<CreateCoupon />}
            ></Route>
          </Route>

          <Route
            path="sdsd"
            element={isAuth ? <MainLayout /> : <Navigate to="/login" />}
          >
            <Route index element={<Drones />}></Route>
          </Route>

          <Route path="*" element={<ErrorPage />} />

          <Route
            path="banner"
            element={isAuth ? <MainLayout /> : <Navigate to="/login" />}
          >
            <Route path="/banner" element={<Banners />}></Route>
          </Route>
        </Routes>

        {/* <Footer /> */}
      </ThemeProvider>
      {/* </BrowserRouter> */}
    </div>
  );
}

export default AppRouter;
