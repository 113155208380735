import React, { useContext, useEffect, useState } from "react";
import styles from "./banner.module.css";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { Button, useTheme, Checkbox, colors } from "@mui/material";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import AddBannerForm from "./AddBannerForm";
import statusicon1 from "../../Assets/activeS.svg";
import statusicon2 from "../../Assets/inactivS.svg";
import editicon from "../../Assets/edit_icon.svg";
import deleteicon from "../../Assets/delete_icon.svg";
import { token_api } from "../../Utils/network";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import CustomModal from "../../Component/CustomModal/CustomModal";

const Banners = () => {
  let navigate = useNavigate();
  const { user_permissions } = useContext(UserCredsContext);
  const [tabValue, setTabValue] = useState(1);
  const [filters, setfilters] = useState({ max: 0, min: undefined, q: "" });
  const [advFilter, setAdvFilter] = useState(false);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [bannerDrawer, setBannerDrawer] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [types, setTypes] = useState([]);
  const [bannerDetails, setBannerDetails] = useState({});
  const [tableBodyData, setTableBodyData] = useState([]);
  const [openDailog, setOpenDailog] = useState(false);
  const [bannerItem, setBannerItem] = useState(null);

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Banner</div>,
  ];

  const handleAddBanner = () => {
    setBannerDrawer(true);
  };

  const handleRedirect = (id) => {};

  const handleCloseDrawer = () => {
    setBannerDetails({});
    setBannerDrawer(false);
  };

  const handleEditBanner = (editObject) => {
    setBannerDetails(editObject);

    setBannerDrawer(true);
  };

  const handleDeleteBanner = () => {
    token_api
      .delete(`base/banners/${bannerItem}/`)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          alert("Banner Deleted Successfully!");
          setOpenDailog(false);
          setBannerItem(null);
          getBannerData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBannerData = (finalFilters) => {
    token_api
      .get(`base/banners`, { params: { ...finalFilters } })
      .then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          setPaginationData(res?.data);
          let modifiedArray = res?.data?.data?.map((item) => {
            return {
              id: (
                <div style={{ cursor: "pointer", fontSize: "12px" }}>
                  {item?.id}
                </div>
              ),
              web: (
                <div className={styles.imgContainer}>
                  {item?.web ? <img src={item?.web} /> : "NA"}
                </div>
              ),
              tab: (
                <div className={styles.imgContainer}>
                  {item?.tab ? <img src={item?.tab} /> : "NA"}
                </div>
              ),
              mobile: (
                <div className={styles.imgContainer}>
                  {item?.mobile ? <img src={item?.mobile} /> : "NA"}
                </div>
              ),
              redirection_display: (
                <div className={styles.rediText}>
                  <p style={{ fontSize: "12px" }}>{item?.redirection_str}</p>
                  <p
                    className={styles.rediSubtext}
                    style={{ fontSize: "12px" }}
                  >
                    {item?.detail?.title}
                  </p>
                </div>
              ),
              status_display: (
                <div>
                  {item?.status === 1 ? (
                    <div
                      className={`${styles.activeStatus} ${styles.statusbox} `}
                    >
                      <img src={statusicon1} />
                      <p style={{ fontSize: "12px" }}> {item?.status_str} </p>
                    </div>
                  ) : (
                    <div
                      className={`${styles.inActiveStatus} ${styles.statusbox}`}
                    >
                      <img src={statusicon2} />
                      <p style={{ fontSize: "12px" }}>{item?.status_str}</p>
                    </div>
                  )}
                </div>
              ),
              created_modified_info: (
                <div>
                  <p style={{ margin: "0", fontSize: "12px" }}>
                    {item?.created_modified_info?.modified_date}
                  </p>
                  <p style={{ margin: "0", fontSize: "12px" }}>
                    ({item?.created_modified_info?.modified_time})
                  </p>
                </div>
              ),
              actions: (
                <div className={styles.actionsCon}>
                  {user_permissions?.update && (
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => handleEditBanner(item)}
                      src={editicon}
                    />
                  )}
                  {user_permissions?.delete && (
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={() => handleOpenDeleteBanner(item?.id)}
                      src={deleteicon}
                    />
                  )}
                </div>
              ),
            };
          });

          setBannerList([...modifiedArray]);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const timout = setTimeout(() => {
      const finalFilters = {};
      if (pageState) {
        finalFilters.page = pageState;
      }
      if (filters?.q) {
        finalFilters.full_name = filters?.q;
      }

      getBannerData(finalFilters);
    }, 600);
    return () => clearTimeout(timout);
  }, [pageState, filters?.q]);

  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  const handleCloseModal = () => {
    setOpenDailog(false);
    setBannerItem(null);
  };


  const handleOpenDeleteBanner = (bannerId) => {
    setBannerItem(bannerId);
    setOpenDailog(true);
  };

  return (
    <Container>
      <div className={styles.topContainer}>
        <div className={styles.breadCrumbsCon}>
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>{" "}
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>

        {user_permissions?.write && (
          <Button
            onClick={handleAddBanner}
            variant="contained"
            className={`${styles.gradientColor} ${styles.AddButton}`}
          >
            + New Banner
          </Button>
        )}
      </div>

      {tabValue == 1 && (
        <>
          {" "}
          <div className={styles.totalCountText}>
            Total Count : <span>{total_count}</span>
          </div>
          <div className={styles.tableContainer}>
            <CustomTable
              columns={tableColumns}
              data={bannerList}
              handleClick={handleRedirect}
              tableFor="courseListing"
            />
          </div>
          <div className={styles.paginationCon}>
            <CustomPagination
              max_pages={max_pages}
              setPageState={setPageState}
            />
          </div>
        </>
      )}

      {tabValue == 2 && (
        <>
          {" "}
          <div>Active Data</div>
        </>
      )}

      {tabValue == 3 && (
        <>
          <div>Inactive Data</div>
        </>
      )}

      <DrawerComp
        width="45%"
        open={bannerDrawer}
        onClose={handleCloseDrawer}
        anchor={"right"}
      >
        <AddBannerForm
          handleCancelDrw={handleCloseDrawer}
          getBannerData={getBannerData}
          bannerDetails={bannerDetails}
        />
      </DrawerComp>

      <CustomModal open={openDailog} onClose={handleCloseModal} height="300px">
        <div className="alertBox">
          <h3>Are you sure you want to delete the banner?</h3>
          <div className="alertBtnCon">
            <Button onClick={handleDeleteBanner} variant="contained">
              Yes
            </Button>
            <Button onClick={handleCloseModal} variant="contained">
              No
            </Button>
          </div>
        </div>
      </CustomModal>
    </Container>
  );
};

export default Banners;

const tableColumns = [
  {
    id: "checkBox",
    label: "",
  },
  {
    id: "id",
    label: "Banner ID",
  },
  {
    id: "web",
    label: "Website",
  },
  {
    id: "tab",
    label: "Tab",
  },
  {
    id: "mobile",
    label: "Mobile",
  },
  {
    id: "redirection_display",
    label: "Redirection",
  },
  {
    id: "status_display",
    label: "Status",
  },
  {
    id: "created_modified_info",
    label: "Modified On",
  },
  {
    id: "actions",
    label: "Actions",
  },
];
