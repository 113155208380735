import React, { useEffect, useState } from "react";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import styles from "./index.module.css";
import InputFields from "../../Component/InputFields/InputFields";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import { useActionData } from "react-router-dom";
import { Button } from "@mui/material";
import { token_api } from "../../Utils/network";
import { ValidateUserDetails } from "./Utility";

const EditUserProfile = ({ user, handleCancelDrw, userFetchDatanew }) => {
  const [formErrors, setFormErrors] = useState({});
  const [userDetails, setUserDetails] = useState({
    name: "",
    mobile: null,
    mobile_verified: "",
    date_joined: "",
    email: "",
    email_verified: "",
    gender: "",
    gender_str: "",
    gst: "",
    id: null,
    last_login: "",
    location: "",
    status: "",
  });


  const handleChange = (e, type, selectName, multiple) => {
    if (type === "select" && selectName && multiple) {
      setUserDetails({
        ...userDetails,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setUserDetails({
        ...userDetails,
        [selectName]: [e],
      });
    } else {
      const { name, value } = e.target;
      setUserDetails({
        ...userDetails,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    // debugger;
    if (user?.id) {
      const {
        name,
        mobile,
        mobile_verified,
        date_joined,
        email,
        email_verified,
        gender,
        gender_str,
        gst,
        id,
        last_login,
        location,
        status,
      } = user;

      const gender1 = parseInt(gender);
      let modifiedStatus = "";
      let modifiedMVStatus = "";
      let modifiedEVStatus = "";
      let modifiedGender = "";

      if (status && status[0]) {
        modifiedStatus = [status[0]];
      } else {
        modifiedStatus = statusList.filter((item) => item?.id === status);
      }
      if (mobile_verified && mobile_verified[0]) {
        modifiedMVStatus = [mobile_verified[0]];
      } else {
        modifiedMVStatus = statusArray.filter(
          (item) => item?.city === mobile_verified
        );
      }

      if (email_verified && email_verified[0]) {
        modifiedEVStatus = [email_verified[0]];
      } else {
        modifiedEVStatus = statusArray.filter(
          (item) => item?.city === email_verified
        );
      }

      if (gender1 && gender1[0]) {
        modifiedGender = [gender1[0]];
      } else {
        modifiedGender = genderArray.filter((item) => item?.id === gender1);
      }

      console.log(parseInt(gender), "useActionData");
      setUserDetails({
        ...userDetails,
        name: user?.name,
        mobile_verified: modifiedMVStatus,
        status: modifiedStatus,
        email: user?.email,
        email_verified: modifiedEVStatus,
        gender: modifiedGender,
        gst: user?.gst,
        mobile: user?.mobile,
      });
    }
  }, [user?.id]);

  const handleDeleteChip = (id, selectName) => {
    console.log("DELETE", id, selectName, userDetails?.[selectName]);
    setUserDetails({
      ...userDetails,
      [selectName]: userDetails?.[selectName]?.filter(
        (chip) => chip?.id !== id
      ),
    });
    // (chips) => chips.filter((chip) => chip?.id !== id)

    // if(selectName === "redirection"){
    //     setUserDetails({
    //         ...userDetails,
    //         detail:null,
    //         redirection:null,
    //     })
    // }
  };

  const handleSaveDetails = () => {
    const {
      name,
      mobile,
      status,
      gender,
      email,
      email_verified,
      mobile_verified,
      gst,
    } = userDetails;
    let data = {
      user: {
        mobile: mobile,
        mobile_verified: mobile_verified[0]?.city,
        ...(email && { email: email }),
        ...(email_verified[0]?.city && {
          email_verified: email_verified[0]?.city,
        }),
      },
      name: name,
      gender: gender[0]?.id,
      status: status[0]?.id,
      gst: gst,
    };
    ValidateUserDetails(userDetails)
      .then((response) => {
        if (response === "success") {
          if (user?.id) {
            token_api
              .patch(`/users/profile/${user?.id}/`, data)
              .then((res) => {
                if (
                  res?.status === 200 ||
                  res?.status === 201 ||
                  res?.status === 204
                ) {
                  // alert('profile update')
                  userFetchDatanew();
                  handleCancelDrw();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      })
      .catch((error) => {
        setFormErrors(error);
      });
  };

  return (
    <DrawerContainer style={{ marginTop: "0px", marginBottom: "10px" }}>
      <DrawerTitle
        text={user?.id ? `Edit Profile` : `Add Profile`}
        handleCancelDrw={handleCancelDrw}
      />
      {/* <div>EditUserProfile {user?.id}</div> */}
      <div className={styles.inputContainer}>
        <InputFields
          label="Full Name"
          required
          name="name"
          value={userDetails?.name}
          onChange={handleChange}
          error={formErrors?.name}
          helperText={formErrors?.name}
        />
      </div>
      <div className={styles.inputContainer}>
        <InputFields
          label="Mobile"
          required
          name="mobile"
          value={userDetails?.mobile}
          onChange={handleChange}
          error={formErrors?.mobile}
          helperText={formErrors?.mobile}
        />
      </div>
      <div className={styles.inputContainer}>
        <CustomSelectChip
          multiple={false}
          label="Mobile Number Verified"
          name="mobile_verified"
          onChange={handleChange}
          onDelete={handleDeleteChip}
          value={userDetails?.mobile_verified}
          listArray={statusArray}
          error={formErrors?.mobile_verified}
          helperText={formErrors?.mobile_verified}
        />
      </div>
      <div className={styles.inputContainer}>
        <CustomSelectChip
          multiple={false}
          label="Gender"
          name="gender"
          onChange={handleChange}
          onDelete={handleDeleteChip}
          value={userDetails?.gender}
          listArray={genderArray}
          error={formErrors?.gender}
          helperText={formErrors?.gender}
        />
      </div>
      <div className={styles.inputContainer}>
        <InputFields
          label="Email"
          required
          name="email"
          value={userDetails?.email}
          onChange={handleChange}
          // error={formErrors?.email}
          // helperText={formErrors?.email}
        />
      </div>
      <div className={styles.inputContainer}>
        <CustomSelectChip
          multiple={false}
          label="Email Verified"
          name="email_verified"
          onChange={handleChange}
          onDelete={handleDeleteChip}
          value={userDetails?.email_verified}
          listArray={statusArray}
          // error={formErrors?.email_verified}
          // helperText={formErrors?.email_verified}
        />
      </div>
      <div className={styles.inputContainer}>
        <InputFields
          label="GST no."
          required
          name="gst"
          value={userDetails?.gst}
          onChange={handleChange}
          error={formErrors?.gst}
          helperText={formErrors?.gst}
        />
      </div>

      <div className={styles.inputContainer}>
        <CustomSelectChip
          multiple={false}
          label="Status"
          name="status"
          onChange={handleChange}
          onDelete={handleDeleteChip}
          value={userDetails?.status}
          listArray={statusList}
          error={formErrors?.status}
          helperText={formErrors?.status}
        />
      </div>
      <div className={styles.inputContainer}>
        <Button
          variant="contained"
          // onClick={() => handleOpenModal(item)}
          onClick={handleSaveDetails}
          // style={{ maxWidth: "170px" }}
        >
          Update
        </Button>
      </div>
    </DrawerContainer>
  );
};

export default EditUserProfile;

const statusArray = [
  {
    id: 1,
    title: "Verified",
    city: true,
  },
  {
    id: 2,
    title: "Not Verified",
    city: false,
  },
];

const statusList = [
  {
    id: 1,
    title: "Active",
  },
  {
    id: 2,
    title: "InActive",
  },
];

const genderArray = [
  {
    id: 1,
    title: "Male",
  },
  {
    id: 2,
    title: "Female",
  },
];
