import React, { useContext, useEffect, useState } from "react";
import Container from "../../Component/Container/Container";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import CustomSelect from "../../Component/CustomSelect/CustomSelect";
import CustomButton from "../../Component/CustomButton/CustomButton";
import CustomDate from "../../Component/CustomDate/CustomDate";
import filterIcon from "../../Assets/filterIcon.png";
import CustomTable from "../../Component/CustomTable/CustomTable";
import { token_api, base_url } from "../../Utils/network";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { Checkbox } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardCard from "../../Component/DashboardCard/DashboardCard";
import { serviceOrderCard } from "../../Utils/constants";
import CustomTabs3 from "../../Component/CustomTabsNew/CustomTabs3";
import SearchAndDropdown from "../../Component/SearchAndDropdown/SearchAndDropdown";
import { UserCredsContext } from "../../ContextApi/UserCredsContext/UserCredsContext";
import styles from "./index.module.css";

import { Button } from "@mui/material";
import {
  extractDateTime,
  getTooltipComponent,
  helperdayMonthYearFull,
  formatDate,
} from "../../Utils/helper";
// import PushNotification from "../../Component/PushNotification/PushNotification";
import CustomSelectChip from "../../Component/CustomSelectChip/CustomSelectChip";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import axios from "axios";
import ServiceBookingCountCard from "../../Component/ServiceBookingCountCard/ServiceBookingCountCard";
import { nlNL } from "@mui/x-date-pickers";
import edit_icon from "../../Assets/edit_icon.svg";
import delete_icon from "../../Assets/delete_icon.svg";
import CustomModal from "../../Component/CustomModal/CustomModal";
const DroneBookings = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user_permissions } = useContext(UserCredsContext);

  console.log(user_permissions, "serviceBookingUserPermissions");

  const [countData, setCountData] = useState({});
  const [filters, setfilters] = useState({
    q: "",
    purchase_status: [],
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const [bookingList, setBookingList] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [typedValue, setTypedValue] = useState("");
  const [advFilter, setAdvFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState(null);

  console.log(page, "yserhsd");
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <div className="orange-1 bread">Drone Hub Bookings</div>,
  ];

  const getPurchaseStatusStyles = (status) => {
    switch (status) {
      case 1:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "#E95E33",

              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Pending
          </div>
        );
      case 2:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(87, 182, 122)",

              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Confirmed{" "}
          </div>
        );
      case 3:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(235, 137, 235)",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            On Hold{" "}
          </div>
        );
      case 4:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "#FF7366",
              // border: "1px solid rgb(81, 79, 255)",
              color: "white",
              fontSize: "12px",
            }}
          >
            Cancelled{" "}
          </div>
        );
      case 5:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "green",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Completed{" "}
          </div>
        );
      case 6:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(245, 199, 99)",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Out For Delivery{" "}
          </div>
        );

      case 7:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(87, 194, 255)",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Delivered{" "}
          </div>
        );

      default:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "black",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            None
          </div>
        );
    }
  };
  const getStatusStyles = (status) => {
    switch (status) {
      case 1:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "#E95E33",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Pending{" "}
          </div>
        );
      case 2:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(245, 199, 99)",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Partially Paid{" "}
          </div>
        );

      case 3:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(87, 182, 122)",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Paid{" "}
          </div>
        );
      case 4:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "#FF7366",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Failed{" "}
          </div>
        );
      default:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "black",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            None
          </div>
        );
    }
  };

  const handleRedirect = (bookingId) => {
    navigate(`/bookings/drone-hub-booking-details/${bookingId}`);
  };

  const handleEditBookingDetails = (bookingId) => {
    navigate(`/bookings/edit-drone-hub-booking-details/${bookingId}`);
  };
  const getDroneHubBookings = (finalFilters) => {
    token_api
      .get(`drone/shop_order`, {
        params: { ...finalFilters },
        paramsSerializer: {
          indexes: null,
        },
      })
      .then((response) => {
        console.log(response, "hariResponseee");
        setPaginationData(response?.data);
        let modifiedArray = response?.data?.data?.map((item) => {
          return {
            id: (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "12px",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  handleRedirect(item?.id);
                }}
              >
                {item?.id}
              </div>
            ),
            name: (
              <div style={{ cursor: "pointer" }}>
                <div
                  style={{ fontSize: "12px" }}
                >{`${item?.user?.first_name} ${item?.user?.last_name}`}</div>
                <div
                  style={{ fontSize: "12px" }}
                >{`${item?.user?.mobile}`}</div>
              </div>
            ),
            product_name: (
              <div style={{ fontSize: "12px" }}>
                {item?.order_product && item?.order_product.length > 0
                  ? item?.order_product.length === 1
                    ? item?.order_product[0]?.product?.name
                    : `${item?.order_product[0]?.product?.name} +${
                        item?.order_product.length - 1
                      } products`
                  : "N.A"}
              </div>
            ),
            total_amount:
              <p style={{ fontSize: "12px" }}>&#8377;{item?.total_amount}</p> ||
              "N.A",
            billing_address: (
              <div
                style={{ fontSize: "12px" }}
              >{`${item?.billing_address_line_1} ${item?.billing_address_line_2} ${item?.billing_city} ${item?.billing_district} ${item?.billing_state} ${item?.billing_country} ${item?.billing_pincode}`}</div>
            ),
            shipping_address: (
              <div
                style={{ fontSize: "12px" }}
              >{`${item?.shipping_address_line_1} ${item?.shipping_address_line_2} ${item?.shipping_city} ${item?.shipping_district} ${item?.shipping_state} ${item?.shipping_country} ${item?.shipping_pincode}`}</div>
            ),

            zoho_order_id: (
              <div style={{ fontSize: "12px" }}>
                {item?.zoho_sales_order_id || "N.A"}
              </div>
            ),

            created: (
              <div>
                <div style={{ fontSize: "12px" }}>
                  {helperdayMonthYearFull(item?.created)}
                </div>
                <div style={{ fontSize: "12px" }}>
                  {extractDateTime(item?.created)}
                </div>
              </div>
            ),
            delivery: (
              <div>
                <div style={{ fontSize: "12px" }}>
                  {helperdayMonthYearFull(item?.delivery_date)}
                </div>
              </div>
            ),

            status: getStatusStyles(item?.payment_status),
            purchase_status: getPurchaseStatusStyles(item?.purchase_status),
            actions: (
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleEditBookingDetails(item?.id);
                  }}
                >
                  <img src={edit_icon} />
                </div>
                {/* <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleDeleteDroneHubBookingPopUp(item?.id);
                  }}
                >
                  <img src={delete_icon} />
                </div> */}
              </div>
            ),
          };
        });
        setBookingList([...modifiedArray]);
      })
      .catch((err) => {
        console.log("BookingErr", err);
      });
  };

  const handleDeleteDroneHubBookingPopUp = (id) => {
    setOpen(true);
    setOrderId(id);
  };

  const handleAdvanceFilters = () => {
    setAdvFilter(!advFilter);
  };

  useEffect(() => {
    const timout = setTimeout(() => {
      const finalFilters = {};
      if (pageState) {
        finalFilters.page = pageState;
      }
      if (filters?.q) {
        finalFilters.q = filters?.q;
      }
      if (filters?.purchase_status) {
        const ids = filters?.purchase_status?.map((info) => info?.id);
        finalFilters.purchase_status = ids[0];
      }

      getDroneHubBookings(finalFilters);
    }, 600);
    return () => clearTimeout(timout);
  }, [pageState, filters?.q, filters?.purchase_status]);

  const handleFilter = (e, type, selectName, multiple) => {
    console.log("HANDLERSS", e, type);
    if (type === "select" && selectName && multiple) {
      setfilters({
        ...filters,
        [selectName]: typeof e === "string" ? e.split(",") : e,
      });
    } else if (type === "select" && selectName && !multiple) {
      setfilters({
        ...filters,
        [selectName]: [e],
      });
    }
  };

  const handleDeleteChip = (id, selectName) => {
    setfilters({
      ...filters,
      [selectName]: filters?.[selectName]?.filter((chip) => chip?.id !== id),
    });
  };

  const handleTabChange = (newTabValue) => {
    console.log(newTabValue, "newtabvalue");
    setPageState(1);

    setTabValue(newTabValue);
  };

  console.log(filters, "filyers");
  const handleNavigateBack = () => {
    navigate(`/dashboard`);
  };

  const handleChangeforSearch = (event, newValue) => {
    setTypedValue(newValue);
    setfilters((prev) => ({
      ...prev,
      location: [newValue],
    }));
  };

  const handleDeleteDronehubBooking = () => {
    token_api
      .delete(`drone/admin_place_order/${orderId}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Dronehub booking deleted successfully!");
          setOpen(false);
          setOrderId(null);
          getDroneHubBookings();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseModal = () => {
    setOpen(false);
    setOrderId(null);
  };
  const handleNavigaeCreate = () => {
    navigate(`/bookings/create-drone-hub-booking`);
  };

  const handleExportData = async () => {
    try {
      let filterObj = {};
      if (filters?.q) {
        filterObj.q = filters?.q;
      }
      if (filters?.purchase_status && filters?.purchase_status[0]) {
        filterObj.purchase_status = filters?.purchase_status[0]?.id;
      }

      const response = await axios.get(`${base_url}drone/shop_order/export`, {
        params: { ...filterObj },

        paramsSerializer: {
          indexes: null,
        },
      });
      const excelUrl = response.data.data.data.excel_url;
      const link = document.createElement("a");
      link.href = excelUrl;
      link.setAttribute("download", `${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      if (response?.status === 200 || response?.status === 201) {
        alert("Dronehub bookings data excel file downloaded successfully!");
        setfilters({});
      }
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>
        <Button variant="contained" onClick={handleNavigaeCreate}>
          + NEW DRONE HUB BOOKING
        </Button>
      </div>

      <FilterContainer
        style={{ maxHeight: "40px", marginTop: "20px" }}
        // className={`${styles.marginTopBox}`}
      >
        <CustomSearchFilter
          placeholder="Search by booking id, user name, mobile number, product name..."
          onChange={(e) => {
            setPageState(1);
            setfilters({ ...filters, q: e });
          }}
        />

        <CustomSelectChip
          multiple={false}
          label="Filter By Booking Status"
          name="purchase_status"
          listArray={purchaseStatusList}
          onChange={handleFilter}
          onDelete={handleDeleteChip}
          value={filters?.purchase_status}
        />

        <CustomButton
          text={"Export"}
          maxWidth="110px"
          onClick={handleExportData}
        />
      </FilterContainer>
      <div className="totalCountText">
        Total Count : <span>{total_count}</span>
      </div>

      <div>
        <CustomTable
          columns={tableColumns}
          data={bookingList}
          tableFor="serviceOrders"
        />
      </div>

      <div style={{ marginTop: "15px" }}>
        <CustomPagination max_pages={max_pages} setPageState={setPageState} />
      </div>

      <CustomModal open={open} onClose={handleCloseModal} height="400px">
        <div className="alertBox">
          <h3>Are you sure you want to delete the dronehub booking?</h3>
          <div className="alertBtnCon">
            <Button onClick={handleDeleteDronehubBooking} variant="contained">
              Yes
            </Button>
            <Button onClick={handleCloseModal} variant="contained">
              No
            </Button>
          </div>
        </div>
      </CustomModal>
    </Container>
  );
};

export default DroneBookings;

const tableColumns = [
  {
    id: "id",
    label: "Booking ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "product_name",
    label: "Product Name",
  },
  {
    id: "total_amount",
    label: "Total Amount",
  },
  // {
  //   id: "billing_address",
  //   label: "Billing Address",
  // },
  // {
  //   id: "shipping_address",
  //   label: "Shipping Address",
  // },
  {
    id: "zoho_order_id",
    label: "Zoho Order ID",
  },
  {
    id: "created",
    label: "Created Date",
  },
  {
    id: "delivery",
    label: "Expected Date",
  },

  // {
  //   id: "status",
  //   label: "Payment Status",
  // },
  {
    id: "purchase_status",
    label: "Booking Status",
  },
  {
    id: "actions",
    label: "Actions",
  },
];

const purchaseStatusList = [
  {
    id: 1,
    title: "Pending",
  },
  {
    id: 2,
    title: "Confirmed",
  },
  {
    id: 3,
    title: "On Hold",
  },
  {
    id: 4,
    title: "Cancelled",
  },
  {
    id: 5,
    title: "Completed",
  },
  {
    id: 6,
    title: "Out for Delivery",
  },
  {
    id: 7,
    title: "Delivered",
  },
];
