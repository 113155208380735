import { Tooltip } from "@mui/material";
import moment from "moment";
import dayjs from "dayjs";
import idle_van from "../Component/TrackingMap/idle_van";
import running_van from "../Component/TrackingMap/running_van";
import unreachable_van from "../Component/TrackingMap/unreachable_van";
import stopped_van from "../Component/TrackingMap/stopped_van";

export const handleKeyPress = (event) => {
  if (event.charCode < 48 || event.charCode > 57) {
    event.preventDefault();
  }
};

export const saveToLocal = (type, value) => {
  if (value) localStorage.setItem(type, JSON.stringify(value));
};

export const quotationCalculation = (changeType, servcieObj, value) => {
  const qObj = {};
  if (changeType === "qty") {
    let newTotal = value * servcieObj?.price;
    qObj.total = newTotal;
    return qObj;
  }
};

export const quotationTotalCalculation = (services) => {
  const fn = services.reduce(
    (acc, item) => {
      acc.subTotal += item?.total || 0;
      acc.igst += item?.id === 132 ? 0 : (item?.total || 0) * (18 / 100);
      acc.total = acc.subTotal + acc.igst;
      return acc;
    },
    { subTotal: 0, igst: 0, total: 0 }
  );

  return fn;
};

export const setServiceLocationHelper = (address) => {
  return (
    <div>
      <div>
        <span>{address?.plot_no && address?.plot_no + ","}</span>
        {/* <span>{address?.line_1 && address?.line_1 + ", "}</span> */}
        <span>{address?.line_2 && address?.line_2 + ", "}</span>
      </div>
      <div>
        <span>{address?.city && address?.city + ", "}</span>
        <span>{address?.district && address?.district + ","}</span>
      </div>
      <div>
        <span>{address?.state && address?.state + ", "}</span>
        <span>{address?.country && address?.country + ", "}</span>
      </div>
      <div>
        <span>{address?.pincode}</span>
      </div>
    </div>
  );
};

export const getAddressSimple = (address) => {
  return `${(address?.line_1 && address?.line_1 + ", ") || ""} ${
    (address?.line_2 && address?.line_2 + ", ") || ""
  } ${(address?.area && address?.area + ", ") || ""} ${
    (address?.city && address?.city + ", ") || ""
  } ${(address?.district && address?.district + ", ") || ""} ${
    (address?.state && address?.state + ", ") || ""
  } ${(address?.country && address?.country + ", ") || ""} ${
    (address?.pincode && address?.pincode) || ""
  }`;
};

// active: true;
// area: "sdf";
// city: "Mumbai";
// country: "India";
// creator: 130;
// district: "Mumbai";
// id: 24;
// landmark: "Andheri East";
// lat: "19.0621840000000000";
// line_1: "2-42";
// line_2: "farm";
// long: "72.8454630000000000";
// modified_by: null;
// pincode: 40006;
// plot_no: null;
// state: "Maharashtra";
// statecode: 2147483647;
// tehsil: "Mumbai  North West";

export const getTooltipComponent = (data, type) => {
  if (data && data?.length > 1) {
    const dataStrings = data?.map((item, index) => {
      return item[type]?.title;
    });
    const firstString = dataStrings[0];
    const restStr = dataStrings.slice(1);
    return (
      <div style={{ display: "flex", fontSize: "12px" }}>
        <span>{`${firstString}`}</span>
        <span>
          <Tooltip title={restStr.join(", ")} placement="top">
            <div>{`...more`}</div>
          </Tooltip>
        </span>
      </div>
    );
  } else {
    if (data?.length > 0) {
      const single = data[0][type]?.title;
      return <div style={{ fontSize: "12px" }}>{single}</div>;
    }
  }
};

export const helperdayMonthYearFull = (date_) => {
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const d = new Date(date_);
  let day = d.getDate();
  let name = month[d.getMonth()];
  let year = d.getFullYear();
  return day + " " + name + " " + year;
};

export const helperdayMonthYearShort = (date_) => {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
};

export const extractDateTime = (date_) => {
  const time = new Date(date_).toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  return time;
};

// get estimate data fior service details

export const getEstimateDataForView = (data) => {
  const final = data.reduce((acm, cv) => {
    acm[cv.key] = cv?.value ? cv?.value : 0;
    return acm;
  }, {});
  return final;
};

export function formatString(inputString) {
  return inputString.replace(/_/g, " ");
}

export function getAMPM(hour) {
  if (hour >= 0 && hour < 12) {
    return "AM";
  } else {
    return "PM";
  }
}

export function convertDaterange(startDateString, endDateString) {
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  const formattedStartDate = startDate
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, "-");
  const formattedEndDate = endDate
    .toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .replace(/\//g, "-");
  let start = formattedStartDate.split("-").reverse().join("-");
  let end = formattedEndDate.split("-").reverse().join("-");

  return {
    start,
    end,
  };
}
export function convertDateFormat(inputDate) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so we add 1 and pad with zero if needed
  const day = String(date.getDate()).padStart(2, "0"); // Pad with zero if needed

  return `${year}-${month}-${day}`;
}

export const formatDate = (date) => {
  const formattedDate = dayjs(date).format("YYYY-MM-DD");
  return formattedDate;
};

export function formatDates(date_) {
  var d = new Date(date_),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  var date = [year, month, day].join("-");
  return date;
}

export const convertTo12HourFormat = (timeString) => {
  let [hours, minutes, seconds] = timeString.split(":").map(Number);
  let period = "AM";

  if (hours >= 12) {
    period = "PM";
    if (hours > 12) {
      hours -= 12;
    }
  } else if (hours === 0) {
    hours = 12;
  }

  // Ensure hours and minutes are always two digits
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes} ${period}`;
};

export const describeDuration = (timeString) => {
  const [hours, minutes] = timeString.split(":");

  return `${hours} Hours, ${minutes} Minutes`;
};

export function convertYMDtoDate(YMD) {
  return new Date(YMD);
  // custom code
  // var dateString = YMD.split("-").join("");
  // var year = dateString.substring(0, 4);
  // var month = dateString.substring(4, 6);
  // var day = dateString.substring(6, 8);
  // var date = new Date(Number(year), Number(month) - 1, Number(day));
  // return date;
}

export function convertDateToYMD(date) {
  const year = date.getFullYear();
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
  // console.log(formattedDate); // Output: "yyyy-mm-dd"
  // return _date.toISOString().slice(0, 10); // gives error
}

export const getMonthEndDates = (date) => {
  const currentMonth = new Date(date); // Returns a zero-based month index
  const startOfMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth(),
    1
  );

  // Get the end date of the current month
  const endOfMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth() + 1,
    0
  );
  return {
    startOfMonth: convertDateToYMD(startOfMonth),
    endOfMonth: convertDateToYMD(endOfMonth),
  };
};

export const checkColor = (status) => {
  switch (status.toLowerCase()) {
    case "idle":
      return "#6A216A";
    case "running":
      return "#25A455";
    case "unreachable":
      return "#EF6464";
    case "stopped":
      return "#FE9A0A";
    default:
      return "black"; // Default color
  }
};

export const checkColorDrone = (status) => {
  switch (status.toLowerCase()) {
    case "unknown":
      return "#6A216A";
    case "offline":
      return "#EF6464";
    case "online":
      return "#25A455";
    default:
      return "black"; // Default color
  }
};

export const checkIconType = (_type) => {
  switch (_type.toLowerCase()) {
    case "idle":
      return idle_van;
    case "running":
      return running_van;
    case "unreachable":
      return unreachable_van;
    case "stopped":
      return stopped_van;
    default:
      return unreachable_van;
  }
};

export const checkDroneType = (_type) => {
  switch (_type.toLowerCase()) {
    case "online":
      return "";
    case "offline":
      return "";
    case "unknown":
      return "";
  }
};

export const convertStringToDateTime = (dateString) => {
  // The given timestamp

  // Create a Date object from the timestamp
  const date = new Date(dateString);

  // Function to convert date to ordinal (e.g., 1 to 1st)
  function ordinal_suffix_of(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  // Format the date and time
  const formattedDateTime = `${ordinal_suffix_of(
    date.getDate()
  )} ${date.toLocaleString("default", {
    month: "short",
  })} ${date.getFullYear()} ${date.getHours() % 12 || 12}:${
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
  } ${date.getHours() < 12 ? "AM" : "PM"}`;

  // Output the result
  return formattedDateTime;
};

export function isToday(dateString) {
  const inputDate = new Date(dateString);
  const today = new Date();

  // Compare the year, month, and day of the input date with today's date
  return (
    inputDate.getFullYear() === today.getFullYear() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getDate() === today.getDate()
  );
}
