import React, { useEffect, useState } from "react";
import styles from "./PilotAttendance.module.css";
import Container from "../../Component/Container/Container";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import CustomSearchFilter from "../../Component/CustomSearch/CustomSearchFilter";
import { Link, useNavigate } from "react-router-dom";
import { token_api, base_url } from "../../Utils/network";
import { formatDate, extractDateTime } from "../../Utils/helper";
import { Button } from "@mui/material";
import pilot_avatar from "../../Assets/pilotIcon.svg";
import download_icon from "../../Assets/downloadDroneCert.svg";
import FilterContainer from "../../Component/FitlerContainer/FilterContainer";
import CustomDatePicker from "../../Component/MaterialDate/MaterialDate";
import CustomButton from "../../Component/CustomButton/CustomButton";
import axios from "axios";
import CustomModal from "../../Component/CustomModal/CustomModal";
import DronePilot from "../../Component/DronePilot/DronePilot";

const PilotAttendance = () => {
  const navigate = useNavigate();
  const [attendanceData, setAttendanceData] = useState([]);
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const [openPopUp, setOpenPopUp] = useState(false);
  const [exportData, setExportData] = useState({
    start_date: null,
    end_date: null,
  });
  const [filters, setFilters] = useState({
    pilot: [],
    start_date: null,
    end_date: null,
  });
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link to="/pilots/pilotslist" className="orange-1 bread breadElement">
      Pilots
    </Link>,

    <div className="orange-1 bread">Pilot Attendance</div>,
  ];

  const getPilotAttendanceList = async (filterobject) => {
    try {
      const fetchList = await token_api.get(`users/pilot/attendance`, {
        params: { ...filterobject },
      });
      if (fetchList?.status !== 200) {
        throw new Error("Network Error");
      }
      setPaginationData(fetchList?.data);

      let modifiedData = fetchList?.data?.data.map((item, index) => {
        return {
          pilot: (
            <div className={styles.wrapperContainer}>
              <p style={{ fontSize: "12px" }}>{item?.pilot_name}</p>
            </div>
          ),
          vehicle_number: (
            <div className={styles.wrapperContainer}>
              <p style={{ fontSize: "12px" }}>
                {item?.vehicle_number || "N.A"}
              </p>
            </div>
          ),
          date: (
            <div style={{ fontSize: "12px" }}>{formatDate(item?.clock_in)}</div>
          ),

          clock_in_time: item?.clock_in ? (
            <a
              style={{ color: "black", fontSize: "12px" }}
              href={`https://www.google.com/maps/?q=${item?.lat},${item?.long}`}
              target="_blank"
            >
              {extractDateTime(item?.clock_in)}
            </a>
          ) : (
            "N.A"
          ),
          clock_out_time: item?.clock_out ? (
            <a
              style={{ color: "black", fontSize: "12px" }}
              href={`https://www.google.com/maps/?q=${item?.lat},${item?.long}`}
              target="_blank"
            >
              {extractDateTime(item?.clock_out)}
            </a>
          ) : (
            "N.A"
          ),
          total_time: (
            <p style={{ fontSize: "12px" }}>{item?.total_hours_minutes}</p>
          ),
          start_kms: item?.clock_in ? (
            <div className={styles.wrapperContainer}>
              <p style={{ fontSize: "12px" }}>{item?.clock_in_kms}</p>
              <a href={item?.clock_in_odometer_image} target="_blank">
                <img src={download_icon} style={{ cursor: "pointer" }} />
              </a>
            </div>
          ) : (
            "N.A"
          ),
          end_kms: item?.clock_out ? (
            <div className={styles.wrapperContainer}>
              <p style={{ fontSize: "12px" }}>{item?.clock_out_kms}</p>
              <a href={item?.clock_out_odometer_image} target="_blank">
                <img src={download_icon} style={{ cursor: "pointer" }} />
              </a>
            </div>
          ) : (
            "N.A"
          ),
          total_kms: (
            <p style={{ fontSize: "12px" }}>
              {item?.total_kms_travelled || "N.A"}
            </p>
          ),
          total_time: (
            <p style={{ fontSize: "12px" }}>
              {item?.total_hours_minutes || "N.A"}
            </p>
          ),
        };
      });

      setAttendanceData(modifiedData);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const timeOut = setTimeout(() => {
      let filtersObject = {};
      if (pageState) {
        filtersObject.page = pageState;
      }
      if (filters?.q) {
        filtersObject.q = filters?.q;
      }
      if (filters?.start_date) {
        filtersObject.from_date = formatDate(filters?.start_date);
      }
      if (filters?.end_date) {
        filtersObject.to_date = formatDate(filters?.end_date);
      }
      if (filters?.pilot[0]) {
        let ids = filters?.pilot.map(
          (item) => item?.user?.first_name + " " + item?.user?.last_name
        );
        filtersObject.q = ids[0];
      }
      getPilotAttendanceList(filtersObject);
    }, 500);

    return () => clearTimeout(timeOut);
  }, [
    pageState,
    filters?.q,
    filters?.start_date,
    filters?.end_date,
    filters?.pilot,
  ]);

  //Export logic...

  const handleCloseModal = () => {
    setExportData({
      start_date: null,
      end_date: null,
    });
    setOpenPopUp(false);
  };
  const handleExportData = async () => {
    try {
      if (!exportData?.start_date || !exportData?.end_date) {
        alert("Please select start date and end dates.");
      } else {
        const response = await axios.get(
          `${base_url}users/pilot/attendance/export?from_date=${formatDate(
            exportData?.start_date
          )}&to_date=${formatDate(exportData?.end_date)}`
        );
        const excelUrl = response.data.data.data.excel_url;
        const link = document.createElement("a");
        link.href = excelUrl;
        link.setAttribute("download", `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();

        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("Pilot Attendance file downloaded successfully!");
          setExportData({
            start_date: null,
            end_date: null,
          });
          setOpenPopUp(false);
          getPilotAttendanceList();
        }
      }
    } catch (error) {
      console.error("Error exporting the file", error);
    }
  };
  const handleNavigateBack = () => {
    navigate(`/pilots/pilotslist`);
  };

  const handleSearchPilot = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Pilot already included");
    } else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        pilot: selectedValues,
      }));
    }
  };
  return (
    <div>
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
            <img src={breadCrumbBack} />
          </div>
          <CustomBreadCrumbs breadcrumbs={breadcrumbs} size="small" />
        </div>
        <div style={{ margin: "20px 0px" }}>
          <FilterContainer>
            <CustomSearchFilter
              placeholder="Search by pilot name..."
              onChange={(e) => {
                setFilters({ ...filters, q: e });
              }}
            />
            <DronePilot value={filters?.pilot} onChange={handleSearchPilot} />

            <CustomDatePicker
              label="Start Date"
              value={filters?.start_date}
              onChange={(newValue) =>
                setFilters({
                  ...filters,
                  start_date: newValue,
                })
              }
            />
            <CustomDatePicker
              label="End Date"
              value={filters?.end_date}
              onChange={(newValue) =>
                setFilters({
                  ...filters,
                  end_date: newValue,
                })
              }
            />

            <CustomButton
              text={"Export"}
              maxWidth="110px"
              onClick={() => {
                setOpenPopUp(true);
              }}
            />
          </FilterContainer>
        </div>
        <div className="totalCountText">
          Total Count : <span>{total_count}</span>
        </div>
        <div className={styles.tableContainer}>
          <CustomTable
            columns={tableColumns}
            data={attendanceData}
            tableFor="pilots attendance"
          />
        </div>
        <div className={styles.paginationContainer}>
          <CustomPagination max_pages={max_pages} setPageState={setPageState} />
        </div>

        <CustomModal open={openPopUp} onClose={handleCloseModal} height="400px">
          <div>
            <h3 style={{ marginBottom: "10px", color: "red" }}>
              Please select start date and end date.
            </h3>
            <div style={{ marginTop: "20px" }}>
              <CustomDatePicker
                label="Start Date"
                value={exportData?.start_date}
                onChange={(newValue) =>
                  setExportData({
                    ...exportData,
                    start_date: newValue,
                  })
                }
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <CustomDatePicker
                label="End Date"
                value={exportData?.end_date}
                onChange={(newValue) =>
                  setExportData({
                    ...exportData,
                    end_date: newValue,
                  })
                }
              />
            </div>{" "}
            <Button
              variant="contained"
              onClick={handleExportData}
              style={{ marginTop: "20px" }}
            >
              Download
            </Button>
          </div>
        </CustomModal>
      </Container>
    </div>
  );
};

export default PilotAttendance;

const tableColumns = [
  // {
  //   id: "id",
  //   label: "ID",
  // },
  {
    id: "pilot",
    label: "Pilot",
  },
  {
    id: "vehicle_number",
    label: "Vehicle Number",
  },
  {
    id: "date",
    label: "Date",
  },
  {
    id: "clock_in_time",
    label: "Clock-In Time",
  },
  {
    id: "clock_out_time",
    label: "Clock-Out Time",
  },
  {
    id: "total_time",
    label: "Total Time",
  },
  {
    id: "start_kms",
    label: "Start Kms",
  },
  {
    id: "end_kms",
    label: "End Kms",
  },
  {
    id: "total_kms",
    label: "Total Kms",
  },
];

const tableBodyData = [
  {
    id: 1,
    pilot: {
      name: "Hari Gadi",
      avatar: pilot_avatar,
    },
    mobile: "6304113067",
    date: "02-06-2024",
    clock_in_time: "12.30 PM",
    clock_out_time: "2.00 PM",
    total_time: "1.30 PM",
    start_kms: "20 km",
    end_kms: "40 km",
    total_kms: "20 km",
  },
  {
    id: 2,
    pilot: {
      name: "Hari Gadi",
      avatar: pilot_avatar,
    },
    mobile: "6304113067",
    date: "02-06-2024",
    clock_in_time: "12.30 PM",
    clock_out_time: "2.00 PM",
    total_time: "1.30 PM",
    start_kms: "20 km",
    end_kms: "40 km",
    total_kms: "20 km",
  },
  {
    id: 3,
    pilot: {
      name: "Hari Gadi",
      avatar: pilot_avatar,
    },
    mobile: "6304113067",
    date: "02-06-2024",
    clock_in_time: "12.30 PM",
    clock_out_time: "2.00 PM",
    total_time: "1.30 PM",
    start_kms: "20 km",
    end_kms: "40 km",
    total_kms: "20 km",
  },
  {
    id: 4,
    pilot: {
      name: "Hari Gadi",
      avatar: pilot_avatar,
    },
    mobile: "6304113067",
    date: "02-06-2024",
    clock_in_time: "12.30 PM",
    clock_out_time: "2.00 PM",
    total_time: "1.30 PM",
    start_kms: "20 km",
    end_kms: "40 km",
    total_kms: "20 km",
  },
  {
    id: 5,
    pilot: {
      name: "Hari Gadi",
      avatar: pilot_avatar,
    },
    mobile: "6304113067",
    date: "02-06-2024",
    clock_in_time: "12.30 PM",
    clock_out_time: "2.00 PM",
    total_time: "1.30 PM",
    start_kms: "20 km",
    end_kms: "40 km",
    total_kms: "20 km",
  },
];
