import React, { useEffect, useState } from "react";
import Container from "../../Component/Container/Container";
import CustomTabs3 from "../../Component/CustomTabsNew/CustomTabs3";
import styles from "./index.module.css";
import UserProfileCard from "../../Component/UserProfileCard/UserProfileCard";
import CustomBreadCrumbs from "../../Component/CustomBreadCrumbs/CustomBreadCrumbs";
import CourseTakenComponent from "../../Component/CourseTakenComponent/CourseTakenComponent";
import { useParams } from "react-router";
import useFetchData from "../../Component/CustomHook/useFetchData";
import UserDDProfileCard from "../../Component/UserDDProfileCard/UserDDProfileCard";
import CustomTable from "../../Component/CustomTable/CustomTable";
import CustomPagination from "../../Component/CustomPagination/CustomPagination";
import { Link, useNavigate } from "react-router-dom";
import { token_api } from "../../Utils/network";
import { modifyCourseBookData } from "../CourseBookings/utility";
import { helperdayMonthYearFull } from "../../Utils/Utils";
import { extractDateTime, getTooltipComponent } from "../../Utils/helper";
import DrawerComp from "../../Component/DrawerComp/DrawerComp";
import EditUserProfile from "./EditUserProfile";
import CustomModal from "../../Component/CustomModal/CustomModal";
import dragDrop from "../../Assets/drag_drop.svg";
import cancellogo from "../../Assets/cancel.svg";
import EditUserpicModel from "./EditUserpicModel";
import Registration from "../../Component/RegistationDetails/Registration";
import breadCrumbBack from "../../Assets/breadCrumbBack.svg";
import ddUserDummy from "../../Assets/DDuserIcons/dduserDummyProfile.svg";
import editIcon from "../../Assets/userProfile/editIcon.svg";
import ddPersonal from "../../Assets/DDuserIcons/ddPersonal.svg";
import ddArea from "../../Assets/DDuserIcons/ddArea.svg";
import ddAssets from "../../Assets/DDuserIcons/ddAssets.svg";
import ddAddresses from "../../Assets/DDuserIcons/ddAddresses.svg";
import ddServiceBooking from "../../Assets/DDuserIcons/ddServiceBookings.svg";
import DDUserAreaAlloted from "../../Component/DDUserAreaAlloted/DDUserAreaAlloted";
import DDUsersAssets from "../../Component/DDUserAssets/DDUserAssets";
import DDUserAddress from "../../Component/DDUserAddress/DDUserAddress";
import DDUserServiceBooking from "../../Component/DDUsersServiceBooking/DDuserServiceBooking";
import orangeDocument from "../../Assets/orangeUserDocument.svg";
import orangeCourseBooking from "../../Assets/orangeCourseBooking.svg";
import orangeServiceBooking from "../../Assets/orangeServiceBooking.svg";
import orangeDroneBooking from "../../Assets/orangeDroneBooking.svg";
import axios from "axios";
import AddressBlock from "../../Component/AddressBlock/AddressBlock";
import DrawerContainer from "../../Component/DrawerContainer/DrawerContainer";
import DrawerTitle from "../../Component/DrawerTitle/DrawerTitle";
import MyMap from "../../Component/MyMap/MyMap";
import InputFields from "../../Component/InputFields/InputFields";
import { Button } from "@mui/material";
import download_icon from "../../Assets/pdfDownload.svg";
import { ValidateAddress } from "./Utility";

const UserProfile = () => {
  const [loading, setLoading] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const navigate = useNavigate();
  const [pageState, setPageState] = useState(1);
  const [paginationData, setPaginationData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profilePic, setProfilePic] = useState({
    avatar: "",
  });
  const [userDataFetch, setUserDataFetch] = useState({});
  const { page, previous_page, max_pages, total_count, count } = paginationData;
  const { id: userId } = useParams();
  const [userAddresses, setUserAddresses] = useState([]);
  const [serviceBookingData, setServiceBookingData] = useState([]);
  const [openPDrawer, setOpenPDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState("");
  const [selectedBlock, setSelectedBlock] = useState([]);
  const [loc, updLoc] = useState({ lat: 20.5937, lng: 78.9629 });
  const [droneBookings, setDroneBookings] = useState([]);

  const [addressData, setAddressData] = useState({
    id: null,
    area: "",
    area_in_acres: "",
    city: "",
    country: "",
    district: "",
    line_1: "",
    line_2: "",
    plot_no: "",
    line_kms: "",
    pincode: "",
    state: "",
    tehsil: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const userFetchDatanew = () => {
    token_api
      .get(`users/dd_profiles?creator=${userId}`)
      .then((response) => {
        const data = response?.data?.data;
        if (response?.status === 200) {
          setUserDataFetch(response?.data?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching invoice data:", error);
      });
  };

  useEffect(() => {
    userFetchDatanew();
  }, []);
  const [userData, setUserData] = useState({});

  const breadcrumbs = [
    <Link to="/dashboard" className="orange-1 bread breadElement">
      Home
    </Link>,
    <Link to="/users/dd-users" className="orange-1 bread breadElement">
      DD-Users
    </Link>,
    <div className="orange-1 bread">User Profile</div>,
  ];
  const [tabValue, setTabValue] = useState(1);

  function extractdate(inputDateTime) {
    // Parse the input string to a Date object
    const dtObject = new Date(inputDateTime);

    // Extract the date part in YYYY-MM-DD format
    const formattedDate = dtObject.toISOString().split("T")[0];

    return formattedDate;
  }

  useEffect(() => {
    if (userDataFetch && userDataFetch.length > 0) {
      const {
        id,
        name,
        gender,
        gender_str,
        dob,
        avatar,
        default_avatar,
        creator: {
          email,
          mobile,
          last_login,
          date_joined,
          mobile_verified,
          email_verified,
        },
        gst,
        status,
        addresses,
        // addresses: [{ city, country, district }],
      } = userDataFetch[0];
      let city, country, district;

      if (addresses && addresses.length > 0) {
        city = addresses[0].city;
        country = addresses[0].country;
        district = addresses[0].district;
        setUserAddresses(addresses);
      }
      setUserData({
        ...userData,
        id: id,
        name: name,
        gender: gender,
        gender_str: gender_str,
        dob: dob,
        email: email,
        email_verified: email_verified,
        mobile: mobile,
        mobile_verified: mobile_verified,
        avatar: avatar,
        default_avatar: default_avatar,
        // last_login: last_login,
        last_login: last_login
          ? `${helperdayMonthYearFull(last_login)}`
          : "N.A",
        // date_joined: date_joined,
        date_joined: date_joined
          ? `${helperdayMonthYearFull(date_joined)}`
          : "N.A",
        gst: gst,
        status: status,
        location: city,
      });
    }
  }, [userDataFetch]);

  const getCourseBookings = async (filterObject) => {
    setLoading(true);
    // setBookingList([]);
    try {
      const fetchCourseBookings = await token_api.get(
        `course/course_book?user=${userId}`,
        {
          params: { ...filterObject },
          paramsSerializer: {
            indexes: null,
          },
        }
      );
      if (fetchCourseBookings?.status !== 200) {
        throw new Error("NETWORk Error");
      }
      const modifiedBookingArray = modifyCourseBookData(
        fetchCourseBookings?.data?.data,
        handleRedirect
      );
      setLoading(false);
      setBookingList(modifiedBookingArray);
      setPaginationData(fetchCourseBookings?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getServiceBookings = async (filterObject) => {
    setLoading(true);
    // setBookingList([]);
    try {
      const fetchCourseBookings = await token_api.get(
        `service/booking?user=${userId}`,
        {
          params: { ...filterObject },
          paramsSerializer: {
            indexes: null,
          },
        }
      );
      if (fetchCourseBookings?.status !== 200) {
        throw new Error("NETWORk Error");
      }
      let modifiedArray = fetchCourseBookings?.data?.data?.map((item) => {
        return {
          id: (
            <div
              onClick={() => handleRedirectService(item?.id)}
              style={{ cursor: "pointer" }}
            >
              {item?.id}
            </div>
          ),
          name: (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleRedirectService(item?.id)}
            >
              <div>{`${item?.full_name}`}</div>
              <div>{`${item?.contact_no}`}</div>
            </div>
          ),
          date: item?.date || "N.A",
          time: item?.time || "N.A",
          location: item?.address?.city || "N.A",
          created: (
            <div>
              <div>{helperdayMonthYearFull(item?.created)}</div>
              <div>{extractDateTime(item?.created)}</div>
            </div>
          ),
          serviceDate:
            (item?.serviceDate && helperdayMonthYearFull(item?.serviceDate)) ||
            "N.A",
          category:
            getTooltipComponent(item?.bookingaddonservice, "category") || "N.A",
          service:
            getTooltipComponent(item?.bookingaddonservice, "services") || "N.A",
          drone: item?.drone || "N.A",
          pilot: item?.pilot || "N.A",

          start_date:
            (item?.start_date && helperdayMonthYearFull(item?.start_date)) ||
            "N.A",

          end_date:
            (item?.end_date && helperdayMonthYearFull(item?.end_date)) || "N.A",
        };
      });
      setServiceBookingData(fetchCourseBookings?.data?.data);
      setBookingList([...modifiedArray]);
      setLoading(false);
      setPaginationData(fetchCourseBookings?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getPurchaseStatusStyles = (status) => {
    switch (status) {
      case 1:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "#E95E33",

              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Pending
          </div>
        );
      case 2:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(87, 182, 122)",

              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Confirmed{" "}
          </div>
        );
      case 3:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(235, 137, 235)",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            On Hold{" "}
          </div>
        );
      case 4:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "#FF7366",
              // border: "1px solid rgb(81, 79, 255)",
              color: "white",
              fontSize: "12px",
            }}
          >
            Cancelled{" "}
          </div>
        );
      case 5:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "green",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Completed{" "}
          </div>
        );
      case 6:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(245, 199, 99)",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Out For Delivery{" "}
          </div>
        );

      case 7:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "rgb(87, 194, 255)",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            Delivered{" "}
          </div>
        );

      default:
        return (
          <div
            className={styles.statusCon}
            style={{
              background: "black",
              border: "1px solid white",
              color: "white",
              fontSize: "12px",
            }}
          >
            None
          </div>
        );
    }
  };

  const handleRedirectDrone = (droneBookingId) => {
    navigate(`/bookings/drone-hub-booking-details/${droneBookingId}`);
  };
  const getDroneBookings = (finalFilters) => {
    token_api
      .get(`drone/shop_order?user=${userId}`, {
        params: { ...finalFilters },
        paramsSerializer: {
          indexes: null,
        },
      })
      .then((response) => {
        setPaginationData(response?.data);
        let modifiedArray = response?.data?.data?.map((item) => {
          return {
            id: (
              <div
                style={{
                  cursor: "pointer",
                  fontSize: "12px",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  handleRedirectDrone(item?.id);
                }}
              >
                {item?.id}
              </div>
            ),
            name: (
              <div style={{ cursor: "pointer" }}>
                <div
                  style={{ fontSize: "12px" }}
                >{`${item?.user?.first_name} ${item?.user?.last_name}`}</div>
                <div
                  style={{ fontSize: "12px" }}
                >{`${item?.user?.mobile}`}</div>
              </div>
            ),
            product_name: (
              <div style={{ fontSize: "12px" }}>
                {item?.order_product && item?.order_product.length > 0
                  ? item?.order_product.length === 1
                    ? item?.order_product[0]?.product?.name
                    : `${item?.order_product[0]?.product?.name} +${
                        item?.order_product.length - 1
                      } products`
                  : "N.A"}
              </div>
            ),
            total_amount:
              <p style={{ fontSize: "12px" }}>&#8377;{item?.total_amount}</p> ||
              "N.A",
            billing_address: (
              <div
                style={{ fontSize: "12px" }}
              >{`${item?.billing_address_line_1} ${item?.billing_address_line_2} ${item?.billing_city} ${item?.billing_district} ${item?.billing_state} ${item?.billing_country} ${item?.billing_pincode}`}</div>
            ),
            shipping_address: (
              <div
                style={{ fontSize: "12px" }}
              >{`${item?.shipping_address_line_1} ${item?.shipping_address_line_2} ${item?.shipping_city} ${item?.shipping_district} ${item?.shipping_state} ${item?.shipping_country} ${item?.shipping_pincode}`}</div>
            ),

            zoho_order_id: (
              <div style={{ fontSize: "12px" }}>
                {item?.zoho_sales_order_id || "N.A"}
              </div>
            ),

            created: (
              <div>
                <div style={{ fontSize: "12px" }}>
                  {helperdayMonthYearFull(item?.created)}
                </div>
                <div style={{ fontSize: "12px" }}>
                  {extractDateTime(item?.created)}
                </div>
              </div>
            ),
            delivery: (
              <div>
                <div style={{ fontSize: "12px" }}>
                  {helperdayMonthYearFull(item?.delivery_date)}
                </div>
              </div>
            ),

            purchase_status: getPurchaseStatusStyles(item?.purchase_status),
          };
        });
        setDroneBookings([...modifiedArray]);
      })
      .catch((err) => {
        console.log("BookingErr", err);
      });
  };

  const handleRedirectService = (id) => {
    navigate(`/bookings/service-booking-details/${id}`);
  };

  // useEffect(() => {
  //   if (tabValue == 4) {
  //     getCourseBookings();
  //   } else if (tabValue == 5) {
  //     getServiceBookings();
  //   } else if (tabValue == 6) {
  //     getDroneBookings();
  //   }
  // }, [tabValue]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      setLoading(true);
      let filterObject = {};
      if (pageState) {
        filterObject.page = pageState;
      }
      if (tabValue == 4) {
        getCourseBookings(filterObject);
      } else if (tabValue == 5) {
        getServiceBookings(filterObject);
      } else if (tabValue == 6) {
        getDroneBookings(filterObject);
      }
    }, 500);
    return () => {
      setLoading(false);
      clearTimeout(timeOut);
    };
  }, [pageState, tabValue]);

  const fetchInvoiceData = (receiveid) => {
    token_api
      .get(`/course/invoice/?course_purchase=${receiveid}`)
      .then((response) => {
        const data = response?.data?.data[0];
        if (data && data.hasOwnProperty("invoice_file")) {
          const pdfUrl = data.invoice_file;
          window.open(pdfUrl, "_blank");
        } else {
          alert("Please Generate Invoice");
        }
      })
      .catch((error) => {
        console.error("Error fetching invoice data:", error);
      });
  };

  const handleRedirect = (id, type, tableFor) => {
    if (type == "edit") {
      navigate(`/bookings/edit/create-course-bookings`, {
        state: { id },
      });
      return;
    }

    if (type == "generate") {
      token_api
        .post(`/course/create_invoice/`, {
          course_purchase_id: id?.id,
        })
        .then((response) => {
          if (response?.status == 200 || response?.status == 201) {
            alert(`invoice request generated`);
          } else {
            throw new Error("Something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });

      return;
    }

    if (type == "download") {
      fetchInvoiceData(id);
      return;
    }

    navigate(`/bookings/course-booking-details/${id}`);
  };

  const opneProfileDrawer = () => {
    setOpenPDrawer(true);
    setDrawerType("profile");
  };

  const handleCloseProfileDrawer = () => {
    setOpenPDrawer(false);
    setDrawerType("");
  };

  const handleAutoCompleteChangeForBlock = (selectedValues) => {
    const newItemId = selectedValues[selectedValues.length - 1]?.id;
    const isIdAlreadyPresent = selectedValues
      .slice(0, -1)
      .some((item) => item.id === newItemId);

    if (isIdAlreadyPresent) {
      alert("Block already included");
    } else {
      setSelectedBlock(selectedValues);
    }
  };

  const handleAddNewAddress = () => {
    setDrawerType("address");
    setOpenPDrawer(true);
    setAddressData({
      ...addressData,
      id: null,
      area: "",
      area_in_acres: "",
      city: "",
      country: "",
      district: "",
      line_1: "",
      line_2: "",
      plot_no: "",
      line_kms: "",
      pincode: "",
      state: "",
      tehsil: "",
    });
    setSelectedBlock([]);
  };
  const handleEditAddress = (info, drawertype) => {
    setDrawerType(drawertype);
    setAddressData({
      ...addressData,
      id: info?.id,
      area: info?.area,
      area_in_acres: info?.area_in_acres,
      city: info?.city,
      country: info?.country,
      district: info?.district,
      line_1: info?.line_1,
      line_2: info?.line_2,
      plot_no: info?.plot_no,
      line_kms: info?.line_kms,
      pincode: info?.pincode,
      state: info?.state,
      tehsil: info?.tehsil,
    });
    updLoc((prev) => ({
      ...prev,
      lat: info?.lat,
      lng: info?.long,
    }));
    setSelectedBlock([info?.block]);
    setOpenPDrawer(true);
  };

  const opneProfileModel = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleNavigateBack = () => {
    navigate(`/users/dd-users`);
  };

  const handleChangeTab = (tabId) => {
    setTabValue(tabId);
    setPageState(1);
  };

  const handleDeleteAddress = (addressId) => {
    token_api
      .delete(`users/address/${addressId}/`)
      .then((response) => {
        if (
          response?.status === 200 ||
          response?.status === 201 ||
          response?.status === 204
        ) {
          alert("DD User address deleted successfully!");
          userFetchDatanew();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    setAddressData({
      ...addressData,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (addressData?.pincode && addressData?.pincode?.length === 6) {
        try {
          setLoading(true);

          const response = await axios.get(
            `https://api.postalpincode.in/pincode/${addressData?.pincode}`
          );

          if (
            response.data.length !== 0 &&
            response.data[0].PostOffice.length !== 0
          ) {
            const pinData = response.data[0].PostOffice[0];
            const { District, Division, State, Name, Region, Country } =
              pinData;

            setAddressData({
              ...addressData,
              tehsil: Division,
              state: State,
              district: District,
              city: District,
              country: Country,
            });

            // setFormData({
            //   ...formData,
            //   district: Name,
            //   city: Region,
            //   state: State,
            //   country: Country,
            //   pincode: pin,
            // });
            // setShowOther(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [addressData?.pincode]);

  const handlePostPilotAddress = (e) => {
    e.preventDefault();
    const data = {
      user_id: userId,
      area: addressData?.area,
      area_in_acres: addressData?.area_in_acres,
      city: addressData?.city,
      country: addressData?.country,
      district: addressData?.district,
      line_1: addressData?.line_1,
      line_2: addressData?.line_2,
      plot_no: addressData?.plot_no,
      line_kms: addressData?.line_kms,
      pincode: addressData?.pincode,
      state: addressData?.state,
      tehsil: addressData?.tehsil,
      lat: loc?.lat,
      long: loc?.lng,
      ...(selectedBlock.length > 0 && {
        block_code: selectedBlock[0]?.block_code,
      }),
    };

    ValidateAddress(addressData, selectedBlock)
      .then((response) => {
        if (response === "success") {
          if (addressData?.id) {
            //PATCH API....
            token_api
              .patch(`users/address/${addressData?.id}/`, data)
              .then((response) => {
                if (
                  response?.status === 200 ||
                  response?.status === 201 ||
                  response?.status === 204
                ) {
                  alert("Pilot Address Updated Successfully!");
                  setDrawerType("");
                  setOpenPDrawer(false);
                  setAddressData({});
                  userFetchDatanew();
                  setSelectedBlock([]);
                  setFormErrors({});
                }
              });
          } else {
            //POST API....
            token_api.post(`users/admin/address/`, data).then((response) => {
              if (
                response?.status === 200 ||
                response?.status === 201 ||
                response?.status === 204
              ) {
                alert("Pilot New Address Add Successfully!");
                setDrawerType("");
                setOpenPDrawer(false);
                setAddressData({});
                userFetchDatanew();
                setSelectedBlock([]);
                setFormErrors({});
              }
            });
          }
        }
      })
      .catch((error) => {
        setFormErrors(error);
      });
  };
  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <div onClick={handleNavigateBack} style={{ cursor: "pointer" }}>
          <img src={breadCrumbBack} />
        </div>

        <CustomBreadCrumbs breadcrumbs={breadcrumbs} />
      </div>

      {/* <div className={styles.topcards}>
        <UserProfileCard student={null} user={userData} />
        
      </div> */}
      <div className={styles.topcards}>
        {/* <CustomTabs3
          tabHead={tabData}
          currentTab={tabValue}
          setTabVal={setTabValue}
        /> */}

        <div className={styles.leftCon}>
          <div>
            <div className={styles.userProfileIconCon}>
              <img
                src={
                  userData?.avatar ? userData?.avatar : userData?.default_avatar
                }
                style={{ width: "100%" }}
              />
              <img
                src={editIcon}
                className={styles.editIcon}
                onClick={opneProfileModel}
              />
            </div>
            <h3 style={{ textAlign: "center" }}>{userData?.name}</h3>
          </div>
          <div style={{ marginTop: "30px" }}>
            {tabData.map((tab, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    handleChangeTab(tab?.id);
                  }}
                  className={
                    tabValue === tab?.id ? styles.activeTab : styles.normalTab
                  }
                >
                  <img src={tab?.icon} />
                  <p>{tab?.title}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.rightCon}>
          {tabValue === 1 && (
            <UserDDProfileCard
              user={userData}
              opneProfileDrawer={opneProfileDrawer}
              opneProfileModel={opneProfileModel}
            />
          )}
          {tabValue === 2 && (
            <DDUserAddress
              userAddresses={userAddresses}
              handleDeleteAddress={handleDeleteAddress}
              handleEditAddress={handleEditAddress}
              handleAddNewAddress={handleAddNewAddress}
            />
          )}
          {/* {tabValue === 3 && <DDUsersAssets user={userData} />} */}
          {/* {tabValue === 4 && <DDUserAddress userAddresses={userAddresses} />} */}

          {tabValue === 3 && (
            <div>
              {/* <CourseTakenComponent /> */}
              <Registration />
            </div>
          )}
          {tabValue === 4 &&
            (bookingList?.length > 0 ? (
              <div style={{ marginTop: "20px" }}>
                {/* <CourseTakenComponent /> */}
                {/* No Course booking */}

                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <div style={{ marginTop: "25px" }}>
                    <CustomTable
                      columns={tableColumns}
                      data={bookingList}
                      handleClick={handleRedirect}
                      tableFor="courseOrders"
                    />
                  </div>
                )}
                <div style={{ marginTop: "15px" }}>
                  <CustomPagination
                    max_pages={max_pages}
                    setPageState={setPageState}
                  />
                </div>
              </div>
            ) : (
              <p>No Course Bookings</p>
            ))}

          {tabValue === 5 && (
            <DDUserServiceBooking
              data={serviceBookingData}
              handleClick={handleRedirectService}
            />
          )}
          {tabValue === 6 &&
            (droneBookings?.length > 0 ? (
              <>
                <div style={{ marginTop: "25px" }}>
                  <CustomTable
                    columns={tableDroneColumns}
                    data={droneBookings}
                    handleClick={handleRedirectDrone}
                    tableFor="droneBookings"
                  />
                </div>

                <div style={{ marginTop: "15px" }}>
                  <CustomPagination
                    max_pages={max_pages}
                    setPageState={setPageState}
                  />
                </div>
              </>
            ) : (
              <p>No Course Bookings</p>
            ))}
        </div>
      </div>

      <DrawerComp
        width="45%"
        open={openPDrawer}
        onClose={handleCloseProfileDrawer}
        anchor={"right"}
      >
        {/* <AddBannerForm handleCancelDrw={handleCloseDrawer} getBannerData={getBannerData} bannerDetails={bannerDetails} /> */}
        {drawerType === "profile" && (
          <EditUserProfile
            user={userData}
            handleCancelDrw={handleCloseProfileDrawer}
            userFetchDatanew={userFetchDatanew}
          />
        )}

        {drawerType === "address" && (
          <DrawerContainer style={{ marginTop: "0px", marginBottom: "10px" }}>
            <DrawerTitle
              text={addressData?.id ? "Edit Address" : "Add Address"}
              handleCancelDrw={handleCloseProfileDrawer}
            />

            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <MyMap
                  width="30vw"
                  height="40vh"
                  search={addressData?.pincode}
                  loc={loc}
                  updLoc={updLoc}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Pincode"
                name="pincode"
                value={addressData?.pincode}
                onChange={handleChangeAddress}
                error={formErrors?.pincode}
                helperText={formErrors?.pincode}
              />
            </div>

            <div style={{ marginTop: "10px" }}>
              <AddressBlock
                value={selectedBlock}
                onChange={handleAutoCompleteChangeForBlock}
              />
              {formErrors?.selectedBlock && (
                <p style={{ color: "red" }}>{formErrors?.selectedBlock}</p>
              )}
            </div>

            <div className={styles.inputContainer}>
              <InputFields
                label="Line 1"
                name="line_1"
                value={addressData?.line_1}
                onChange={handleChangeAddress}
                error={formErrors?.line_1}
                helperText={formErrors?.line_1}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Line 2"
                name="line_2"
                value={addressData?.line_2}
                onChange={handleChangeAddress}
                error={formErrors?.line_2}
                helperText={formErrors?.line_2}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Area"
                name="area"
                value={addressData?.area}
                onChange={handleChangeAddress}
                error={formErrors?.area}
                helperText={formErrors?.area}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Area In Acres"
                name="area_in_acres"
                value={addressData?.area_in_acres}
                onChange={handleChangeAddress}
                error={formErrors?.area_in_acres}
                helperText={formErrors?.area_in_acres}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="City"
                name="city"
                value={addressData?.city}
                onChange={handleChangeAddress}
                error={formErrors?.city}
                helperText={formErrors?.city}
              />
            </div>

            <div className={styles.inputContainer}>
              <InputFields
                label="Plot No."
                name="plot_no"
                value={addressData?.plot_no}
                onChange={handleChangeAddress}
                error={formErrors?.plot_no}
                helperText={formErrors?.plot_no}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Line In Kms"
                name="line_kms"
                value={addressData?.line_kms}
                onChange={handleChangeAddress}
                error={formErrors?.line_kms}
                helperText={formErrors?.line_kms}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Tehsil"
                name="tehsil"
                value={addressData?.tehsil}
                onChange={handleChangeAddress}
                error={formErrors?.tehsil}
                helperText={formErrors?.tehsil}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="District"
                name="district"
                value={addressData?.district}
                onChange={handleChangeAddress}
                error={formErrors?.district}
                helperText={formErrors?.district}
              />
            </div>

            <div className={styles.inputContainer}>
              <InputFields
                label="State"
                name="state"
                value={addressData?.state}
                onChange={handleChangeAddress}
                error={formErrors?.state}
                helperText={formErrors?.state}
              />
            </div>
            <div className={styles.inputContainer}>
              <InputFields
                label="Country"
                name="country"
                value={addressData?.country}
                onChange={handleChangeAddress}
                error={formErrors?.country}
                helperText={formErrors?.country}
              />
            </div>

            <div className={styles.inputContainer}>
              <Button
                variant="contained"
                // onClick={() => handleOpenModal(item)}
                onClick={handlePostPilotAddress}
                // style={{ maxWidth: "170px" }}
              >
                {addressData?.id ? "Update Address" : "Add Address"}
              </Button>
            </div>
          </DrawerContainer>
        )}
      </DrawerComp>

      <CustomModal open={isModalOpen} onClose={handleCloseModal}>
        <EditUserpicModel
          user={userData}
          userFetchDatanew={userFetchDatanew}
          handleCloseModal={handleCloseModal}
        />
      </CustomModal>
    </Container>
  );
};

export default UserProfile;

const tabData = [
  {
    id: 1,
    title: "Personal Details",
    icon: ddPersonal,
  },

  {
    id: 2,
    title: "Addresses",
    icon: ddAddresses,
  },
  {
    id: 3,
    title: "Documents",
    icon: orangeDocument,
  },
  {
    id: 4,
    title: "Course Bookings",
    icon: orangeCourseBooking,
  },
  {
    id: 5,
    title: "Service Bookings",
    icon: orangeServiceBooking,
  },
  {
    id: 6,
    title: "Drone Bookings",
    icon: orangeDroneBooking,
  },
];

const tableColumns = [
  {
    id: "bookin_status",
    label: "Booking Status",
  },
  {
    id: "book_id",
    label: "Booking ID",
  },
  {
    id: "book_date",
    label: "Booking Date",
  },
  {
    id: "user_name",
    label: "User Name",
  },
  {
    id: "course_name",
    label: "Course Name",
  },
  {
    id: "batch_name",
    label: "Batch Name",
  },
  {
    id: "mode",
    label: "Mode",
  },
  {
    id: "start_date",
    label: "Start Date",
  },

  {
    id: "slot_fly",
    label: "Flying Slot",
  },
  {
    id: "slot_flying_center",
    label: "Slot Flying Center",
  },
  {
    id: "location",
    label: "Location(City)",
  },
  {
    id: "payment_status",
    label: "Payment Status",
  },
  {
    id: "amount",
    label: "Amount",
  },
  {
    id: "accommodation",
    label: "Accomodation",
  },
  {
    id: "accomodation_date",
    label: "Accomodation Date",
  },
  {
    id: "certificate",
    label: "Certificate",
  },
  {
    id: "invoice",
    label: "Invoice",
  },

  // {
  //   id: "actions",
  //   label: "Actions",
  // },
];

const tableColumnsService = [
  // {
  //   id: "checkBox",
  //   label: "",
  // },
  {
    id: "id",
    label: "Service ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "created",
    label: "Service Created",
  },
  {
    id: "serviceDate",
    label: "Service Date",
  },
  // {
  //   id: "time",
  //   label: "Time",
  // },
  {
    id: "location",
    label: "Location(City)",
  },
  {
    id: "category",
    label: "Category",
  },
  {
    id: "service",
    label: "Service",
  },
  {
    id: "drone",
    label: "Drone",
  },
  {
    id: "pilot",
    label: "Pilot",
  },

  {
    id: "start_date",
    label: "Start date",
  },
  {
    id: "end_date",
    label: "End date",
  },
];

const tableDroneColumns = [
  {
    id: "id",
    label: "Booking ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "product_name",
    label: "Product Name",
  },
  {
    id: "total_amount",
    label: "Total Amount",
  },

  {
    id: "zoho_order_id",
    label: "Zoho Order ID",
  },
  {
    id: "created",
    label: "Created Date",
  },
  {
    id: "delivery",
    label: "Expected Date",
  },

  {
    id: "purchase_status",
    label: "Booking Status",
  },
];
